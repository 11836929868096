var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded shadow p-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: {
                  tag: "button",
                  to: {
                    name: "organisationIndividualAdd",
                    params: { id: _vm.organisationId }
                  }
                }
              },
              [_vm._v("Add User")]
            )
          ],
          1
        ),
        _c("h1", [_vm._v("Users")]),
        _c(
          "div",
          {
            staticClass:
              "flex mb-4 simple-list-dropdown-search flex-wrap flex-row-reverse sm:flex-wrap"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "simple-list-search flex-initial w-full sm:w-full md:w-auto"
              },
              [
                _vm._v("\n        Search:\n        "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchCriteria,
                      expression: "searchCriteria"
                    }
                  ],
                  staticClass:
                    "block sm:inline-block bg-gray-25 h-8 rounded sm:ml-3 w-64 p-2 focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1",
                  attrs: { placeholder: "please enter a user's name" },
                  domProps: { value: _vm.searchCriteria },
                  on: {
                    keyup: _vm.getList,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchCriteria = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        _vm.accountsList.length
          ? _c(
              "div",
              { staticClass: "table-container" },
              [
                _vm.searchResults.length
                  ? [
                      _c(
                        "table",
                        { staticClass: "w-full table" },
                        [
                          _vm._m(0),
                          _c("tr"),
                          _vm._l(_vm.searchResults, function(account) {
                            return _c(
                              "tr",
                              {
                                key: account.accountId,
                                staticClass: "table-tr transition",
                                on: {
                                  click: function($event) {
                                    return _vm.edit(account.account.id)
                                  }
                                }
                              },
                              [
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.account.firstName))
                                ]),
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.account.lastName))
                                ]),
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.account.email))
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "table-data-left" },
                                  [
                                    _c("tick-cross", {
                                      attrs: { value: account.owner }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "table-data-left" },
                                  [
                                    _c("tick-cross", {
                                      attrs: {
                                        value: account.account.isSuspended
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "table-data-left" },
                                  [
                                    _c("tick-cross", {
                                      attrs: { value: account.account.isActive }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "table-data-center",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.showModalFunction(
                                          account.account.id,
                                          account.account.firstName,
                                          account.account.lastName
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  : _c("div", [
                      _vm._v(
                        "There are no users with that name associated with this organisation"
                      )
                    ]),
                _c("pagination", {
                  staticClass: "simple-list-pagination",
                  attrs: {
                    total: _vm.total,
                    "per-page": 10,
                    maxVisibleButtons: 3,
                    "current-page": _vm.pageNumber,
                    response: _vm.searchResults
                  },
                  on: {
                    pagechanged: function($event) {
                      _vm.pageNumber = $event
                    }
                  }
                })
              ],
              2
            )
          : _c("div", [
              _vm._v("There are no users assosciated with this organisation")
            ])
      ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v(
              "Are you sure you want to delete " +
                _vm._s(this.accountName) +
                "?"
            )
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("First name")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Last name")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Email")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Owner")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Suspended")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Active")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }