
























import { SaveMixin } from "@/services/mixins/saveMixin";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Entitlement } from "@/types/entitlement";
import { HttpService } from "@/services/api/httpService";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
@Component({
  validations: {
    form: {
      entitlementName: { required, maxLength: maxLength(50) },
      shortCode: { required, maxLength: maxLength(10) }
    }
  },
  mixins: [SaveMixin]
})
export default class EntitlementAdd extends Vue {
  id: string = "";
  entitlement: Entitlement = new Entitlement();
  backRoute = {
    name: "entitlementsList"
  };

  httpService() {
    return new HttpService<Entitlement>();
  }

  @Watch("back") onBack() {
    if (this.$store.getters.getBackEvent) {
      this.$store.commit("BACK", false);
      this.$router.push(this.backRoute);
    }
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpService()
        .postItem("entitlements", this.entitlement)
        .then(res => {
          this.$router.push(this.backRoute);
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
