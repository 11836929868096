import { RootState } from "../../types";
import { Uploads } from "./types";

import { mutations } from "./mutations";
import { Module } from "vuex";
export const state: Uploads = {
  alertCount: 0,
  busy: false,
  notificationsList: [],
  disableUploadButton: false
};

export const uploads: Module<Uploads, RootState> = {
  state,
  mutations
};
