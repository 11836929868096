







import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Default extends Vue {}
