































































import axios from "axios";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Application } from "@/types/application";
import { Communication } from "@/types/communication";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { Form } from "@/types/form";
import { required, maxLength } from "vuelidate/lib/validators";

import ApplicationCommunicationList from "@/views/application/applicationCommunication/ApplicationCommunicationList.vue";
@Component({
  validations: {
    application: {
      applicationName: { required, maxLength: maxLength(50) }
    }
  },
  components: {
    ApplicationCommunicationList
  },
  mixins: [SaveMixin]
})
export default class ApplicationEdit extends Vue {
  forms: Array<Form> = Array<Form>();
  application: Application = new Application();
  id: number = 0;
  backRoute = {
    name: "applicationsList"
  };

  created() {
    this.id = Number(this.$route.params.id);
    let httpService = new HttpService<Array<Form>>();
    httpService
      .getList("forms?PageSize=100")
      .then(response => (this.forms = response.data))
      .catch(error => {
        console.error(error);
      });
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = Number(this.$route.params.id);
    this.getApplication();
  }
  async getApplication() {
    let httpService = new HttpService<Application>();
    await httpService.getItem(`applications/${this.id}`).then(response => {
      this.application = response.data || new Application();
    });
  }

  gotoAddCommunication() {
    this.$router.push({
      name: "applicationCommunicationAdd"
    });
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpService = new HttpService<Application>();
      await httpService
        .putItem(`applications/${this.id}`, this.application)
        .catch(error => {
          console.error(error);
        });
      this.$router.push(this.backRoute);
      this.$notification.success("Application successfully updated");
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
