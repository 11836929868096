









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import axios, { AxiosError } from "axios";
import { ClientSecret } from "@/types/client";

@Component
export default class DashboardClientSecret extends Vue {
  httpService = new HttpService<ClientSecret>();
  responseArray = new Array<ClientSecret>();
  showModal: boolean = false;
  clientId: any = "";
  removeData = new ClientSecret();
  async getList() {
    let httpService = new HttpService<Array<ClientSecret>>();
    const response = await httpService.getList(
      `clients/${this.clientId}/secrets`
    );
    this.responseArray = response.data;
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.clientId = this.$route.params.id;
    this.getList();
  }

  edit(secret: ClientSecret) {
    this.$router.push({
      name: "clientSecretEdit",
      params: {
        id: this.clientId,
        secretId: secret.id
      }
    });
  }

  confirmRemove(data: ClientSecret) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: ClientSecret) {
    const response = await this.httpService.deleteItem(
      `clients/${data.clientId}/secrets`,
      data.id
    );

    this.responseArray = this.responseArray.filter(
      (item: ClientSecret) => item.clientId != data.clientId,
      this.$notification.success(`${data.type} successfully removed!`)
    );

    this.showModal = false;
    this.getList();
  }
}
