var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "input",
      attrs: { type: "text", placeholder: "click to insert date" },
      domProps: { value: _vm.moment(_vm.value) }
    }),
    _vm.value
      ? _c(
          "button",
          {
            staticClass: "float-right font-semibold text-red-300",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.reset($event)
              }
            }
          },
          [_vm._v("Clear")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }