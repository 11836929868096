
















import { Component, Vue, Watch } from "vue-property-decorator";
import AccountApplicationCommunication from "./accountApplicationCommunication/AccountApplicationCommunication.vue";
import { HttpService } from "@/services/api/httpService";
import {
  FullAccountApplicationType,
  AccountApplicationType
} from "../../../types/application";
import { AccountCommunication } from "@/types/accountCommunication";
import { ApplicationCommunication } from "../../../types/applicationCommunication";
import { SaveMixin } from "@/services/mixins/saveMixin";
import AccountApplicationForm from "./accountApplicationForm/AccountApplicationForm.vue";
import { Answer } from "../../../types/answer";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { Form } from "../../../types/form";
import { Communication } from "../../../types/communication";
import { AxiosResponse } from "axios";
import { Account } from "../../../types/account";
@Component({
  components: {
    Communications: AccountApplicationCommunication,
    FormData: AccountApplicationForm
  },
  mixins: [SaveMixin],
  validations: {}
})
export default class AccountApplicationEdit extends Vue {
  applicationData = new FullAccountApplicationType();
  applicationId: number | string = "";
  accountId: string = "";
  showData = false;

  accountCommunications = new Array<AccountCommunication>();
  accountName = "";
  backRoute = {
    name: "accountDashboard",
    id: this.accountId
  };
  get showSave() {
    if (
      !this.applicationData.application.form &&
      !this.accountCommunications.length
    )
      return false;
    else return true;
  }
  @Watch("$route", { immediate: true }) getInfo() {
    this.applicationId = this.$route.params.applicationId;
    this.accountId = this.$route.params.id;
    this.getCommunications();
    this.getForm();
  }

  async getCommunications() {
    const httpServceAccountCommunication = new HttpService<
      Array<AccountCommunication>
    >();
    const httpServiceApplicationCommunication = new HttpService<
      Array<ApplicationCommunication>
    >();
    const response = await httpServiceApplicationCommunication.getList(
      `applications/${this.applicationId}/communications`
    );
    if (response.status === 404) return;
    const applicationCommunications = response.data;

    const response2 = await httpServceAccountCommunication.getList(
      `accounts/${this.accountId}/communications`
    );

    const allCommunications = response2.data;
    let accountCommunications: AccountCommunication[] = [];
    if (response2.status === 204 || response2.status === 200) {
      accountCommunications = response2.data.filter(
        (communicationOne: AccountCommunication) => {
          return applicationCommunications.find(
            (communicationTwo: ApplicationCommunication) => {
              return (
                communicationOne.communicationId ===
                communicationTwo.communicationId
              );
            }
          );
        }
      );
    }

    let unansweredCommunications = [];

    unansweredCommunications = applicationCommunications.filter(
      (communicationOne: ApplicationCommunication) => {
        return !accountCommunications.find(
          (communicationTwo: AccountCommunication) => {
            return (
              communicationOne.communicationId ===
              communicationTwo.communicationId
            );
          }
        );
      }
    );
    unansweredCommunications.forEach(communication => {
      let accountCommunication = new AccountCommunication();
      accountCommunication.communicationName = communication.communicationName;
      accountCommunication.communicationId = communication.communicationId;
      accountCommunication.accountId = this.accountId;
      accountCommunication.optOut = communication.autoOptOut;
      accountCommunications.push(accountCommunication);
    });
    this.accountCommunications = [...accountCommunications];
  }

  async getForm() {
    const httpServiceAccount = new HttpService<Account>();
    const response2 = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response2.data.fullName;
    const httpServiceApplication = new HttpService<
      FullAccountApplicationType
    >();
    const response = await httpServiceApplication.getItem(
      `accounts/${this.accountId}/fullapplications/${this.applicationId}`
    );

    this.applicationData = response.data;
    this.showData = true;
  }
  get title() {
    return `${this.accountName} - ${this.applicationData.application.applicationName}`;
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      await this.saveformComplete();
      await this.saveCommunications();
      if (this.applicationData.application.form)
        await this.saveForm(),
          this.$notification.success("Application successfuly saved");

      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
  async saveCommunications() {
    const httpServceAccountCommunication = new HttpService<
      AccountCommunication
    >();
    for (let communication of this.accountCommunications) {
      if (communication.accountCommunicationId) {
        await httpServceAccountCommunication.putItem(
          `accounts/${this.accountId}/communications/${communication.accountCommunicationId}`,
          communication
        );
      } else {
        await httpServceAccountCommunication.postItem(
          `accounts/${this.accountId}/communications/`,
          communication
        );
      }
    }
  }
  async saveForm() {
    const httpService = new HttpService<Answer>();
    const applicationData = this.applicationData;
    if (this.$store.state.applicationAnswerDeleteArray.deleteArray.length) {
      await this.$store.state.applicationAnswerDeleteArray.deleteArray.forEach(
        async (value: number) => {
          let response1 = await httpService.deleteItem(`answers`, value);
        }
      );
    }

    await this.applicationData.application.form.groups.forEach(async group => {
      await group.groupQuestions.forEach(async groupQuestion => {
        if (groupQuestion.question.answers)
          await groupQuestion.question.answers.forEach(async answer => {
            answer.accountId = this.accountId;
            if (answer.accountQuestionAnswerId) {
              await httpService.putItem(
                `answers/${answer.accountQuestionAnswerId}`,
                answer
              );
            } else if (!answer.accountQuestionAnswerId) {
              answer.questionId = groupQuestion.question.questionId;
              answer.accountId = this.accountId;
              await httpService.postItem("answers", answer);
            }
          });
      });
    });
  }

  async saveformComplete() {
    const formCompletehttpService = new HttpService<AccountApplicationType>();

    const response = await formCompletehttpService.putItem(
      `accounts/${this.accountId}/applications/${this.applicationId}`,
      { formCompleted: this.applicationData.formCompleted }
    );
  }

  destroyed() {
    this.$store.commit("RESET_ANSWER_ARRAY");
  }
}
