

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { Entitlement } from "@/types/entitlement";
import { SubscriptionPackageEntitlement } from "@/types/subscriptionPackageEntitlement";
import { AxiosResponse } from "axios";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  maxValue,
  minValue
} from "vuelidate/lib/validators";
import EntitlementList from "../entitlements/EntitlementList.vue";

@Component({
  validations: {
    subscriptionPackage: {
      subscriptionPackageName: { required, maxLength: maxLength(50) },
      subscriptionPackageCode: { required, maxLength: maxLength(10) }
    }
  },
  mixins: [SaveMixin]
})
export default class SubscriptionPackage extends Vue {
  id: number | string = 0;
  subscriptionPackage: SubscriptionPackageItem = new SubscriptionPackageItem();
  httpServiceSubscriptionPackage: HttpService<
    SubscriptionPackageItem
  > = new HttpService<SubscriptionPackageItem>();
  httpServiceEntitlements: HttpService<Array<Entitlement>> = new HttpService<
    Array<Entitlement>
  >();
  backRoute = {
    name: "packagesList"
  };

  httpServiceSubscriptionPackageEntitlement: HttpService<
    SubscriptionPackageEntitlement
  > = new HttpService<SubscriptionPackageEntitlement>();

  entitlements: Array<Entitlement> = new Array<Entitlement>();
  entitlement: Entitlement = new Entitlement();
  subscriptionPackageEntitlement: SubscriptionPackageEntitlement = new SubscriptionPackageEntitlement();
  addButtonDisabled: boolean = true;

  getPackageInfo() {
    this.httpServiceSubscriptionPackage
      .getItem(`packages/${this.id}`)
      .then(response => {
        this.subscriptionPackage =
          response.data || new SubscriptionPackageItem();
      });
  }

  getEntitlements() {
    this.httpServiceEntitlements.getList("entitlements").then(res => {
      this.entitlements = res.data || new Array<Entitlement>();
    });
  }

  disableAddButton() {
    return this.entitlement.entitlementId === undefined
      ? (this.addButtonDisabled = true)
      : (this.addButtonDisabled = false);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getPackageInfo();
    this.getEntitlements();
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.httpServiceSubscriptionPackage
        .putItem(`packages/${this.id}`, this.subscriptionPackage)
        .then(res => {
          this.$router.push(this.backRoute);
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }

  removeEntitlement(id: number) {
    this.httpServiceSubscriptionPackageEntitlement
      .deleteItem(`packages/${this.id}/entitlements`, id)
      .then((res: AxiosResponse) => {
        this.getPackageInfo();
      });
  }

  add() {
    this.subscriptionPackageEntitlement.subscriptionPackageId = this.id;
    this.subscriptionPackageEntitlement.entitlementId = this.entitlement.entitlementId;
    this.httpServiceSubscriptionPackageEntitlement
      .postItem(
        `packages/${this.id}/entitlements`,
        this.subscriptionPackageEntitlement
      )
      .then(res => {
        this.getPackageInfo();
      });
  }
}
