var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded shadow p-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: {
                  tag: "button",
                  to: {
                    name: "organisationSubscriptionAdd",
                    params: { id: _vm.organisationId }
                  }
                }
              },
              [
                _c("div", { staticClass: "sm:flex flex-col md:flex-row" }, [
                  _c("p", [_vm._v("Add  ")]),
                  _c("p", [_vm._v("Subscription")])
                ])
              ]
            )
          ],
          1
        ),
        _c("h1", [_vm._v("Subscriptions")]),
        _vm.subscriptions.length
          ? _c("div", { staticClass: "table-container" }, [
              _c(
                "table",
                { staticClass: "w-full table" },
                [
                  _vm._m(0),
                  _c("tr"),
                  _vm._l(_vm.subscriptions, function(subscription) {
                    return _c(
                      "tr",
                      {
                        key: subscription.subscriptionPackageId,
                        staticClass: "table-tr transition",
                        on: {
                          click: function($event) {
                            return _vm.edit(
                              subscription.organisationSubscriptionId
                            )
                          }
                        }
                      },
                      [
                        _c("td", { staticClass: "table-data-left" }, [
                          _vm._v(_vm._s(subscription.subscriptionPackageName))
                        ]),
                        _c("td", { staticClass: "table-data-left" }, [
                          _vm._v(_vm._s(_vm.moment(subscription.startDate)))
                        ]),
                        _c("td", { staticClass: "table-data-left" }, [
                          _vm._v(_vm._s(_vm.moment(subscription.endDate)))
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-data-left" },
                          [
                            _c("tick-cross-date", {
                              attrs: {
                                startDate: subscription.startDate,
                                endDate: subscription.endDate
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-data-center",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.showModalFunction(
                                  subscription.organisationSubscriptionId,
                                  subscription.subscriptionPackageName
                                )
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          : _c("div", [
              _vm._v(
                "There are no subscriptions associated with this organisation"
              )
            ])
      ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v(
              "Are you sure you want to delete " +
                _vm._s(this.subscriptionPackageName) +
                "?"
            )
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Subscription Package Name")
      ]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Start Date")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("End Date")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Active")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }