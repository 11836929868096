
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import NotificationItemType from "../../types/notificationItem";
@Component
export default class NotificationItem extends Vue {
  @Prop() item!: NotificationItemType;
  @Prop() index!: number;
  remove() {
    this.$store.commit("REMOVE_NOTIFICATION", { index: this.index });
  }
  completionTime: string = "";

  @Watch("item", { immediate: true, deep: true }) updateTime() {
    let now = moment().unix();
    let parsedItem = JSON.parse(JSON.stringify(this.item));
    let elapsedTime: number = 0;
    if (parsedItem.notification.endTime) {
      elapsedTime = now - parsedItem.notification.endTime;
      let calucalateTime = (format: string) => {
        return moment.utc(elapsedTime * 1000).format(format);
      };
      if (elapsedTime < 60)
        this.completionTime = `completed ${elapsedTime} seconds ago`;
      else if (elapsedTime < 180)
        this.completionTime = `completed a minute ago`;
      else if (elapsedTime < 600)
        this.completionTime = `completed ${calucalateTime("m")} minutes ago`;
      else if (elapsedTime < 3600)
        this.completionTime = `completed ${calucalateTime("mm")} minutes ago`;
      else if (elapsedTime < 5400)
        this.completionTime = `completed more than an hour ago`;
      else if (elapsedTime < 36000)
        this.completionTime = `completed more than ${moment
          .utc(elapsedTime)
          .format("H")} hours ago`;
      else if (elapsedTime < 36000 && elapsedTime < 86400)
        this.completionTime = `completed more than ${moment
          .utc(elapsedTime)
          .format("HH")} hours ago`;
      else if (elapsedTime >= 86400)
        this.completionTime = `completed more than a day ago`;
    }
  }
}
