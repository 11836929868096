var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "text-sm" }, [
        _c(
          "input",
          _vm._g(
            {
              staticClass: "mr-2 leading-tight",
              class: _vm.$validationStatus.status(_vm.validationObj),
              attrs: { type: "checkbox", disabled: _vm.disabled },
              domProps: { checked: _vm.checked },
              on: { change: _vm.change }
            },
            _vm.listeners
          )
        ),
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
      ]),
      _c("input-validation", { attrs: { validationObj: _vm.validationObj } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }