var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("font-awesome-icon", {
    class: _vm.evaluateDates() ? "awesome-tick" : "awesome-cross",
    attrs: { icon: _vm.evaluateDates() ? "check" : "times" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }