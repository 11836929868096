var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "input",
        _vm._g(
          {
            staticClass:
              "w-full rounded p-2 border border-gray-50 focus:outline-none transition pr-8",
            class: _vm.$validationStatus.status(_vm.validationObj),
            attrs: {
              type: _vm.type,
              placeholder: _vm.placeholder,
              disabled: _vm.disabled
            },
            domProps: { value: _vm.value },
            on: { input: _vm.input }
          },
          _vm.listeners
        )
      ),
      _c("input-validation", { attrs: { validationObj: _vm.validationObj } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }