export class AccountCommunication {
  accountCommunicationId: any;
  communicationName: any;
  communicationId: any;
  accountId: string;
  optOut: boolean;
  constructor() {
    this.communicationId = null;
    this.communicationName = "";
    this.accountId = "";
    this.optOut = false;
  }
}
