













































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Country } from "../../types/country";
import { TitleMixin } from "../../services/mixins/titleMixin";
import { Application } from "../../types/application";
import {
  CreateClient,
  ClientScope,
  CreateWebAppClient,
  CreateSpaClient
} from "../../types/client";
import DatePicker from "@/components/DatePicker.vue";
import { HttpService } from "@/services/api/httpService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import {
  isPhone,
  isPostCode
} from "@/services/customValidator/customValidators";
import { ApiScope, ApiResource } from "../../types/apiResource";
import { IdentityResource } from "../../types/identityResource";
import { isUrl } from "@/services/customValidator/customValidators";
@Component({
  components: { DatePicker },
  validations: {
    client: {
      clientName: { maxLength: maxLength(200) },
      clientId: { required, maxLength: maxLength(200) },
      clientUri: { maxLength: maxLength(2000), isUrl },
      description: { maxLength: maxLength(1000) },
      logoUri: { maxLength: maxLength(2000), isUrl },
      redirectUris: {
        $each: { redirectUri: { required, maxLength: maxLength(2000), isUrl } }
      },
      postLogoutRedirectUri: {
        $each: {
          postLogoutRedirectUri: { maxLength: maxLength(2000), isUrl }
        }
      },
      corsOrigins: {
        $each: {
          origin: { required, maxLength: maxLength(150), isUrl }
        }
      }
    }
  },
  mixins: [SaveMixin]
})
export default class ClientAddSpa extends Vue {
  showData = false;
  scopes: (ApiResource | IdentityResource)[] = [];
  applications: Array<Application> = [];
  client = new CreateSpaClient();
  newClientScope = new ClientScope();
  httpServiceCreateClient = new HttpService<CreateClient>();
  backRoute = {
    name: "clientList"
  };
  addClientScope() {
    if (this.newClientScope.scope == "") return;
    this.client.scopes.push(this.newClientScope);
    this.scopes.map((scope, index) => {
      if (scope.name == this.newClientScope.scope) {
        this.scopes.splice(index, 1);
      }
    });
    this.newClientScope = new ClientScope();
  }
  removeClientScope(scope: string, index: number) {
    this.client.scopes.splice(index, 1);
    let newScope = new ApiResource();
    newScope.name = scope;
    this.scopes.push(newScope);
  }

  async getApplications() {
    const httpService = new HttpService<Array<Application>>();
    const response = await httpService.getList("applications");
    this.applications = response.data;
    this.showData = true;
  }

  async getScopes() {
    const httpServiceAPI = new HttpService<Array<ApiResource>>();
    const apiResourceresponse = await httpServiceAPI.getList(
      "api-resources/1/scopes"
    );
    const httpServiveIdentity = new HttpService<Array<IdentityResource>>();
    const identityResourceResponse = await httpServiveIdentity.getList(
      "identity-resources"
    );
    const allScopes = [
      ...apiResourceresponse.data,
      ...identityResourceResponse.data
    ];

    const scopesAlreadyAdded = [
      new ClientScope("email"),
      new ClientScope("profile"),
      new ClientScope("openid")
    ]; //default Scopes for SPA and WebApp

    this.scopes = allScopes.filter(scope => {
      return !scopesAlreadyAdded.find(function removeScopesAlreadyAdded(
        scopeAddedAlready
      ) {
        return scope.name === scopeAddedAlready.scope;
      });
    });
  }

  async created() {
    await this.getScopes();
    this.getApplications();
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (this.client.logoUri == "") this.client.logoUri = null; //model doesn't allow empty string in backend

    if (!this.$v.$invalid) {
      const response = await this.httpServiceCreateClient.postItem(
        "spa-client",
        this.client
      );

      this.$notification.success("Client details successfully saved");
      this.$store.dispatch("SET_DIRTY", false);

      this.$router.push({
        name: "clientDashboard",
        params: { id: response.data.id }
      });
    }
  }
}
