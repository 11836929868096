var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input-component", {
        staticClass: "mb-2",
        attrs: {
          validationObj:
            _vm.$v.groupQuestion.question.answers.$each.$iter[0]
              .questionAnswerText
        },
        model: {
          value:
            _vm.$v.groupQuestion.question.answers.$each.$iter[0]
              .questionAnswerText.$model,
          callback: function($$v) {
            _vm.$set(
              _vm.$v.groupQuestion.question.answers.$each.$iter[0]
                .questionAnswerText,
              "$model",
              $$v
            )
          },
          expression:
            "$v.groupQuestion.question.answers.$each.$iter[0].questionAnswerText.$model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }