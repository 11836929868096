var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "lg:px-20 xl:px-20 bg-gray-500 text-white w-full xl:h-14 animated relative overflow-hidden transition h-full flex-grow lg:flex lg:items-center sm:w-auto",
      class: [_vm.isMenuOpen ? "block" : "hidden"]
    },
    [_c("navigation-items")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }