















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import { AccountSubscription } from "@/types/accountSubscription";
import moment from "moment";
import { AxiosError } from "axios";

@Component
export default class AccountDashboardSubscriptions extends Vue {
  accountid: string = "";
  httpService = new HttpService<Array<AccountSubscription>>();
  subscriptions = new Array<AccountSubscription>();
  httpServiceSubscription = new HttpService<Array<SubscriptionPackageItem>>();
  accountSubscriptionId: any = "";
  subscriptionPackageName: string = "";
  todayDate = new Date();
  showModal: boolean = false;

  async getData() {
    const response = await this.httpService.getItem(
      `accounts/${this.accountid}/subscriptions`
    );

    const response2 = await this.httpServiceSubscription.getListPaging(
      "packages"
    );
    if (response.status !== 404) {
      for (let item of response.data) {
        item.subscriptionPackageName = this.$returnObject(
          item.subscriptionPackageId,
          response2.data,
          "subscriptionPackageId"
        ).subscriptionPackageName;
      }
    }
    this.subscriptions = response.data || new Array<AccountSubscription>();
    this.$emit("finishedLoading", true);
  }

  moment(date: Date) {
    if (!date) {
      return "";
    }
    return moment(date).format("DD MMM YYYY");
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.accountid = this.$route.params.id;

    this.getData();
  }

  edit(accountSubscriptionId: string) {
    this.$router.push({
      name: "accountSubscriptionEdit",
      params: {
        id: this.accountid,
        accountSubscriptionId: accountSubscriptionId
      }
    });
  }

  showModalFunction(
    accountSubscriptionId: string,
    subscriptionPackageName: string
  ) {
    this.showModal = true;
    this.accountSubscriptionId = accountSubscriptionId;
    this.subscriptionPackageName = subscriptionPackageName;
  }
  async remove() {
    await this.httpService.deleteItem(
      `accounts/${this.accountid}/subscriptions`,
      this.accountSubscriptionId,
      { errorInterceptor: false }
    );

    this.subscriptions = this.subscriptions.filter(
      (item: AccountSubscription) =>
        item.accountSubscriptionId != this.accountSubscriptionId,
      this.$notification.success(
        `${this.subscriptionPackageName} successfully removed!`
      )
    );

    this.showModal = false;
    this.getData();
  }
}
