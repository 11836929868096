var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Add a user")]),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("div", { staticClass: "md:flex mb-4 flex-none block" }, [
                _c("div", { staticClass: "md:w-2/5-2 h-8 w-full" }, [
                  _vm._v("User:")
                ]),
                _c(
                  "div",
                  { staticClass: "md:w-3/5-2 md:px-3 h-8 w-full sm:px-0" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.$v.organisationAccount.account.email.$model,
                          expression:
                            "$v.organisationAccount.account.email.$model"
                        }
                      ],
                      staticClass:
                        "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                      attrs: {
                        placeholder:
                          "Please select a user from the table below",
                        disabled: "true"
                      },
                      domProps: {
                        value: _vm.$v.organisationAccount.account.email.$model
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.$v.organisationAccount.account.email,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.$v.organisationAccount.account.email.$error
                      ? _c(
                          "div",
                          { staticClass: "text-red-400 text-xs italic" },
                          [
                            !_vm.$v.organisationAccount.account.email.required
                              ? _c("p", [
                                  _vm._v(
                                    "Please select an account to add to the Organisation"
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "md:flex mb-4 flex-none block" }, [
                _c("div", { staticClass: "md:w-2/5-2 h-8 w-full" }, [
                  _vm._v("Owner:")
                ]),
                _c(
                  "div",
                  { staticClass: "md:w-3/5-2 md:px-3 h-8 w-full sm:px-0" },
                  [
                    _c("checkbox-component", {
                      model: {
                        value: _vm.$v.organisationAccount.owner.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.organisationAccount.owner,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.organisationAccount.owner.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "flex mb-4 simple-list-dropdown-search flex-wrap sm:flex-wrap"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "simple-list-dropdown flex-initial mr-auto w-full sm:w-full md:w-auto mb-4 md:mb-0"
                    },
                    [
                      _vm._v("\n          Show:\n          "),
                      _c("dropdown-list", {
                        staticClass:
                          "inline-block focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1 ml-2 mr-2",
                        attrs: {
                          data: [{ page: 10 }, { page: 25 }],
                          accessValueField: "page",
                          selectedValue: _vm.pageSize ? _vm.pageSize : 10,
                          allowNull: false
                        },
                        on: {
                          selected: function($event) {
                            ;(_vm.pageSize = $event), (_vm.pageNumber = 1)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._v(_vm._s(item.page))]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "simple-list-search flex-initial w-full sm:w-full md:w-auto"
                    },
                    [
                      _vm._v("\n          Search:\n          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchCriteria,
                            expression: "searchCriteria"
                          }
                        ],
                        staticClass:
                          "block sm:inline-block bg-gray-25 h-8 rounded sm:ml-3 w-64 p-2 focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1",
                        domProps: { value: _vm.searchCriteria },
                        on: {
                          keyup: function($event) {
                            return _vm.keyMonitor($event, "resetPaging")
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchCriteria = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c(
                    "table",
                    { staticClass: "w-full table" },
                    [
                      _c("tr", [
                        _c("th", { staticClass: "table-header-left" }, [
                          _vm._v("First Name")
                        ]),
                        _c("th", { staticClass: "table-header-left" }, [
                          _vm._v("Last Name")
                        ]),
                        _c("th", { staticClass: "table-header-left" }, [
                          _vm._v("Email")
                        ]),
                        _c("th", { staticClass: "table-header-left" }, [
                          _vm._v("Active")
                        ])
                      ]),
                      _vm.searching
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                attrs: { colspan: "100" }
                              },
                              [_vm._v(_vm._s(_vm.message))]
                            )
                          ])
                        : _vm._l(_vm.filteredAccounts, function(
                            account,
                            index
                          ) {
                            return _c(
                              "tr",
                              {
                                key: account.id,
                                ref: "accounts",
                                refInFor: true,
                                staticClass: "table-tr transition",
                                attrs: { id: account.id },
                                on: {
                                  click: function($event) {
                                    return _vm.select(account, index)
                                  }
                                }
                              },
                              [
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.firstName))
                                ]),
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.lastName))
                                ]),
                                _c("td", { staticClass: "table-data-left" }, [
                                  _vm._v(_vm._s(account.email))
                                ]),
                                _c("td", { staticClass: "table-data-left" }, [
                                  account.isActive == true
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-green-300 text-sm block text-center"
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "check" }
                                          })
                                        ],
                                        1
                                      )
                                    : account.isActive == false
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-red-300 text-sm block text-center"
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "times" }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          })
                    ],
                    2
                  ),
                  _c("pagination", {
                    staticClass: "simple-list-pagination",
                    attrs: {
                      total: _vm.total,
                      "per-page": _vm.pageSize,
                      maxVisibleButtons: 3,
                      "current-page": _vm.pageNumber,
                      response: _vm.accounts
                    },
                    on: {
                      pagechanged: function($event) {
                        _vm.pageNumber = $event
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }