










import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class TickCross extends Vue {
  @Prop({ required: true }) value!: boolean;
}
