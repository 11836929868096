var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [_vm._v("Edit Communication Type")]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Communication Type:")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("input-component", {
              attrs: {
                validationObj: _vm.$v.communicationType.communicationTypeName
              },
              model: {
                value: _vm.$v.communicationType.communicationTypeName.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.communicationType.communicationTypeName,
                    "$model",
                    $$v
                  )
                },
                expression: "$v.communicationType.communicationTypeName.$model"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }