var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded shadow p-8 mb-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: {
                  tag: "button",
                  to: {
                    name: "organisationApplicationAdd",
                    params: { id: _vm.organisationId }
                  }
                }
              },
              [
                _c("div", { staticClass: "sm:flex flex-col md:flex-row" }, [
                  _c("p", [_vm._v("Add  ")]),
                  _c("p", [_vm._v("Application")])
                ])
              ]
            )
          ],
          1
        ),
        _c("h1", [_vm._v("Applications")]),
        _vm.applications.length
          ? _c("div", { staticClass: "table-container" }, [
              _c(
                "table",
                { staticClass: "w-full table" },
                [
                  _vm._m(0),
                  _c("tr"),
                  _vm._l(_vm.applications, function(application) {
                    return _c(
                      "tr",
                      {
                        key: application.application.applicationId,
                        staticClass: "table-tr-no-click transition hover:"
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "table-data-left cursor-default" },
                          [
                            _vm._v(
                              _vm._s(application.application.applicationName)
                            )
                          ]
                        ),
                        application.application.formName
                          ? _c(
                              "td",
                              { staticClass: "table-data-left cursor-default" },
                              [_vm._v(_vm._s(application.application.formName))]
                            )
                          : _c(
                              "td",
                              { staticClass: "table-data-left cursor-default" },
                              [_vm._v("No form associated with application")]
                            ),
                        _c(
                          "td",
                          { staticClass: "table-data-left cursor-default" },
                          [
                            _c("tick-cross", {
                              attrs: {
                                value:
                                  application.application.allowSelfRegistration
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-data-left cursor-default" },
                          [
                            _c("tick-cross", {
                              attrs: {
                                value:
                                  application.application.enforceFormCompletion
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-data-left cursor-default" },
                          [
                            _c("tick-cross", {
                              attrs: {
                                value: application.application.requireAddress
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-data-left cursor-default" },
                          [
                            _c("tick-cross", {
                              attrs: {
                                value:
                                  application.application.consumerApplication
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-data-center",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.showModalFunction(
                                  application.application.applicationId,
                                  application.application.applicationName
                                )
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          : _c("div", [
              _vm._v(
                "There are no applications associated with this organisation"
              )
            ])
      ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v(
              "Are you sure you want to delete " +
                _vm._s(this.applicationName) +
                "?"
            )
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Application Name")
      ]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Form Name")]),
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Allow Self Registration")
      ]),
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Enforce Form Completion")
      ]),
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Require Address")
      ]),
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Consumer Application")
      ]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }