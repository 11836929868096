











import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { required, maxLength } from "vuelidate/lib/validators";
@Component({
  validations: {
    updatedName: { required, maxLength: maxLength(100) }
  }
})
export default class ConfigureForm extends Vue {
  @Prop() name!: string;

  updatedName: String = this.name;
  get triggerSave() {
    return this.$store.getters.getSideModalSave;
  }
  @Watch("triggerSave") onSave() {
    if (this.triggerSave) {
      this.save();
    }
  }
  @Watch("updatedName", { deep: true }) formNameChange() {
    this.$store.dispatch("SET_DIRTY", true);
  }
  save() {
    this.$store.commit("SIDEMODALSAVE", false);
    if (!this.$v.$invalid) {
      this.$store.commit("UPDATE_FORM_NAME", this.updatedName);
      this.$emit("close");
    }
  }
}
