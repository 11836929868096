import { render, staticRenderFns } from "./CountryAdd.vue?vue&type=template&id=7bed21ce&"
import script from "./CountryAdd.vue?vue&type=script&lang=ts&"
export * from "./CountryAdd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bed21ce')) {
      api.createRecord('7bed21ce', component.options)
    } else {
      api.reload('7bed21ce', component.options)
    }
    module.hot.accept("./CountryAdd.vue?vue&type=template&id=7bed21ce&", function () {
      api.rerender('7bed21ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/configuration/countries/CountryAdd.vue"
export default component.exports