






























































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationSubscription } from "@/types/organisationSubscription";
import DatePicker from "@/components/DatePicker.vue";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { SaveMixin } from "@/services/mixins/saveMixin";
import moment from "moment";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { Organisation } from "../../types/organisation";

const endDateGreaterThanStartDate = (endDate: string, vm: any) => {
  if (!moment(endDate).isValid()) {
    return true;
  }
  return moment(endDate).startOf("day") >= moment(vm.startDate).startOf("day");
};

const dateGreaterThanToday = (date: string, vm: any) => {
  if (!moment(date).isValid()) {
    return true;
  }

  return moment(date).startOf("day") >= moment().startOf("day");
};

@Component({
  components: { DatePicker },
  mixins: [SaveMixin],
  validations: {
    organisationSubscription: {
      subscriptionPackageId: { required },
      trial: { required },
      perpetual: { required },
      suspended: { required },
      startDate: { required, dateGreaterThanToday },
      endDate: { endDateGreaterThanStartDate, dateGreaterThanToday },
      notes: { maxLength: maxLength(1000) }
    }
  }
})
export default class OrganisationSubscriptionAdd extends Vue {
  today = new Date();
  showData = false;
  organisationId: any = "";
  organisationSubscription = new OrganisationSubscription();
  httpServiceSubscriptions = new HttpService<Array<OrganisationSubscription>>();

  httpServiceSubscription = new HttpService<OrganisationSubscription>();
  httpServiceSubscriptionPackages = new HttpService<
    Array<SubscriptionPackageItem>
  >();
  activeSubscriptions: Array<OrganisationSubscription> = [];
  allSubscriptions = new Array<SubscriptionPackageItem>();
  organisationName = "";
  filteredSubscriptions = new Array<SubscriptionPackageItem>();
  backRoute = {
    name: "organisationDashboard",
    id: ""
  };
  get title() {
    return `${this.organisationName} - Add Subscription`;
  }
  async getData() {
    const httpService = new HttpService<Organisation>();
    const response3 = await httpService.getItem(
      `organisations/${this.organisationId}`
    );
    this.organisationName = response3.data.organisationName;
    await this.httpServiceSubscriptions
      .getItem(`organisations/${this.organisationId}/subscriptions`)
      .then(response => {
        if (response.status != 404) {
          this.activeSubscriptions = response.data;
        } else {
          this.activeSubscriptions = [];
        }
      })
      .finally(() => {
        this.httpServiceSubscriptionPackages.getList("packages").then(res => {
          if (res.status != 404) {
            this.allSubscriptions = this.$sortList(
              res.data,
              "subscriptionPackageName"
            );
          } else {
            this.allSubscriptions = new Array<SubscriptionPackageItem>();
          }

          this.getFilteredSubscriptions();
        });
      });
    this.showData = true;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.backRoute.id = this.$route.params.id;
    this.organisationId = this.$route.params.id;
    this.getData();
  }

  getFilteredSubscriptions() {
    let activeSubscriptions: any = this.activeSubscriptions;
    this.filteredSubscriptions = this.allSubscriptions.filter(function(
      objFromA: any
    ) {
      return !activeSubscriptions.find(function(objFromB: any) {
        return (
          objFromA.subscriptionPackageId === objFromB.subscriptionPackageId
        );
      });
    });
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceSubscription
        .postItem(
          `organisations/${this.organisationId}/subscriptions`,
          this.organisationSubscription
        )
        .then(res =>
          this.$notification.success(`Subscription successfully added!`)
        )
        .then(() => {
          this.$router.push({
            name: "organisationDashboard",
            params: { id: this.organisationId }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
