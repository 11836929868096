import AccountTypeList from "@/views/configuration/accountType/AccountTypeList.vue";
import AccountTypeEdit from "@/views/configuration/accountType/AccountTypeEdit.vue";
import AccountTypePage from "@/views/configuration/accountType/AccountTypePage.vue";
import AccountTypeAdd from "@/views/configuration/accountType/AccountTypeAdd.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const accountTypeRoutes = {
  path: "/configuration/accountTypes",

  component: AccountTypePage,
  children: [
    {
      path: "",
      component: AccountTypeList,
      name: "accountTypesList"
    },
    {
      path: "edit/:id",
      name: "accountTypesEdit",
      component: AccountTypeEdit
    },
    {
      path: "add",
      name: "accountTypesAdd",
      component: AccountTypeAdd
    }
  ]
  // beforeEnter: roleGuard
};
