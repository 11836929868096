export class ApplicationCommunication {
  applicationCommunicationId: number | null;
  communicationName: string;
  applicationName: string = "";
  applicationId: number | null;
  autoOptOut: boolean = false;
  communicationId: number | null;
  sortOrder: number;

  constructor() {
    this.applicationCommunicationId = null;
    this.communicationName = "";
    this.applicationName = "";
    this.autoOptOut = false;
    this.applicationId = null;
    this.communicationId = null;
    this.sortOrder = 0;
  }
}
