var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "bg-gray-20 border-gray-25 shadow-md border-t-2 fixed text-center md:text-right left-0 right-0 py-4 px-5 sm:px-20 bottom-0 w-full z-50"
    },
    [
      _c("h2", { staticClass: "mb-2" }, [_vm._v("You have unsaved changes")]),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "Are you sure you want to navigate away from this page? - you have unsaved changes"
        )
      ]),
      _c(
        "button",
        {
          staticClass: "btn-secondary m-1 btn-width-lg",
          on: {
            click: function($event) {
              return _vm.discard()
            }
          }
        },
        [_vm._v("Discard")]
      ),
      _c(
        "button",
        {
          staticClass: "btn-default m-1 btn-width-lg",
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_vm._v("Stay")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }