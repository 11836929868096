import { MutationTree } from "vuex";

import { DeleteAnswers } from "./types";

export const mutations: MutationTree<DeleteAnswers> = {
  DELETE_ANSWER_ARRAY(state, payLoad: { id: number }) {
    state.deleteArray.push(payLoad.id);
  },
  RESET_ANSWER_ARRAY(state) {
    state.deleteArray = [];
  }
};
