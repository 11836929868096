var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showData
    ? _c("div", { staticClass: "loader" })
    : _c(
        "div",
        [
          _c("div", { staticClass: "flex mb-4" }, [
            _c("h1", { staticClass: "block" }, [_vm._v("Clients")]),
            _c("div", { staticClass: "btn-group btn-group-positioning" }, [
              _c(
                "button",
                {
                  staticClass: "btn-default",
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [_vm._v("Add New")]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "flex mb-4 simple-list-dropdown-search flex-wrap sm:flex-wrap"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "simple-list-dropdown flex-initial mr-auto w-full sm:w-full md:w-auto mb-4 md:mb-0"
                },
                [
                  _vm._v("\n      Show:\n      "),
                  _c("dropdown-list", {
                    staticClass:
                      "inline-block focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1 ml-2 mr-2",
                    attrs: {
                      data: [{ page: 10 }, { page: 25 }],
                      accessValueField: "page",
                      selectedValue: _vm.pageSize ? _vm.pageSize : 10,
                      allowNull: false
                    },
                    on: {
                      selected: function($event) {
                        ;(_vm.pageSize = $event), (_vm.pageNumber = 1)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(_vm._s(item.page))]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "simple-list-search flex-initial w-full sm:w-full md:w-auto"
                },
                [
                  _vm._v("\n      Search:\n      "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCriteria,
                        expression: "searchCriteria"
                      }
                    ],
                    staticClass:
                      "block sm:inline-block bg-gray-25 h-8 rounded sm:ml-3 w-64 p-2 focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1",
                    domProps: { value: _vm.searchCriteria },
                    on: {
                      keydown: function($event) {
                        return _vm.keyMonitor($event, "resetPaging")
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchCriteria = $event.target.value
                      }
                    }
                  })
                ]
              )
            ]
          ),
          _c("div", { staticClass: "table-container" }, [
            _c(
              "table",
              { staticClass: "w-full table" },
              [
                _vm._m(0),
                _vm.searching
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-center",
                          attrs: { colspan: "100" }
                        },
                        [_vm._v(_vm._s(_vm.message))]
                      )
                    ])
                  : _vm._l(_vm.clients, function(client) {
                      return _c(
                        "tr",
                        {
                          key: client.id,
                          staticClass: "table-tr transition",
                          on: {
                            click: function($event) {
                              return _vm.edit(client.id)
                            }
                          }
                        },
                        [
                          _c("td", { staticClass: "table-data-left" }, [
                            _vm._v(_vm._s(client.clientName))
                          ]),
                          _c("td", { staticClass: "table-data-left" }, [
                            _vm._v(_vm._s(client.clientId))
                          ]),
                          _c("td", { staticClass: "table-data-left" }, [
                            _vm._v(_vm._s(client.clientUri))
                          ]),
                          _c("td", { staticClass: "table-data-left" }, [
                            client.enabled == true
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-green-300 text-sm block text-center"
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "check" }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-red-300 text-sm block text-center"
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "times" }
                                    })
                                  ],
                                  1
                                )
                          ]),
                          _c(
                            "td",
                            {
                              staticClass: "table-data-center",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showModalFunction(
                                    client.clientId,
                                    client.client
                                  )
                                }
                              }
                            },
                            [_vm._v("\n          Delete\n        ")]
                          )
                        ]
                      )
                    })
              ],
              2
            )
          ]),
          _c("pagination", {
            staticClass: "simple-list-pagination",
            attrs: {
              total: _vm.total,
              "per-page": _vm.pageSize,
              maxVisibleButtons: 3,
              "current-page": _vm.pageNumber,
              response: _vm.clients
            },
            on: {
              pagechanged: function($event) {
                _vm.pageNumber = $event
              }
            }
          }),
          _c(
            "modal",
            {
              attrs: { showModal: _vm.showModal },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("h2", [_vm._v("Delete")]),
              _c("p", [
                _vm._v(
                  "\n      Are you sure you want to delete the client with client Id\n      " +
                    _vm._s(this.clientIdString) +
                    "?\n    "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "modal-button block",
                  on: {
                    click: function($event) {
                      return _vm.remove()
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Client Name")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Client Id")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Client Uri")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Enabled")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }