import FormList from "@/views/forms/FormList.vue";
import FormPage from "@/views/forms/FormPage.vue";
import FormAdd from "@/views/forms/FormAdd.vue";
import FormEdit from "@/views/forms/FormEdit.vue";
export const formRoutes = {
  path: "/forms",
  component: FormPage,
  children: [
    {
      path: "",
      component: FormList,
      name: "formsList"
    },
    {
      path: "edit/:id",
      name: "formsEdit",
      component: FormEdit
    },
    {
      path: "add",
      name: "formsAdd",
      component: FormAdd
    }
  ]
};
