var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex items-center justify-between mb-2" },
          [
            _c("h2", { staticClass: "m-0" }, [_vm._v("Configure Question")]),
            _c("font-awesome-icon", {
              staticClass: "block sm:hidden cursor-pointer",
              attrs: { icon: "trash", size: "lg" },
              on: {
                click: function($event) {
                  _vm.showModal = true
                }
              }
            })
          ],
          1
        ),
        _c("label", { staticClass: "leading-loose" }, [
          _vm._v("Question Name:")
        ]),
        _c(
          "div",
          [
            _c("input-component", {
              attrs: { disabled: "" },
              model: {
                value: _vm.updatedGroupQuestion.question.name,
                callback: function($$v) {
                  _vm.$set(_vm.updatedGroupQuestion.question, "name", $$v)
                },
                expression: "updatedGroupQuestion.question.name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [
          _vm._v("Question Type:")
        ]),
        _c(
          "div",
          [
            _c("input-component", {
              attrs: { disabled: "" },
              model: {
                value: _vm.updatedGroupQuestion.question.questionType.name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.updatedGroupQuestion.question.questionType,
                    "name",
                    $$v
                  )
                },
                expression: "updatedGroupQuestion.question.questionType.name"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [
          _vm._v("Alternative Name:")
        ]),
        _c(
          "div",
          [
            _c("input-component", {
              attrs: {
                validationObj: _vm.$v.updatedGroupQuestion.alternativeName,
                placeholder: "Alternative Name"
              },
              model: {
                value: _vm.$v.updatedGroupQuestion.alternativeName.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.updatedGroupQuestion.alternativeName,
                    "$model",
                    $$v
                  )
                },
                expression: "$v.updatedGroupQuestion.alternativeName.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [_vm._v("Is Required")]),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.updatedGroupQuestion.isRequired.$model,
                expression: "$v.updatedGroupQuestion.isRequired.$model"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(
                _vm.$v.updatedGroupQuestion.isRequired.$model
              )
                ? _vm._i(_vm.$v.updatedGroupQuestion.isRequired.$model, null) >
                  -1
                : _vm.$v.updatedGroupQuestion.isRequired.$model
            },
            on: {
              change: function($event) {
                var $$a = _vm.$v.updatedGroupQuestion.isRequired.$model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.$v.updatedGroupQuestion.isRequired,
                        "$model",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.$v.updatedGroupQuestion.isRequired,
                        "$model",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(
                    _vm.$v.updatedGroupQuestion.isRequired,
                    "$model",
                    $$c
                  )
                }
              }
            }
          })
        ])
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [
          _vm._v("Column Position")
        ]),
        _c("div", [
          _c("label", { staticClass: "opt-out active block" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedGroupQuestion.columnPosition,
                  expression: "updatedGroupQuestion.columnPosition"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: 0,
                checked: _vm._q(_vm.updatedGroupQuestion.columnPosition, 0)
              },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.updatedGroupQuestion, "columnPosition", 0)
                }
              }
            }),
            _vm._v(" Left\n      ")
          ]),
          _c("label", { staticClass: "opt-out block" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedGroupQuestion.columnPosition,
                  expression: "updatedGroupQuestion.columnPosition"
                }
              ],
              attrs: { type: "radio" },
              domProps: {
                value: 1,
                checked: _vm._q(_vm.updatedGroupQuestion.columnPosition, 1)
              },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.updatedGroupQuestion, "columnPosition", 1)
                }
              }
            }),
            _vm._v(" Right\n      ")
          ])
        ])
      ]),
      _c("h2", { staticClass: "mt-12" }, [_vm._v("Answers")]),
      _vm.updatedGroupQuestion.question.questionType.questionTypeId == 4 ||
      _vm.updatedGroupQuestion.question.questionType.questionTypeId == 5 ||
      _vm.updatedGroupQuestion.question.questionType.questionTypeId == 8
        ? _c(
            "div",
            { staticClass: "max-h-screen-30 overflow-y-auto" },
            [
              _vm._l(_vm.updatedGroupQuestion.question.questionValues, function(
                singleValue
              ) {
                return _c("ul", { key: singleValue.questionValueId }, [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: singleValue.isSelected,
                          expression: "singleValue.isSelected"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(singleValue.isSelected)
                          ? _vm._i(singleValue.isSelected, null) > -1
                          : singleValue.isSelected
                      },
                      on: {
                        change: function($event) {
                          var $$a = singleValue.isSelected,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  singleValue,
                                  "isSelected",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  singleValue,
                                  "isSelected",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(singleValue, "isSelected", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v("\n        " + _vm._s(singleValue.name) + "\n      ")
                  ])
                ])
              }),
              _c("div", { staticClass: "btn-group float-none mt-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-blank-bordered",
                    on: {
                      click: function($event) {
                        _vm.showAddAnswer = true
                      }
                    }
                  },
                  [_vm._v("Add/Edit Answers")]
                )
              ])
            ],
            2
          )
        : _c("p", [
            _vm._v("There are no answers associated with this type of question")
          ]),
      _c(
        "transition",
        { attrs: { name: "fade-in-out" } },
        [
          _vm.showAddAnswer
            ? _c("new-answers", {
                attrs: {
                  groupIndex: _vm.groupIndex,
                  questionIndex: _vm.questionIndex,
                  groupQuestion: _vm.groupQuestion
                },
                on: {
                  close: function($event) {
                    _vm.showAddAnswer = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }