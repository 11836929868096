export class AccountAddress {
	accountAddressId?: string | number;
	accountId: string;
	address1: string;
	address2: string | null;
	address3: string | null;
	companyName: string | null;
	countryId: number | null;
	county: string | null;
	postCode: string;
	town: string | null;

	constructor() {
		this.accountId = "";
		this.address1 = "";
		this.address2 = null;
		this.address3 = null;
		this.companyName = null;
		this.countryId = null;
		this.county = null;
		this.postCode = "";
		this.town = null;
	}
}
