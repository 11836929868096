import CommunicationTypeList from "@/views/configuration/communicationTypes/CommunicationTypeList.vue";
import CommunicationTypeEdit from "@/views/configuration/communicationTypes/CommunicationTypeEdit.vue";
import CommunicationTypePage from "@/views/configuration/communicationTypes/CommunicationTypePage.vue";
import CommunicationTypeAdd from "@/views/configuration/communicationTypes/CommunicationTypeAdd.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const communicationTypeRoutes = {
  path: "/configuration/communicationtypes",

  component: CommunicationTypePage,
  children: [
    {
      path: "",
      component: CommunicationTypeList,
      name: "communicationTypesList"
    },
    {
      path: "edit/:id",
      name: "communicationTypesEdit",
      component: CommunicationTypeEdit
    },
    {
      path: "add",
      name: "communicationTypesAdd",
      component: CommunicationTypeAdd
    }
  ],
  beforeEnter: roleGuard
};
