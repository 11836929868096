




















































import axios, { AxiosError } from "axios";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { ApplicationCommunication } from "@/types/applicationCommunication";
import { Application } from "@/types/application";

@Component
export default class ApplicationCommunicationList extends Vue {
  @Prop() application!: Application;
  applicationCommunications: Array<ApplicationCommunication> = Array<
    ApplicationCommunication
  >();
  applicationCommunication: ApplicationCommunication = new ApplicationCommunication();
  id: number = 0;
  httpServiceArray = new HttpService<Array<ApplicationCommunication>>();
  httpService = new HttpService<ApplicationCommunication>();
  showModal: boolean = false;
  getList() {
    this.httpServiceArray
      .getList(`applications/${this.id}/communications`, {
        errorInterceptor: false
      })
      .then(
        response => {
          if (response.status != 404) {
            this.applicationCommunications = response.data;
          } else
            this.applicationCommunications = Array<ApplicationCommunication>();
        },
        error => {
          if (error.response.status === 404) {
            this.applicationCommunications = Array<ApplicationCommunication>();
          } else {
            this.$notification.error("Something went wrong");
          }
        }
      );
  }
  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = Number(this.$route.params.id);
    this.getList();
  }

  edit(applicationCommunication: ApplicationCommunication) {
    this.$router.push({
      name: "applicationCommunicationEdit",
      params: {
        id: String(applicationCommunication.applicationId),
        id2: String(applicationCommunication.applicationCommunicationId)
      }
    });
  }
  showModalFunction(applicationCommunication: ApplicationCommunication) {
    this.applicationCommunication = applicationCommunication;
    this.showModal = true;
  }
  async remove(id: number) {
    await this.httpService.deleteItem(
      `applications/${this.application.applicationId}/communications`,
      id,
      { errorInterceptor: true }
    );
    this.showModal = false;
    this.getList();
  }
}
