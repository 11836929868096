export class AccountListImportType {
  spreadSheetData: FormData | null;
  subscriptionPackageId?: number | null;
  applicationId: number | null;
  overwriteUserDetails: boolean;
  id: any;

  constructor() {
    this.spreadSheetData = null;
    this.overwriteUserDetails = false;
    this.subscriptionPackageId = null;
    this.applicationId = null;
    this.id = null;
  }
}
