import { RootState } from "../../types";
import { ToggleMenu } from "./types";
import { getters } from "./getters";

import { mutations } from "./mutations";
import { Module } from "vuex";
export const state: ToggleMenu = {
  isMenuOpen: false
};

export const toggleMenu: Module<ToggleMenu, RootState> = {
  state,
  mutations,
  getters
};
