






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DashboardClientInformation from "./DashboardClientInformation.vue";
import DashboardClientClaim from "./DashboardClientClaim.vue";
import DashboardClientProperty from "./DashboardClientProperty.vue";
import DashboardClientScope from "./DashboardClientScope.vue";
import DashboardClientGrantType from "./DashboardClientGrantType.vue";
import DashboardClientCorsOrigin from "./DashboardClientCorsOrigin.vue";
import DashboardClientPostLogoutRedirectUri from "./DashboardClientPostLogoutRedirectUri.vue";
import DashboardClientRedirectUri from "./DashboardClientRedirectUri.vue";
import DashboardClientIdPRestricition from "./DashboardClientIdPRestricition.vue";
import DashboardClientSecret from "./DashboardClientSecret.vue";
import { HttpService } from "../../../services/api/httpService";
import { ClientGrantType } from "../../../types/client";

@Component({
  components: {
    ClientInformation: DashboardClientInformation,
    ClientClaim: DashboardClientClaim,
    ClientScope: DashboardClientScope,
    GrantType: DashboardClientGrantType,
    CorsOrigin: DashboardClientCorsOrigin,
    LogoutUri: DashboardClientPostLogoutRedirectUri,
    ClientProperty: DashboardClientProperty,
    IdpRestriction: DashboardClientIdPRestricition,
    RedirectUri: DashboardClientRedirectUri,
    ClientSecret: DashboardClientSecret
  }
})
export default class ClientDashboard extends Vue {
  information = false;
  details = false;
  subscriptions = false;
  applications = false;
  organisations = false;
  panelsCompleted = 0;
  showLoader = true;
  clientType = "";

  async created() {
    const httpService = new HttpService<Array<ClientGrantType>>();
    const grantTypeResponse = await httpService.getList(
      `clients/${this.$route.params.id}/grant-types`
    );
    if (grantTypeResponse.status == 404) return;
    grantTypeResponse.data.map(grantType => {
      if (grantType.grantType == "hybrid") {
        this.clientType = "webApp";
      } else if (grantType.grantType == "authorization_code") {
        this.clientType = "spa";
      } else if (grantType.grantType == "client_credentials") {
        this.clientType = "machine";
      }
    });
  }
  get width() {
    let panelsCompleted = `${this.panelsCompleted}/10`;
    return panelsCompleted;
  }

  get spa() {
    return this.clientType == "spa" || this.clientType == "";
  }

  get spaOrWeb() {
    return (
      this.clientType == "spa" ||
      this.clientType == "webApp" ||
      this.clientType == ""
    );
  }
  get spaOrWebOrMachine() {
    return true;
  }

  get webOrMachine() {
    return (
      this.clientType == "webApp" ||
      this.clientType == "machine" ||
      this.clientType == ""
    );
  }

  increaseLoadingBar() {
    this.panelsCompleted += 1;
  }

  @Watch("panelsCompleted") showData() {
    if (this.panelsCompleted == 10) {
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
    }
  }
}
