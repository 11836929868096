import _Vue from "vue";
export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$sortList = function(list: any[], sortParameter: string) {
      const SortedList = list.sort((a, b) => {
        if (a[sortParameter].toLowerCase() < b[sortParameter].toLowerCase()) {
          return -1;
        }
        if (a[sortParameter].toLowerCase() > b[sortParameter].toLowerCase()) {
          return 1;
        }
        return 0;
      });
      return SortedList;
    };
  }
};

declare module "vue/types/vue" {
  interface Vue {
    $sortList: (arg1: {}[], arg2: string) => [];
  }
}
