import { Question } from "./question";

export class GroupQuestionType {
  groupQuestionId?: number;
  question: Question;
  sortOrder: number;
  isRequired: boolean;
  alternativeName: string;
  columnPosition: number;

  constructor() {
    (this.question = new Question()),
      (this.sortOrder = 1),
      (this.isRequired = true),
      (this.alternativeName = this.question.name),
      (this.columnPosition = 1);
  }
}
