var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-8 bg-white rounded shadow mb-8 relative mb-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c("h1", [_vm._v(_vm._s(_vm.name) + " communications")]),
        _c("div", { staticClass: "table-container" }, [
          _vm.accountCommunications.length
            ? _c(
                "table",
                { staticClass: "w-full table" },
                [
                  _vm._m(0),
                  _vm._l(_vm.accountCommunications, function(communication) {
                    return _c(
                      "tr",
                      {
                        key: communication.accountCommunicationId,
                        staticClass: "table-tr transition"
                      },
                      [
                        _c("td", { staticClass: "table-data-left" }, [
                          _vm._v(_vm._s(communication.communicationName))
                        ]),
                        _c("td", { staticClass: "table-data-left" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: communication.optOut,
                                expression: "communication.optOut"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(communication.optOut)
                                ? _vm._i(communication.optOut, null) > -1
                                : communication.optOut
                            },
                            on: {
                              change: function($event) {
                                var $$a = communication.optOut,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        communication,
                                        "optOut",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        communication,
                                        "optOut",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(communication, "optOut", $$c)
                                }
                              }
                            }
                          })
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            : _c("div", [
                _vm._v(
                  "There are no Communications associated with " +
                    _vm._s(_vm.name)
                )
              ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [
        _vm._v("Communication Name")
      ]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Opt out")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }