import { ActionTree } from "vuex";

import { SaveBar } from "./types";
import { RootState } from "../../types";

export const actions: ActionTree<SaveBar, RootState> = {
  SET_DIRTY({ commit }, payLoad: boolean) {
    commit("SET_DIRTY", payLoad);
  },

  SET_DISCARD({ commit }, payLoad: boolean) {
    commit("SET_DISCARD", payLoad);
  },

  SET_SAVE_BAR({ commit }, payLoad: boolean) {
    commit("SET_SAVE_BAR", payLoad);
  },

  SET_ROUTE({ commit }, payLoad: { name: string; params?: any }) {
    commit("SET_ROUTE", payLoad);
  }
};
