var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Communication")]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("dropdown-list", {
              attrs: {
                data: _vm.communications,
                accessValueField: "communicationId",
                selectedValue: _vm.applicationCommunication.communicationId,
                allowNull: false
              },
              on: {
                selected: function($event) {
                  _vm.$v.applicationCommunication.communicationId.$model = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(_vm._s(item.communicationName))]
                  }
                }
              ])
            }),
            _vm.$v.applicationCommunication.communicationId.$error
              ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
                  !_vm.$v.applicationCommunication.communicationId.required
                    ? _c("p", [_vm._v("This field is required")])
                    : _vm._e()
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Sort order")]),
        _c("div", { staticClass: "main-input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.applicationCommunication.sortOrder.$model,
                expression: "$v.applicationCommunication.sortOrder.$model"
              }
            ],
            class: [
              "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
              _vm.$validationStatus.status(
                _vm.$v.applicationCommunication.sortOrder
              )
            ],
            attrs: { type: "text" },
            domProps: {
              value: _vm.$v.applicationCommunication.sortOrder.$model
            },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.$v.applicationCommunication.sortOrder,
                  "$model",
                  $event.target.value
                )
              }
            }
          }),
          _vm.$v.applicationCommunication.sortOrder.$error
            ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
                !_vm.$v.applicationCommunication.sortOrder.required
                  ? _c("p", [_vm._v("This field is required")])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Auto opt out")]),
        _c("div", { staticClass: "main-input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.applicationCommunication.autoOptOut,
                expression: "applicationCommunication.autoOptOut"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.applicationCommunication.autoOptOut)
                ? _vm._i(_vm.applicationCommunication.autoOptOut, null) > -1
                : _vm.applicationCommunication.autoOptOut
            },
            on: {
              change: function($event) {
                var $$a = _vm.applicationCommunication.autoOptOut,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.applicationCommunication,
                        "autoOptOut",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.applicationCommunication,
                        "autoOptOut",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.applicationCommunication, "autoOptOut", $$c)
                }
              }
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }