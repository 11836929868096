var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "table-container" }, [
        _c(
          "table",
          { staticClass: "w-full table" },
          [
            _vm._m(0),
            _vm.applicationCommunications.length === 0
              ? _c("tr", { staticClass: "text-center" }, [
                  _c("td", { attrs: { colspan: "100" } }, [
                    _vm._v(
                      "No communications associated with " +
                        _vm._s(_vm.application.applicationName)
                    )
                  ])
                ])
              : _vm._l(_vm.applicationCommunications, function(
                  applicationCommunication
                ) {
                  return _c(
                    "tr",
                    {
                      key: applicationCommunication.applicationCommunicationId,
                      staticClass: "table-tr transition",
                      on: {
                        click: function($event) {
                          return _vm.edit(applicationCommunication)
                        }
                      }
                    },
                    [
                      _c("td", { staticClass: "table-data-left" }, [
                        _c("h3", [
                          _vm._v(
                            _vm._s(applicationCommunication.communicationName)
                          )
                        ])
                      ]),
                      _c("td", { staticClass: "table-data-left" }, [
                        _c("h3", [
                          _vm._v(_vm._s(applicationCommunication.sortOrder))
                        ])
                      ]),
                      _c(
                        "td",
                        { staticClass: "table-data-left" },
                        [
                          _c("tick-cross", {
                            attrs: {
                              value: applicationCommunication.autoOptOut
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "border border-gray-25 w-24 text-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "px-8 py-4 cursor-pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showModalFunction(
                                    applicationCommunication
                                  )
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ]
                      )
                    ]
                  )
                })
          ],
          2
        )
      ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _vm._v("\n    Are you sure you want to delete\n    "),
          _c("strong", [
            _vm._v(_vm._s(_vm.applicationCommunication.communicationName))
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove(
                    _vm.applicationCommunication.applicationCommunicationId
                  )
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Communication")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Sort Order")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Auto Opt Out?")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }