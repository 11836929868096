import { RootState } from "../../types";
import { SaveBar } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { Module } from "vuex";

export const state: SaveBar = {
  save: false,
  dirtyFlag: false,
  discardBar: false,
  back: false,
  showSave: false,
  forwardRoute: undefined
};

export const saveBar: Module<SaveBar, RootState> = {
  state,
  mutations,
  actions,
  getters
};
