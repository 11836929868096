





















































import { Component, Vue, Watch } from "vue-property-decorator";
import { CommunicationType } from "@/types/communicationType";
import { Communication } from "@/types/communication";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import GetJson from "@/components/GetJson.vue";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  components: { GetJson },
  validations: {
    communication: {
      communicationName: { required, maxLength: maxLength(100) },
      comment: { maxLength: maxLength(100) },
      communicationTypeId: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class CommunicationAdd extends Vue {
  communication: Communication = new Communication();
  backRoute = {
    name: "communicationsList"
  };
  httpServiceCommunication = new HttpService<Communication>();

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceCommunication
        .postItem("communications", this.communication)
        .then(() => {
          this.$router.push(this.backRoute);
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
