











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";

import { QuestionType } from "../../../../../types/questionType";
import { Question, QuestionAdder } from "../../../../../types/question";
import { HttpService } from "../../../../../services/api/httpService";
import QuestionValueList from "./newQuestionValues/QuestionValueList.vue";
import { QuestionValue } from "../../../../../types/questionValue";
import GroupQuestion from "../GroupQuestion.vue";
import QuestionAdd from "../../../../configuration/questions/QuestionAdd.vue";

const myQuestion: QuestionAdder = new QuestionAdder();
@Component({
  components: {
    QuestionValueList
  },
  validations: {
    question: {
      name: { required },
      isRequired: {},
      maximumLength: {},
      questionTypeId: { required }
    },
    questionValues: {
      required: requiredIf(function(vm) {
        return (
          myQuestion.questionTypeId == 4 ||
          myQuestion.questionTypeId == 5 ||
          myQuestion.questionTypeId == 8
        );
      })
    }
  }
})
export default class NewQuestion extends Vue {
  @Prop() groupIndex!: number;

  question: QuestionAdder = new QuestionAdder();
  questionValues = Array<QuestionValue>();
  questionTypes: Array<QuestionType> = Array<QuestionType>();

  created() {
    let httpService = new HttpService<Array<QuestionType>>();
    httpService
      .getList("questiontypes")
      .then(response => (this.questionTypes = response.data))
      .catch(error => {
        console.error(error);
      });
  }
  @Watch("question", { deep: true }) updateVuelidate() {
    myQuestion.questionTypeId = this.question.questionTypeId;
  }
  add(e: QuestionValue) {
    this.questionValues.push(e);
  }

  async save() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpsServiceQuestion = new HttpService<QuestionAdder>();
      let httpServiceValue = new HttpService<QuestionValue>();
      let groupQuestion = new GroupQuestion();

      await httpsServiceQuestion
        .postItem("questions", this.question)
        .then(async response => {
          for (let value of this.questionValues) {
            value.isSelected = true;
            await httpServiceValue.postItem(
              `questions/${response.data.questionId}/values`,
              value
            );
          }
          await this.$store.dispatch("SET_QUESTIONS", "questions");

          this.$emit("newQuestion", response.data);
        });
      this.$emit("close");
    }
  }
  cancel() {
    this.$emit("close");
  }
}
