


































import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientClaim } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientClaim: {
      type: { required, maxLength: maxLength(250) },
      value: { required, maxLength: maxLength(250) }
    }
  }
})
export default class ClientClaimSave extends Vue {
  clientClaim = new ClientClaim();
  httpService = new HttpService<ClientClaim>();
  backRoute = {
    name: "clientDashboard",
    id: ""
  };
  showData = false;
  async created() {
    if (this.$route.params.claimId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/claims/${this.$route.params.claimId}`
      );

      this.clientClaim = response.data || new ClientClaim();
    }
    this.clientClaim.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;

    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";
      this.clientClaim.id
        ? await this.httpService.putItem(
            `clients/${this.clientClaim.clientId}/claims/${this.clientClaim.id}`,
            this.clientClaim
          )
        : await this.httpService.postItem(
            `clients/${this.clientClaim.clientId}/claims/`,
            this.clientClaim
          );
      this.$notification.success("Client Claim successfully saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
