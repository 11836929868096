var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "px-3 py-2 md:px-8 md:py-4 bg-green-100 text-white" },
      [
        _c("div", { staticClass: "flex flex-row items-center" }, [
          _c("h3", { staticClass: "mb-0 ml-6" }, [
            _c("strong", { staticClass: "hidden md:inline-block" }, [
              _vm._v("Group Name:")
            ]),
            _vm._v("\n        " + _vm._s(_vm.group.name) + "\n      ")
          ])
        ])
      ]
    ),
    !_vm.group.groupQuestions.length
      ? _c("div", { staticClass: "text-center my-2" }, [
          _vm._v("There are no questions associated with this group")
        ])
      : _c(
          "div",
          _vm._l(_vm.group.groupQuestions, function(groupQuestion) {
            return _c("div", { key: groupQuestion.groupQuestionId }, [
              _c("div", { staticClass: "input-container question my-3" }, [
                _c(
                  "div",
                  { staticClass: "input-label pr-3" },
                  [
                    groupQuestion.alternativeName
                      ? [_vm._v(_vm._s(groupQuestion.alternativeName))]
                      : [_vm._v(_vm._s(groupQuestion.question.name))],
                    groupQuestion.isRequired
                      ? _c("span", { staticClass: "text-red-600" }, [
                          _vm._v("*")
                        ])
                      : void 0
                  ],
                  2
                ),
                _c("div", { staticClass: "main-input" }, [
                  groupQuestion.question.questionType.name === "textbox"
                    ? _c(
                        "div",
                        [
                          _c("text-box-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  groupQuestion.question.questionType.name === "textarea"
                    ? _c(
                        "div",
                        [
                          _c("text-area-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  groupQuestion.question.questionType.name === "radio"
                    ? _c(
                        "div",
                        [
                          _c("radio-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  groupQuestion.question.questionType.name === "checkbox"
                    ? _c(
                        "div",
                        [
                          _c("checkbox-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  groupQuestion.question.questionType.name === "dropdownlist"
                    ? _c(
                        "div",
                        [
                          _c("dropdown-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  groupQuestion.question.questionType.name === "date"
                    ? _c(
                        "div",
                        [
                          _c("date-question", {
                            attrs: { groupQuestion: groupQuestion }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }