import { render, staticRenderFns } from "./QuestionTypePage.vue?vue&type=template&id=4d3fb6fb&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d3fb6fb')) {
      api.createRecord('4d3fb6fb', component.options)
    } else {
      api.reload('4d3fb6fb', component.options)
    }
    module.hot.accept("./QuestionTypePage.vue?vue&type=template&id=4d3fb6fb&", function () {
      api.rerender('4d3fb6fb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/configuration/questionType/QuestionTypePage.vue"
export default component.exports