import "izitoast/dist/css/iziToast.min.css";
import iZtoast, { IziToast } from "izitoast/";
import _Vue from "vue";

export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$notification = {
      error: (
        message: string = "An error occured",
        title: string = "Error",
        timeout = 5000
      ) => {
        return iZtoast.error({
          title: title,
          message: message,
          position: "bottomCenter",
          timeout: timeout
        });
      },

      success: (message: string, title = "Success", timeout = 2500) => {
        return iZtoast.success({
          title: title,
          message: message,
          position: "bottomCenter",
          timeout: timeout
        });
      }
    };
  }
};

declare module "vue/types/vue" {
  interface Vue {
    $notification: {
      error: (message: string, title?: string, timeout?: number) => IziToast;
      success: (
        message: string,
        title?: string,
        timeout?: number | boolean
      ) => IziToast;
    };
  }
}
