var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showData
    ? _c("div", { staticClass: "loader" })
    : _c(
        "save-bar",
        { attrs: { showSave: _vm.showSave } },
        [
          _c("communications", {
            attrs: {
              name: _vm.applicationData.application.applicationName,
              accountCommunications: _vm.accountCommunications
            }
          }),
          _c("form-data", {
            attrs: {
              accountId: _vm.accountId,
              name: _vm.applicationData.application.applicationName,
              formData: _vm.applicationData.application.form
            },
            model: {
              value: _vm.applicationData.formCompleted,
              callback: function($$v) {
                _vm.$set(_vm.applicationData, "formCompleted", $$v)
              },
              expression: "applicationData.formCompleted"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }