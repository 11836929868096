






import Chart from "chart.js";
import axios from "axios";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class StatisticsChart extends Vue {
  @Prop() direction!: string;
  @Prop() xData!: string;
  @Prop() yData!: string;
  @Prop() chartInfo!: string;
  @Prop() chartName!: string;
  @Prop({ default: "bar" }) chartType!: string;
  @Prop() chartFill!: boolean;
  @Prop({ default: ["#f66580"] })
  chartBackgroundColor!: string[];
  @Prop({ default: ["#f4a7b5"] })

  maintainAspectRatio!: boolean;
  @Prop({ default: true })
  chartBorderColor!: string[];
  statsChart: any = {
    type: this.chartType,
    data: {
      labels: [],
      datasets: [
        {
          label: this.chartInfo,
          data: [],
          backgroundColor: this.chartBackgroundColor,
          borderColor: this.chartBorderColor,
          borderWidth: 3,
          fill: this.chartFill
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: this.maintainAspectRatio,
      lineTension: 5,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 25
            }
          }
        ]
      }
    }
  };

  createChart(chartId: string, chartData: any) {
    const ctx: any = document.getElementById(chartId);
    const myChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options
    });
  }

  created() {
    axios
      .get(this.direction)
      .then(res => {
        const lastLogin = [];
        const yAxis: string[] = [];
        const xAxis: string[] = [];
        const data = res.data;
        for (let key in res.data) {
          const user = res.data[key];
          xAxis.push(user[this.xData]);
          yAxis.push(user[this.yData]);
          lastLogin.push(user);
        }

        this.statsChart.data.datasets[0].data.push(...yAxis);
        this.statsChart.data.labels.push(...xAxis);
      })
      .then(() => this.createChart(this.chartName, this.statsChart));
  }
}
