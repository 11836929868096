import Dashboard from "@/views/dashboard/Dashboard.vue";

export const dashboardRoutes = {
  path: "/",
  component: Dashboard,
  name: "dashboard",
  meta: { layout: "simple" },
  beforeEnter: (to: any, from: any, next: any) => {
    next({ path: "/individuals" }); //TODO implement the dashboard and remove this route guard
  }
};
