
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class onclickOutside extends Vue {
  @Prop() close!: () => void;
  mounted() {
    const listener: any = (e: { target: Node }) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        return;
      }
      this.close();
    };
    document.addEventListener("mousedown", listener);
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("mousedown", listener);
    });
  }
  render() {
    if (!this.$slots.default || this.$slots.default.length === 0) {
      return "";
    } else if (this.$slots.default.length === 1) {
      return this.$slots.default[0];
    }
  }
}
