var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupQuestion.question.questionValues, function(value) {
      return _c("div", { key: value.questionValueId }, [
        _c("input", {
          attrs: { type: "radio", id: value.questionValueId },
          domProps: { checked: _vm.checkAnswer(value) },
          on: {
            change: function($event) {
              _vm.checkRadio(
                value,
                _vm.checkAnswer(value, _vm.groupQuestion),
                _vm.groupQuestion.question.answers[0]
              )
            }
          }
        }),
        _c("label", { attrs: { for: value.questionValueId } }, [
          _vm._v(_vm._s(value.name))
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }