









































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Organisation } from "@/types/organisation";
import { OrganisationAccountPost } from "@/types/organisationAccount";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required } from "vuelidate/lib/validators";
import { AccountOrganisation } from "../../../types/accountOrganisation";
import { Account } from "../../../types/account";

@Component({
  mixins: [SaveMixin],
  validations: {
    organisationId: { required },
    owner: {}
  }
})
export default class AccountOrganisationAdd extends Vue {
  accountId = "";
  showData = false;
  organisationId = "";
  accountName = "";
  owner = false;
  filteredOrganisations = new Array<Organisation>();
  backRoute = {
    name: "accountDashboard",
    id: ""
  };
  get title() {
    return `${this.accountName} - Add To An Organisation`;
  }
  async getData() {
    const httpServiceAccount = new HttpService<Account>();
    const response3 = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response3.data.fullName;
    const httpAccountOrganisations = new HttpService<
      Array<AccountOrganisation>
    >();
    const httpOrganisations = new HttpService<Array<Organisation>>();
    let currentOrganisations: Array<AccountOrganisation> = [];
    let allOrganisations = new Array<Organisation>();
    const response = await httpAccountOrganisations.getListPaging(
      `accounts/${this.accountId}/organisations`
    );

    if (response.status != 404) {
      currentOrganisations = response.data;
    } else {
      currentOrganisations = [];
    }

    const response2 = await httpOrganisations.getList("organisations");

    if (response2.status != 404) {
      allOrganisations = this.$sortList(response2.data, "organisationName");
    }

    this.getFilteredOrganisations(currentOrganisations, allOrganisations);
  }

  @Watch("$route", { immediate: true }) getId() {
    this.accountId = this.$route.params.id;
    this.backRoute.id = this.$route.params.id;
    this.getData();
  }

  getFilteredOrganisations(
    currentOrganisations: Array<AccountOrganisation>,
    allOrganisations: Array<Organisation>
  ) {
    this.filteredOrganisations = allOrganisations.filter(organisation => {
      return !currentOrganisations.find(currentOrganisation => {
        return (
          currentOrganisation.organisation.organisationId ===
          organisation.organisationId
        );
      });
    });
    this.showData = true;
  }

  async save() {
    const httpService = new HttpService<OrganisationAccountPost>();
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const response = await httpService.postItem(
        `organisations/${this.organisationId}/accounts`,
        {
          accountId: this.accountId,
          owner: this.owner
        }
      );
      if (response.status != 404)
        this.$notification.success(
          `account successfully added to organisation !`
        );

      this.$router.push({
        name: "accountDashboard",
        params: { id: this.accountId }
      }),
        this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
