


















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Question } from "@/types/question";
@Component
export default class QuestionsList extends Vue {
  total = 0;
  searchCriteria = "";
  httpServiceArray = new HttpService<Array<Question>>();
  httpService = new HttpService<Question>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<any> = new Array<Question>();
  showModal: boolean = false;
  removeData: Question = new Question();

  get updatedResponseArray() {
    for (let res of this.responseArray) {
      res.questionType = res.questionType.name;
    }
    return this.responseArray;
  }
  getList() {
    this.httpServiceArray
      .getListSearch(
        "questions",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          this.total = JSON.parse(response.headers["x-pagination"]).totalNumber;
        } else this.responseArray = new Array<Question>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "questionsAdd"
    });
  }
  confirmRemove(data: Question) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: Question) {
    await this.httpService
      .deleteItem("questions", data.questionId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: Question) => item.questionId != data.questionId,
          this.$notification.success(`${data.name} successfully removed!`)
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: Question) {
    this.$router.push({
      name: `questionsEdit`,
      params: { id: data.questionId }
    });
  }
}
