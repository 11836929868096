import { GroupQuestionType } from "./groupQuestion";

export class GroupType {
  uniqueId?: number;
  name: string;
  formGroupId?: number;
  description?: string;
  sortOrder: number;
  multipleGroups: boolean;
  groupLimit: number;
  groupQuestions: Array<GroupQuestionType>;

  constructor() {
    (this.name = "New Group"),
      (this.uniqueId = 0.9),
      (this.sortOrder = 1),
      (this.multipleGroups = true),
      (this.groupLimit = 5),
      (this.groupQuestions = Array<GroupQuestionType>());
  }
}
