



























































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { AccountSubscription } from "@/types/accountSubscription";
import DatePicker from "@/components/DatePicker.vue";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import moment from "moment";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { ValidationProperties } from "vue/types/vue";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { Account } from "../../../types/account";
const endDateGraterThanStartDate = (endDate: string, vm: any) => {
  if (!moment(endDate).isValid()) {
    return true;
  }
  return moment(endDate).startOf("day") >= moment(vm.startDate).startOf("day");
};

@Component({
  mixins: [SaveMixin],
  components: { DatePicker },
  validations: {
    accountSubscription: {
      subscriptionPackageId: { required },
      trial: { required },
      perpetual: { required },
      suspended: { required },
      startDate: { required },
      endDate: { endDateGraterThanStartDate },
      notes: { maxLength: maxLength(1000) }
    }
  }
})
export default class AccountSubscriptionEdit extends Vue {
  showData = false;
  accountId: string = "";
  accountName = "";
  today = new Date();
  accountSubscriptionId: string = "";
  accountSubscription = new AccountSubscription();
  httpServiceSubscrption = new HttpService<AccountSubscription>();
  httpServiceSubscriptionItem = new HttpService<SubscriptionPackageItem>();
  httpServiceAccountSubscrptions = new HttpService<
    Array<AccountSubscription>
  >();
  httpServiceSubscriptionPackages = new HttpService<
    Array<SubscriptionPackageItem>
  >();

  activeSubscriptions = new Array<AccountSubscription>();
  allSubscriptions = new Array<SubscriptionPackageItem>();
  filteredSubscriptions = new Array<SubscriptionPackageItem>();
  backRoute = {
    name: "accountDashboard",
    id: ""
  };
  get title() {
    return `${this.accountName} - ${this.accountSubscription.subscriptionPackageName}`;
  }
  async getSubscrptionData() {
    const httpServiceAccount = new HttpService<Account>();
    const response = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response.data.fullName;
    const response2 = await this.httpServiceSubscrption.getItem(
      `accounts/${this.accountId}/subscriptions/${this.accountSubscriptionId}`
    );
    const response3 = await this.httpServiceSubscriptionItem.getItem(
      `packages/${response2.data.subscriptionPackageId}`
    );
    response2.data.subscriptionPackageName =
      response3.data.subscriptionPackageName;
    this.accountSubscription = response2.data;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.accountId = this.$route.params.id;
    this.accountSubscriptionId = this.$route.params.accountSubscriptionId;
    this.getFilteredSubscriptions();
    this.getSubscrptionData();
    this.accountSubscription = new AccountSubscription();
  }

  created() {
    this.backRoute.id = this.$route.params.id;
  }

  async getFilteredSubscriptions() {
    const response = await this.httpServiceAccountSubscrptions.getItem(
      `accounts/${this.accountId}/subscriptions`
    );

    this.activeSubscriptions = response.data;
    const response2 = await this.httpServiceSubscriptionPackages.getList(
      "packages"
    );
    this.allSubscriptions = response2.data;
    let activeSubscriptions = this.activeSubscriptions;
    this.filteredSubscriptions = this.allSubscriptions.filter(subscription => {
      return !activeSubscriptions.find(filteredSubscription => {
        return (
          subscription.subscriptionPackageId ===
          filteredSubscription.subscriptionPackageId
        );
      });
    });
    this.showData = true;
  }

  save() {
    this.$store.commit("SAVE", false);
    if (!this.$v.$invalid) {
      this.httpServiceSubscrption
        .putItem(
          `accounts/${this.accountId}/subscriptions/${this.accountSubscriptionId}`,
          this.accountSubscription
        )
        .then(res => {
          this.$notification.success(`Subscription successfully updated!`);

          this.$router.push({
            name: "accountDashboard",
            params: { id: this.accountId }
          });
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
