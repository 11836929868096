


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosPromise } from "axios";
import { CommunicationType } from "@/types/communicationType";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    communicationType: {
      communicationTypeName: { required }
    }
  }
})
export default class CommunicationTypeEdit extends Vue {
  communicationType: CommunicationType = new CommunicationType();
  httpService: HttpService<CommunicationType> = new HttpService<
    CommunicationType
  >();
  backRoute = { name: "communicationTypesList" };

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpService.postItem(
        "communicationtypes",
        this.communicationType
      );

      this.$notification.success("Communication type successfully saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
