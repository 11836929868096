
























































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Account } from "@/types/account";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { AccountAddress } from "@/types/accountAddress";
import {
  isPhone,
  isPostCode
} from "@/services/customValidator/customValidators";
import { AccountType } from "../../types/accountType";
import { Country } from "../../types/country";
import { TitleMixin } from "../../services/mixins/titleMixin";

@Component({
  validations: {
    account: {
      firstName: { required, maxLength: maxLength(50) },
      lastName: { required, maxLength: maxLength(50) },
      jobTitle: {},
      email: { email, required, maxLength: maxLength(256) },
      accountTypeId: { required },
      optOutThirdPartyMail: {},
      optOutThirdPartyEmail: {},
      optOutThirdPartyTelephone: {},
      optOutMail: {},
      optOutEmail: {},
      optOutTelephone: {},
      isSuspended: {},
      isActive: {},
      phoneNumber: { isPhone },
      userName: { required }
    },
    address: {
      postCode: {
        isPostCode,
        required: requiredUnless(vm => {
          return Object.values(vm).every(x => x === null || x === "");
        }),
        maxLength: maxLength(20)
      },
      address1: {
        required: requiredUnless(vm => {
          return Object.values(vm).every(x => x === null || x === "");
        }),
        maxLength: maxLength(100)
      },
      address2: { maxLength: maxLength(100) },
      address3: { maxLength: maxLength(100) },
      town: { maxLength: maxLength(50) },
      county: { maxLength: maxLength(50) },
      countryId: {
        required: requiredUnless(vm => {
          return Object.values(vm).every(x => x === null || x === "");
        })
      },
      countryName: {},

      companyName: {}
    }
  },
  mixins: [SaveMixin, TitleMixin]
})
export default class AccountAdd extends Vue {
  get title() {
    return "New Individual";
  }
  showData = false;
  id: string = "";
  accountTypes: Array<AccountType> = [];
  account: Account = new Account();
  address: AccountAddress = new AccountAddress();
  httpServiceAccount: HttpService<Account> = new HttpService<Account>();
  countries = new Array<Country>();
  httpServiceAddress: HttpService<AccountAddress> = new HttpService<
    AccountAddress
  >();
  backRoute = {
    name: "accountsList"
  };

  created() {
    this.getData();
  }
  async getData() {
    const httpService = new HttpService<Array<AccountType>>();
    const httpServiceCountry = new HttpService<Array<Country>>();
    const response = await httpService.getListPaging(
      "accountTypes",
      25,
      1,
      "accountTypeName"
    );
    const response2 = await httpServiceCountry.getListPaging(
      "countries",
      1000,
      1,
      "countryName"
    );
    this.accountTypes = response.data;
    this.countries = response2.data;
    this.showData = true;
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.account.userName = this.account.email;
    this.$v.$touch();

    if (!this.$v.$invalid) {
      await this.httpServiceAccount
        .postItem("accounts", this.account)
        .then(response => {
          this.id = response.data.id;
        })
        .then(() => {
          this.address.accountId = this.id;
        })
        .then(() => {
          if (this.address.address1 != "") {
            this.httpServiceAddress.postItem("address", this.address);
          }
        })
        .then(() => {
          this.$notification.success("Account details successfully saved");
          this.$store.dispatch("SET_DIRTY", false);
          this.$router.push({
            name: "accountDashboard",
            params: { id: this.id }
          });
        });
    }
  }
}
