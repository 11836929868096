











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfigureQuestion from "./ConfigureQuestion.vue";
import { GroupQuestionType } from "@/types/groupQuestion";
import SideModal from "@/components/SideModal.vue";
@Component({ components: { ConfigureQuestion, SideModal } })
export default class GroupQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;
  @Prop() groupId!: number;
  @Prop() questionOrder!: number;
  @Prop() groupIndex!: number;
  showModal: boolean = false;
  showConfigureQuestion = false;

  configureQuestion() {
    this.showConfigureQuestion = true;
    this.$emit("configQ", {
      groupQuestion: this.groupQuestion,
      questionIndex: this.questionOrder
    });
  }
  @Watch("groupQuestion", { deep: true }) checkGroup() {
    this.$store.dispatch("SET_DIRTY", true);
  }

  removeQuestion() {
    this.$store.dispatch("REMOVE_QUESTION", {
      questionOrder: this.questionOrder,
      groupIndex: this.groupIndex
    });
    if (this.groupQuestion.groupQuestionId) {
      this.$store.commit("DELETE_QUESTION_ARRAY", {
        groupQuestionId: this.groupQuestion.groupQuestionId,
        groupId: this.groupId
      });
    }
  }
}
