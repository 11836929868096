export class QuestionValue {
  name: string;
  questionValueId?: any;
  isSelected: boolean;
  sortOrder: number | null;

  constructor() {
    this.isSelected = true;
    this.name = "";
    this.sortOrder = null;
  }
}
