




































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationAddApplication } from "@/types/organisationAddApplication";
import { OrganisationApplication } from "@/types/organisationApplication";
import { Application } from "@/types/application";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required } from "vuelidate/lib/validators";
import { Organisation } from "../../types/organisation";

@Component({
  validations: {
    organisationApplication: {
      applicationId: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationApplicationAdd extends Vue {
  showData = false;
  organisationId: any = "";
  organisationApplication = new OrganisationAddApplication();
  httpServiceApplications = new HttpService<Array<Application>>();
  organisationName = "";
  httpServiceOrganisationAddApplication = new HttpService<
    OrganisationAddApplication
  >();
  httpServiceOrganisationApplication = new HttpService<
    Array<OrganisationApplication>
  >();

  activeApplications = new Array<OrganisationApplication>();
  allApplications = new Array<Application>();
  filteredApplications = new Array<Application>();
  backRoute: { name: string; id?: string } = {
    name: "organisationDashboard",
    id: ""
  };
  get title() {
    return `${this.organisationName} - Add Application`;
  }
  async getData() {
    const httpService = new HttpService<Organisation>();
    const response3 = await httpService.getItem(
      `organisations/${this.organisationId}`
    );

    this.organisationName = response3.data.organisationName;
    const response = await this.httpServiceOrganisationApplication.getItem(
      `organisations/${this.organisationId}/applications`,
      {
        errorInterceptor: false
      }
    );

    if (response.status != 404) {
      this.activeApplications = response.data;
    } else {
      this.activeApplications = [];
    }

    const response2 = await this.httpServiceApplications.getList(
      "applications"
    );
    if (response2.status != 404) {
      this.allApplications = this.$sortList(response2.data, "applicationName");
    } else {
      this.allApplications = new Array<Application>();
    }

    this.getFilteredApplications();

    this.showData = true;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.backRoute.id = this.$route.params.id;
    this.organisationId = this.$route.params.id;
    this.getData();
  }

  getFilteredApplications() {
    let activeApplications: Array<OrganisationApplication> = this
      .activeApplications;

    this.filteredApplications = this.allApplications.filter(
      (objFromA: Application) => {
        return !activeApplications.find(function(
          objFromB: OrganisationApplication
        ) {
          return objFromA.applicationId === objFromB.application.applicationId;
        });
      }
    );
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceOrganisationAddApplication
        .postItem(
          `organisations/${this.organisationId}/applications/${this.organisationApplication.applicationId}`
        )
        .then(res =>
          this.$notification.success(`Application successfully added!`)
        )
        .then(() => {
          this.$router.push(this.backRoute),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
