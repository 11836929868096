export class AccountPassword {
  password: string;
  policy?: Policy

  constructor(password: string = "") {
    this.password = password;
  }
}

class Policy {
  requiredLength: number;
  requiredUniqueChars: number;
  requireNonAlphanumeric: boolean;
  requireLowercase: boolean;
  requireUppercase: boolean;
  requireDigit: boolean;

  constructor() {
    this.requiredLength = 8;
    this.requiredUniqueChars = 1;
    this.requireNonAlphanumeric = true;
    this.requireLowercase = true;
    this.requireUppercase = true
    this.requireDigit = true
  }
}
