
import { Validation } from 'vuelidate'
import _Vue from "vue";

export default {

    install(Vue: typeof _Vue) {
        Vue.prototype.$validationStatus = {
            status: (validation: Validation) => {
                if (validation != undefined) {
                    return {
                        error: validation.$error
                    };
                }
            }
        }
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $validationStatus: {
            status: (validation: Validation) => Validation
        };
    }
}
