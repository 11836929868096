import { GetterTree } from "vuex";
import { SaveBar } from "./types";
import { RootState } from "../../types";
export const getters: GetterTree<SaveBar, RootState> = {
  getSaveEvent(state) {
    return state.save;
  },
  getDirtyFlag(state) {
    return state.dirtyFlag;
  },

  getDiscardBar(state) {
    return state.discardBar;
  },

  getBackEvent(state) {
    return state.back;
  },

  getForwardRoute(state) {
    return state.forwardRoute;
  }
};
