var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupQuestion.question.questionValues, function(value) {
      return _c("checkbox-component", {
        key: value.questionValueId,
        attrs: {
          label: value.name,
          checked: _vm.checkAnswer(value, _vm.groupQuestion)
        },
        on: {
          change: function($event) {
            _vm.check(
              value,
              _vm.groupQuestion,
              _vm.checkAnswer(value, _vm.groupQuestion)
            )
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }