

















import { Vue, Component, Prop } from "vue-property-decorator";
import StatisticsChart from "./StatisticsChart.vue";

@Component({
  components: {
    StatisticsChart
  }
})
export default class Panel extends Vue {
  @Prop() direction!: string;
  @Prop() xData!: string;
  @Prop() yData!: string;
  @Prop() chartInfo!: string;
  @Prop() chartName!: string;
  @Prop() chartType!: string;
  @Prop() chartFill!: boolean;
  @Prop() chartBackgroundColor!: string[];
  @Prop() chartBorderColor!: string[];
  @Prop() chartHeight!: string;
  @Prop() maintainAspectRatio!: boolean;
  navigateToStatisticsPage() {
    this.$router.push({ name: "statisticsPage" });
  }
}
