import { GetterTree } from "vuex";
import { Role } from "./types";
import { RootState } from "../../types";
export const getters: GetterTree<Role, RootState> = {
  getRole(state) {
    if (state.role === "toDo") return true;
    //TODO when we have roles we can amend this
    else return false;
  }
};
