import QuestionList from "@/views/configuration/questions/QuestionList.vue";
import QuestionPage from "@/views/configuration/questions/QuestionPage.vue";
import QuestionEdit from "@/views/configuration/questions/QuestionEdit.vue";
import QuestionAdd from "@/views/configuration/questions/QuestionAdd.vue";
import QuestionValueAdd from "@/views/configuration/questions/questionValue/QuestionValueAdd.vue";
import QuestionValueEdit from "@/views/configuration/questions/questionValue/QuestionValueEdit.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const questionRoutes = {
  path: "/configuration/questions",
  component: QuestionPage,
  children: [
    {
      path: "",
      component: QuestionList,
      name: "questionsList"
    },
    {
      path: "edit/:id",
      name: "questionsEdit",
      component: QuestionEdit
    },
    {
      path: "add",
      name: "questionsAdd",
      component: QuestionAdd
    },
    {
      path: "/:id/add-answer",
      name: "questionValueAdd",
      component: QuestionValueAdd
    },
    {
      path: "/:id/answers/:id2",
      name: "questionValueEdit",
      component: QuestionValueEdit
    }
  ],
  beforeEnter: roleGuard
};
