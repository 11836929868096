var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded p-8 shadow relative mb-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: { tag: "button", to: { name: "clientInformationEdit" } }
              },
              [_vm._v("Edit")]
            )
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "flex -mx-4 flex-wrap" }, [
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Client Name")
              ]),
              _vm._v(
                "\n          " + _vm._s(_vm.client.clientName) + "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Client ID")
              ]),
              _vm._v(
                "\n          " + _vm._s(_vm.client.clientId) + "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Client URI")
              ]),
              _vm._v(
                "\n          " + _vm._s(_vm.client.clientUri) + "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Client Type:")
              ]),
              _vm._v("\n          " + _vm._s(_vm.clientType) + "\n        ")
            ])
          ]),
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Logout URI")
              ]),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.client.frontChannelLogoutUri) +
                  "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "xl:w-1/3 md:w-1/3 sm:w-1/2 w-full px-4" }, [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Allow Offline Access")
                ]),
                _c("tick-cross", {
                  staticClass: "ml-20",
                  attrs: { value: _vm.client.allowOfflineAccess }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sm:flex flex-col text-xl md:flex-row py-4" },
      [_c("p", [_vm._v("Client  ")]), _c("p", [_vm._v("information")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }