import moment from "moment";
import { QuestionType } from "@/types/questionType";
import { Question } from "@/types/question";
import { __values } from "tslib";

export const containNum = (value: any) => {
  const regex = /\d/;
  return regex.test(value);
};

export const containNonAlphaNum = (value: any) => {
  const regex = /^[a-zA-Z0-9]+$/;
  return !regex.test(value);
};

export const containUpperCaseLettter = (value: any) => {
  const regex = /[A-Z]/;
  return regex.test(value);
};
export const containLowerCaseLettter = (value: any) => {
  const regex = /[a-z]/;
  return regex.test(value);
};

export const endDateGreaterThanStartDate = (endDate: string, vm: any) => {
  if (!moment(endDate).isValid()) {
    return true;
  }
  return moment(endDate).startOf("day") >= moment(vm.startDate).startOf("day");
};

export const dateGreaterThanToday = (date: string, vm: any) => {
  if (!moment(date).isValid()) {
    return true;
  }

  return moment(date).startOf("day") >= moment().startOf("day");
};

export const domain = (value: string) => {
  const domain = /^(?!www.*$).*(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  return domain.test(value);
};

export const isRequired = (question: Question) => {
  if (question.isRequired) {
    return true;
  }
};
export const isPhone = (value?: string) => {
  if (value)
    return /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*|\s*)$/.test(value);
  else return true;
};

export const isPostCode = (value?: string) => {
  if (value) {
    return /^[a-zA-Z0-9-,./ ]+$/.test(value);
  } else return true;
};

export const isSpreadSheet = (value: File) => {
  if (!value) return true;
  if (
    value.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    value.type === "application/vnd.ms-excel"
  )
    return true;
  else return false;
};

export const isUrl = (value: string | null) => {
  const urlRegex = /^https?:\/\//;
  if (typeof value == "string" && value != "") return urlRegex.test(value);
  else return true;
};
