var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Add Answers")]),
    _c(
      "div",
      { staticClass: "table-container max-h-screen-60 overflow-y-auto" },
      [
        _c(
          "table",
          { staticClass: "table w-full" },
          [
            _vm._m(0),
            _vm._l(_vm.values, function(questionValue, index) {
              return _c(
                "tr",
                { key: index, staticClass: "table-tr transition" },
                [
                  _c(
                    "td",
                    { staticClass: "table-data-left" },
                    [
                      _c("input-component", {
                        model: {
                          value: questionValue.name,
                          callback: function($$v) {
                            _vm.$set(questionValue, "name", $$v)
                          },
                          expression: "questionValue.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c("td", { attrs: { hidden: "" } }, [
                    _vm._v(_vm._s((questionValue.sortOrder = index + 1)))
                  ]),
                  _c(
                    "td",
                    { staticClass: "border border-gray-25 w-24 text-center" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "px-8 py-4 cursor-pointer",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.remove(index)
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ]
                  )
                ]
              )
            }),
            _c("tr", [
              _c(
                "td",
                { staticClass: "table-data-left" },
                [
                  _c("input-component", {
                    attrs: {
                      placeholder: "Insert new answer...",
                      validationObj: _vm.$v.questionValue.name
                    },
                    model: {
                      value: _vm.$v.questionValue.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.questionValue.name, "$model", $$v)
                      },
                      expression: "$v.questionValue.name.$model"
                    }
                  })
                ],
                1
              ),
              _c("td", { staticClass: "table-data-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-default ml-2 btn-width-lg",
                    on: {
                      click: function($event) {
                        return _vm.add()
                      }
                    }
                  },
                  [_vm._v("Add")]
                )
              ])
            ])
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Answer")]),
      _c("th", { staticClass: "table-header-left" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }