


























































import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/auth/auth.service";
import OnClickOutside from "@/components/OnClickOutside.vue";
import NotificationList from "@/views/notificationList/NotificationList.vue";
const auth = new AuthService();
@Component({ components: { OnClickOutside, NotificationList } })
export default class AccountInfo extends Vue {
  isOpen: boolean = false;
  isToggleId: number | null = null;
  listOpen = false;
  get alertCount() {
    const alertCount = this.$store.state.uploads.alertCount;
    if (alertCount > 0) return alertCount;
    else return;
  }
  get busy() {
    return this.$store.state.uploads.busy;
  }
  logout() {
    auth.logout();
  }
  close(): void {
    if (!this.isOpen) {
      return;
    }
    this.isOpen = false;
  }

  get openNotificationClass() {
    if (this.isOpen) {
      return "text-white";
    } else return "";
  }
  //   toggle(toggleId: number): void {
  //     this.isToggleId = toggleId;
  //     this.isOpen = !this.isOpen;
  //   }

  toggleNotification() {
    this.$store.commit("RESET_ALERT");
    this.listOpen = true;
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.listOpen = false;
    }, 0);
  }
}
