

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AccountDashboardActivity from "@/views/accounts/accountDashboard/AccountDashboardActivity.vue";
import AccountDashboardApplications from "@/views/accounts/accountDashboard/AccountDashboardApplications.vue";
import AccountDashboardDetails from "@/views/accounts/accountDashboard/AccountDashboardDetails.vue";
import AccountDashboardSubscriptions from "@/views/accounts/accountDashboard/AccountDashboardSubscriptions.vue";
import AccountDashboardInformation from "@/views/accounts/accountDashboard/AccountDashboardInformation.vue";
import AccountDashboardOrganisation from "@/views/accounts/accountDashboard/AccountDashboardOrganisation.vue";

@Component({
  components: {
    AccountDashboardActivity,
    AccountDashboardApplications,
    AccountDashboardDetails,
    AccountDashboardSubscriptions,
    AccountDashboardInformation,
    AccountDashboardOrganisation
  }
})
export default class AccountDashboard extends Vue {
  information = false;
  details = false;
  subscriptions = false;
  applications = false;
  organisations = false;
  get showData() {
    if (
      this.information &&
      this.details &&
      this.subscriptions &&
      this.organisations &&
      this.applications
    )
      return true;
    else return false;
  }
}
