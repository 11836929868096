






























import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SaveMixin } from "@/services/mixins/saveMixin";
import moment from "moment";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import {
  AccountApplicationType,
  Application,
  FullAccountApplicationType
} from "../../../types/application";
import { ApplicationCommunication } from "../../../types/applicationCommunication";
import { AccountCommunication } from "../../../types/accountCommunication";
import { RawLocation } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import ApplicationCommunicationList from "../../application/applicationCommunication/ApplicationCommunicationList.vue";
import { Account } from "../../../types/account";

@Component({
  mixins: [SaveMixin],
  validations: {
    applicationId: { required }
  }
})
export default class AccountApplicationAdd extends Vue {
  accountName = "";
  showData = false;
  accountApplication = new AccountApplicationType();
  accountId: string = "";
  httpServiceAccountApplications = new HttpService<
    Array<AccountApplicationType>
  >();
  httpServiceAccountApplication = new HttpService<AccountApplicationType>();
  httpServiceAccountApplicationList = new HttpService<
    Array<FullAccountApplicationType>
  >();

  httpServiceApplications = new HttpService<Array<Application>>();
  applications = new Array<Application>();
  applicationId: any = "";
  backRoute = {
    name: "accountDashboard",
    id: ""
  };

  async getData() {
    const httpServiceAccount = new HttpService<Account>();
    const response = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response.data.fullName;
    const response1 = await this.httpServiceApplications.getListPaging(
      "applications"
    );
    let allApplications: Application[] = [];
    if (response1.status != 404) {
      allApplications = this.$sortList(response1.data, "applicationName");
    } else {
      allApplications = new Array<Application>();
    }
    const response2 = await this.httpServiceAccountApplicationList.getListPaging(
      `accounts/${this.accountId}/fullapplications`
    );
    if (response2.status === 404) {
      this.applications = allApplications;
    } else {
      let accountApplications = response2.data;
      this.applications = allApplications.filter(appOne => {
        return !accountApplications.find(appTwo => {
          return appOne.applicationId === appTwo.application.applicationId;
        });
      });
    }
    this.showData = true;
  }
  get title() {
    return `${this.accountName} - Add Application`;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.accountId = this.$route.params.id;
    this.backRoute.id = this.$route.params.id;
    this.getData();
  }

  async save() {
    const httpServiceAppCommunication = new HttpService<
      Array<ApplicationCommunication>
    >();
    const httpServiceAccountCommunication = new HttpService<
      AccountCommunication
    >();
    const httpServiceAccountCommunicationList = new HttpService<
      Array<AccountCommunication>
    >();

    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const response = await this.httpServiceAccountApplication.postItem(
        `accounts/${this.accountId}/applications/${this.applicationId}`,
        this.accountApplication
      );
      if (response.status === 204)
        this.$notification.success(`Application successfully added!`);

      const response2 = await httpServiceAppCommunication.getList(
        `applications/${this.applicationId}/communications`
      );

      const appCommunications = response2.data;

      const response3 = await httpServiceAccountCommunicationList.getList(
        `accounts/${this.accountId}/communications`
      );

      let communicationstoAdd: Array<ApplicationCommunication> = [];
      const accountCommunicationList = response3.data;

      if (response3.status !== 404 && response2.status !== 404) {
        communicationstoAdd = appCommunications.filter(communicationOne => {
          return !accountCommunicationList.find(communicationTwo => {
            return (
              communicationTwo.communicationId ===
              communicationOne.communicationId
            );
          });
        });
      }

      if (response2.status !== 404 && response3.status === 404) {
        communicationstoAdd = appCommunications;
      }
      communicationstoAdd.forEach(async communication => {
        const accountCommunication = new AccountCommunication();
        accountCommunication.optOut = communication.autoOptOut;
        accountCommunication.communicationId = communication.communicationId;
        accountCommunication.accountId = this.accountId;

        await httpServiceAccountCommunication.postItem(
          `accounts/${this.accountId}/communications`,
          accountCommunication
        );
      });

      this.$router.push({
        name: "accountApplication",
        params: { id: this.accountId, applicationId: this.applicationId }
      }),
        this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
