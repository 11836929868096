















import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { Validation } from "vuelidate";
import InputValidation from "@/components/inputs/InputValidation.vue";

@Component({
  components: {
    InputValidation
  }
})
export default class TextAreaComponent extends Vue {
  @Model("input") value!: string;

  @Prop() placeholder!: string;
  @Prop() validationObj!: Validation;
  @Prop() cols!: string;
  @Prop() rows!: string;

  input(event: KeyboardEvent) {
    this.$emit("input", (event.target as HTMLInputElement).value);
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners;
    return listeners;
  }
}
