import { render, staticRenderFns } from "./OrganisationDashboardIndividuals.vue?vue&type=template&id=f8facff6&"
import script from "./OrganisationDashboardIndividuals.vue?vue&type=script&lang=ts&"
export * from "./OrganisationDashboardIndividuals.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8facff6')) {
      api.createRecord('f8facff6', component.options)
    } else {
      api.reload('f8facff6', component.options)
    }
    module.hot.accept("./OrganisationDashboardIndividuals.vue?vue&type=template&id=f8facff6&", function () {
      api.rerender('f8facff6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/organisations/organisationDashboard/OrganisationDashboardIndividuals.vue"
export default component.exports