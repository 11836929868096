import QuestionTypeList from "@/views/configuration/questionType/QuestionTypeList.vue";
import QuestionTypePage from "@/views/configuration/questionType/QuestionTypePage.vue";
import QuestiontypeAdd from "@/views/configuration/questionType/QuestiontypeAdd.vue";
import QuestiontypeEdit from "@/views/configuration/questionType/QuestiontypeEdit.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const questionTypeRoutes = {
  path: "/configuration/questiontypes",
  component: QuestionTypePage,
  children: [
    {
      path: "",
      component: QuestionTypeList,
      name: "questionTypesList"
    },
    {
      path: "edit/:id",
      name: "questionTypesEdit",
      component: QuestiontypeEdit
    },
    {
      path: "add",
      name: "questionTypesAdd",
      component: QuestiontypeAdd
    }
  ],
  beforeEnter: roleGuard
};
