














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DiscardBar extends Vue {
  discard() {
    this.$store.dispatch("SET_DISCARD", false);
    this.$store.dispatch("SET_DIRTY", false);
    this.$router.push(this.$store.getters.getForwardRoute);
  }
  cancel() {
	this.$store.dispatch("SET_DISCARD", false);
	this.$store.commit("BACK", false);
  }

  beforeDestroy() {
    this.$store.dispatch("SET_ROUTE", {
      name: undefined,
      params: undefined
    });
  }
}
