import _Vue from "vue";
export default {
  install(Vue: typeof _Vue, options?: any) {
    Vue.prototype.$returnObject = function(
      id: number,
      list: [],
      idProperty: string
    ) {
      let object = list.find(item => {
        return item[idProperty] === id;
      });
      return object;
    };
  }
};

declare module "vue/types/vue" {
  interface Vue {
    $returnObject: (arg1: number, arg2: {}[], arg3: string) => any;
  }
}
