




















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import InputComponent from "@/components/inputs/InputComponent.vue";
import ConfigureGroup from "@/views/forms/groups/ConfigureGroup.vue";
import AddQuestion from "@/views/forms/groups/questions/AddQuestion.vue";
import GroupQuestion from "@/views/forms/groups/questions/GroupQuestion.vue";
import ConfigureQuestion from "@/views/forms/groups/questions/ConfigureQuestion.vue";
import Draggable from "vuedraggable";
import SideModal from "@/components/SideModal.vue";
import { GroupType } from "@/types/group";
import { GroupQuestionType } from "../../../types/groupQuestion";
@Component({
  components: {
    AddQuestion,
    InputComponent,
    ConfigureGroup,
    Draggable,
    GroupQuestion,
    ConfigureQuestion,
    SideModal
  }
})
export default class QuestionGroup extends Vue {
  @Prop() questionGroup!: GroupType;
  @Prop() groupIndex!: number;
  @Prop() formId!: number;
  questionConfigIndex!: number;
  configureQuestion: boolean = false;
  showconfigureGroup: boolean = false;
  showModal: boolean = false;
  showAddQuestion: boolean = false;
  enabled: boolean = true;
  @Watch("groupIndex", { deep: true }) checkIndex() {
    this.$store.dispatch("SET_DIRTY", true);
  }
  @Watch("questionGroup") checkGroup() {
    this.$store.dispatch("SET_DIRTY", true);
  }
  removeGroup() {
    this.$store.commit("REMOVE_GROUP", this.groupIndex);
    this.$store.dispatch("SET_DIRTY", true);
    if (this.questionGroup.formGroupId) {
      this.$store.commit("DELETE_GROUP_ARRAY", {
        groupId: this.questionGroup.formGroupId,
        formId: this.formId
      });
    }
  }

  groupQuestion(question: GroupQuestionType, index: number) {
    question.sortOrder = index + 1;
    return question;
  }

  get draggableOptions() {
    return {
      animation: 0.3,
      group: "questions",
      disabled: false,
      ghostClass: "ghost"
    };
  }
}
