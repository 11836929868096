var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex items-center justify-between mb-2" },
        [
          _c("h2", { staticClass: "m-0" }, [_vm._v("Configure Group")]),
          _c("font-awesome-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$store.getters.getForm.groups.length > 1,
                expression: "$store.getters.getForm.groups.length>1"
              }
            ],
            staticClass: "block sm:hidden cursor-pointer",
            attrs: { icon: "trash", size: "lg" },
            on: {
              click: function($event) {
                _vm.showModal = true
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [_vm._v("Group Name:")]),
        _c(
          "div",
          [
            _c("input-component", {
              attrs: { validationObj: _vm.$v.updatedGroup.name },
              model: {
                value: _vm.$v.updatedGroup.name.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.updatedGroup.name, "$model", $$v)
                },
                expression: "$v.updatedGroup.name.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [_vm._v("Description:")]),
        _c(
          "div",
          [
            _c("text-area", {
              attrs: { validationObj: _vm.$v.updatedGroup.description },
              model: {
                value: _vm.$v.updatedGroup.description.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.updatedGroup.description, "$model", $$v)
                },
                expression: "$v.updatedGroup.description.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mb-4" }, [
        _c("label", { staticClass: "leading-loose" }, [
          _vm._v("Allow Multiple Groups?")
        ]),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.updatedGroup.multipleGroups.$model,
                expression: "$v.updatedGroup.multipleGroups.$model"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.$v.updatedGroup.multipleGroups.$model)
                ? _vm._i(_vm.$v.updatedGroup.multipleGroups.$model, null) > -1
                : _vm.$v.updatedGroup.multipleGroups.$model
            },
            on: {
              change: function($event) {
                var $$a = _vm.$v.updatedGroup.multipleGroups.$model,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.$v.updatedGroup.multipleGroups,
                        "$model",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.$v.updatedGroup.multipleGroups,
                        "$model",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.$v.updatedGroup.multipleGroups, "$model", $$c)
                }
              }
            }
          })
        ])
      ]),
      _vm.updatedGroup.multipleGroups
        ? _c("div", [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Multiple Question Groups Limit")
              ]),
              _c(
                "div",
                [
                  _c("input-component", {
                    attrs: { validationObj: _vm.$v.updatedGroup.groupLimit },
                    model: {
                      value: _vm.$v.updatedGroup.groupLimit.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.updatedGroup.groupLimit, "$model", $$v)
                      },
                      expression: "$v.updatedGroup.groupLimit.$model"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v("\n      Are you sure you want to delete\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.questionGroup.name))]),
            _vm._v(" and any questions it contains from the form?\n    ")
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.removeGroup()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }