var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [_vm._v("Add entitlement")]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Entitlement Name:")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("input-component", {
              attrs: { validationObj: _vm.$v.form.entitlementName },
              model: {
                value: _vm.$v.form.entitlementName.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.form.entitlementName, "$model", $$v)
                },
                expression: "$v.form.entitlementName.$model"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "input-container" }, [
      _c("div", { staticClass: "input-label" }, [_vm._v("Short Code:")]),
      _c(
        "div",
        { staticClass: "main-input" },
        [
          _c("input-component", {
            attrs: { validationObj: _vm.$v.form.shortCode },
            model: {
              value: _vm.$v.form.shortCode.$model,
              callback: function($$v) {
                _vm.$set(_vm.$v.form.shortCode, "$model", $$v)
              },
              expression: "$v.form.shortCode.$model"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }