import axios from "axios";

import AuthService from "../auth/auth.service";

export default () => {
  const auth = new AuthService();
  function getToken() {
    return auth.getAccessToken().then((userToken: string) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
    });
  }
  axios.defaults.baseURL = process.env.VUE_APP_API;

  getToken();
};
