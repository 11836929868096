export class Organisation {
  organisationId?: string;
  contact: string;
  organisationName: string;
  telephone: string;

  constructor() {
    this.contact = "";
    this.organisationName = "";
    this.telephone = "";
  }
}
