























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationAccount } from "@/types/organisationAccount";
import { AxiosError } from "axios";

@Component
export default class OrganisationDashboardIndividuals extends Vue {
  accountsList: Array<OrganisationAccount> = new Array<OrganisationAccount>();
  httpService = new HttpService<Array<OrganisationAccount>>();
  organisationId = "";
  accountId = "";
  accountName = "";
  showModal = false;
  total = 0;
  searchCriteria = "";
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber = 1;
  async getList() {
    const response = await this.httpService.getListPaging(
      `organisations/${this.organisationId}/accounts`,
      1000,
      1
    );

    this.accountsList = response.data || new Array<OrganisationAccount>();
    this.$emit("finishedLoading", true);
  }

  get searchResults() {
    let filteredQuestions = this.accountsList.filter(
      (user: OrganisationAccount) => {
        return user.account.fullName
          .toLowerCase()
          .includes(this.searchCriteria.toLowerCase());
      }
    );
    this.total = filteredQuestions.length;
    let tempArray = [];
    let MyQuestions = [];
    for (let index = 0; index < this.total; index += 10) {
      MyQuestions = filteredQuestions.slice(index, index + 10);

      tempArray.push(MyQuestions);
    }

    if (tempArray[this.pageNumber - 1]) return tempArray[this.pageNumber - 1];
    else return tempArray[0], (this.pageNumber = 1);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;

    this.getList();
  }
  edit(accountId: string) {
    this.$router.push({
      name: "organisationIndividualEdit",
      params: {
        id: this.organisationId,
        accountId: accountId
      }
    });
  }

  showModalFunction(accountId: string, firstName: string, lastName: string) {
    this.showModal = true;
    this.accountId = accountId;
    this.accountName = firstName + " " + lastName;
  }
  async remove() {
    await this.httpService
      .deleteItem(
        `organisations/${this.organisationId}/accounts`,
        this.accountId,
        { errorInterceptor: false }
      )
      .then(response => {
        this.$notification.success(
          `Account successfully removed from organisation.`
        );
      });
    this.showModal = false;
    this.getList();
  }
}
