import { render, staticRenderFns } from "./ClientPropertySave.vue?vue&type=template&id=52551c44&scoped=true&"
import script from "./ClientPropertySave.vue?vue&type=script&lang=ts&"
export * from "./ClientPropertySave.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52551c44",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52551c44')) {
      api.createRecord('52551c44', component.options)
    } else {
      api.reload('52551c44', component.options)
    }
    module.hot.accept("./ClientPropertySave.vue?vue&type=template&id=52551c44&scoped=true&", function () {
      api.rerender('52551c44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/client/clientConfiguration/clientProperty/ClientPropertySave.vue"
export default component.exports