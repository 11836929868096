export class ApiResource {
  id?: any;
  description: string = "";
  displayName: string = "";
  enabled: boolean = false;
  name: string = "";
  created: Date = new Date();
  lastAccessed?: Date;
  nonEditable: boolean = false;
  updated?: Date;
}

export class ApiScope {
  id?: any;
  apiResourceId: number | null = null;
  description?: string;
  displayName?: string;
  emphasize = false;
  name = "";
  required = false;
  showInDiscoveryDocument = false;
}
