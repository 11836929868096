



















































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { AccountSubscription } from "@/types/accountSubscription";
import DatePicker from "@/components/DatePicker.vue";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { SaveMixin } from "@/services/mixins/saveMixin";
import moment from "moment";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { Account } from "../../../types/account";

const endDateGraterThanStartDate = (endDate: Date, vm: any) => {
  if (!moment(endDate).isValid()) {
    return true;
  }
  return moment(endDate).startOf("day") >= moment(vm.startDate).startOf("day");
};

const dateGraterThanToday = (date: Date, vm: any) => {
  if (!moment(date).isValid()) {
    return true;
  }

  return moment(date).startOf("day") >= moment().startOf("day");
};

@Component({
  mixins: [SaveMixin],
  components: { DatePicker },

  validations: {
    accountSubscription: {
      subscriptionPackageId: { required },
      trial: { required },
      perpetual: { required },
      suspended: { required },
      startDate: { required, dateGraterThanToday },
      endDate: { endDateGraterThanStartDate, dateGraterThanToday },
      notes: { maxLength: maxLength(1000) }
    }
  }
})
export default class AccountSubscriptionAdd extends Vue {
  accountName = "";
  showData = false;
  accountId = "";
  accountSubscription = new AccountSubscription();
  httpServiceSubscriptions = new HttpService<Array<AccountSubscription>>();
  today = new Date();
  httpServiceSubscription = new HttpService<AccountSubscription>();
  httpServiceSubscriptionPackages = new HttpService<
    Array<SubscriptionPackageItem>
  >();
  activeSubscriptions: Array<AccountSubscription> = [];
  allSubscriptions = new Array<SubscriptionPackageItem>();
  filteredSubscriptions = new Array<SubscriptionPackageItem>();
  backRoute = {
    name: "accountDashboard",
    id: ""
  };
  get title() {
    return `${this.accountName} - Add Subscription`;
  }
  async getData() {
    const httpServiceAccount = new HttpService<Account>();
    const response = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response.data.fullName;
    const response2 = await this.httpServiceSubscriptions.getListPaging(
      `accounts/${this.accountId}/subscriptions`
    );

    if (response2.status != 404) {
      this.activeSubscriptions = response2.data;
    } else {
      this.activeSubscriptions = [];
    }

    const response3 = await this.httpServiceSubscriptionPackages.getListPaging(
      "packages",
      1000,
      1,
      "subscriptionPackageName"
    );

    if (response3.status != 404) {
      this.allSubscriptions = this.$sortList(
        response3.data,
        "subscriptionPackageName"
      );
    } else {
      this.allSubscriptions = new Array<SubscriptionPackageItem>();
    }

    this.getFilteredSubscriptions();
  }

  @Watch("$route", { immediate: true }) getId() {
    this.accountId = this.$route.params.id;
    this.getData();
  }

  created() {
    this.backRoute.id = this.$route.params.id;
  }

  getFilteredSubscriptions() {
    let activeSubscriptions = this.activeSubscriptions;
    this.filteredSubscriptions = this.allSubscriptions.filter(subscription => {
      return !activeSubscriptions.find(activeSubscription => {
        return (
          subscription.subscriptionPackageId ===
          activeSubscription.subscriptionPackageId
        );
      });
    });
    this.showData = true;
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceSubscription
        .postItem(
          `accounts/${this.accountId}/subscriptions`,
          this.accountSubscription
        )
        .then(res =>
          this.$notification.success(`Subscription successfully added!`)
        )
        .then(() => {
          this.$router.push({
            name: "accountDashboard",
            params: { id: this.accountId }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
