






















































































































import moment from "moment";
import { Component, Watch, Vue } from "vue-property-decorator";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { HttpService } from "@/services/api/httpService";
import { SubscriptionPackageItem } from "../../types/subscriptionPackage";
import { Application } from "../../types/application";
import { AccountListImportType } from "@/types/accountListImport";
import { ThirdParty } from "@/types/thirdParty";
import XLSX from "xlsx";
import NotificationItem from "@/types/notificationItem";
import { isSpreadSheet } from "@/services/customValidator/customValidators";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { AxiosResponse } from "axios";

const make_cols = (refstr: string) =>
  Array(XLSX.utils.decode_range(refstr).e.c + 1)
    .fill(0)
    .map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }));
@Component({
  mixins: [SaveMixin],
  validations: {
    list: {
      subscriptionPackageId: {
        required: requiredUnless(vm => {
          return vm.applicationId;
        })
      },
      applicationId: {
        required: requiredUnless(vm => {
          return vm.subscriptionPackageId;
        })
      },
      overwriteUserDetails: {},
      spreadSheetData: { required, isSpreadSheet },
      id: { required }
    }
  }
})
export default class AccountListUpload extends Vue {
  backRoute = {
    name: "accountsList"
  };
  preview = false;
  thirdParties = new Array<ThirdParty>();
  showData = false;
  list = new AccountListImportType();
  subscriptions = new Array<SubscriptionPackageItem>();
  applications = new Array<Application>();
  data: {}[] = [];
  form = new FormData();
  columns: { name: string; key: number }[] = [];
  fileName = "";
  async getData() {
    const httpServiceSubs = new HttpService<Array<SubscriptionPackageItem>>();
    const httpServiceApps = new HttpService<Array<Application>>();
    const httpServiceThirdParty = new HttpService<Array<ThirdParty>>();
    const subscriptionResponse = await httpServiceSubs.getListPaging(
      `packages`
    );
    const applicationsResponse = await httpServiceApps.getListPaging(
      `applications`
    );
    const thirdPartyResponse = await httpServiceThirdParty.getListPaging(
      "thirdparty"
    );
    this.thirdParties = thirdPartyResponse.data;
    this.subscriptions = subscriptionResponse.data;
    this.applications = applicationsResponse.data;
    this.showData = true;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.getData();
  }
  handleFileUpload(e: any) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      this.list.spreadSheetData = e.target.files[0];
      if (
        this.$v.list &&
        this.$v.list.spreadSheetData &&
        this.$v.list.spreadSheetData.$invalid
      )
        return this.$v.list.spreadSheetData.$touch(), (this.preview = false);

      this.form.append("file", e.target.files[0]);
      this._file(e.target.files[0]);
    } else {
      this.list.spreadSheetData = null;

      this.columns = [];
      this.preview = false;
    }
  }
  _file(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const dataString = e.target.result;
      const workBook = XLSX.read(dataString, {
        type: "binary",
        raw: true,
        cellDates: true
      });

      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const data: {}[] = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
        raw: true
      });
      this.data = data;
      if (workSheet["!ref"]) this.columns = make_cols(workSheet["!ref"]);
    };
    reader.readAsBinaryString(file);
    this.preview = true;
  }

  currentTime() {
    return moment().unix();
  }

  async save() {
    let thirdParty = this.thirdParties.find(thirdParty => {
      return thirdParty.id === this.list.id;
    });

    let application = this.applications.find(application => {
      return application.applicationId === this.list.applicationId;
    });

    let subscription = this.subscriptions.find(subscription => {
      return (
        subscription.subscriptionPackageId === this.list.subscriptionPackageId
      );
    });

    let notificationMessageDetails = "";
    if (thirdParty)
      notificationMessageDetails = `${this.fileName} from ${thirdParty.name} and adding `;

    if (subscription && !application)
      notificationMessageDetails += subscription.subscriptionPackageName;
    else if (subscription && application)
      notificationMessageDetails += `${subscription.subscriptionPackageName} and ${application.applicationName}`;
    else if (application && !subscription)
      notificationMessageDetails += application.applicationName;
    else notificationMessageDetails = "error";

    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.form.append(
        "subscriptionPackageId",
        String(this.list.subscriptionPackageId)
      );

      this.form.append("applicationId", String(this.list.applicationId));
      this.form.append(
        "overwriteUserDetails",
        String(this.list.overwriteUserDetails)
      );
      this.form.append("thirdPartyId", String(this.list.id));
      const httpService = new HttpService<FormData>();
      this.$v.$reset();
      this.$store.commit("UPLOAD_STARTED");
      this.$router.push({
        name: "accountsList"
      });
      this.$notification.success("started importing data");
      this.$store.commit("ADD_NOTIFICATION", {
        notification: new NotificationItem(
          `Importing ${notificationMessageDetails}`,
          "uploading",
          this.currentTime()
        )
      });

      this.$store.commit("SET_DIRTY", false);
      let response: AxiosResponse;

      try {
        response = await httpService.postItem(
          "accountSubscriptionImport",
          this.form,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      } catch (error) {
        response = error;
      }

      if (response.status < 300) {
        this.$store.commit("UPDATE_NOTIFICATION", {
          message: `Finished importing ${notificationMessageDetails}`,
          endTime: this.currentTime(),
          status: "Success"
        });
        this.$notification.success(`${this.fileName} successfully uploaded`);
      } else {
        this.$store.commit("UPDATE_NOTIFICATION", {
          message: "Something went wrong",
          endTime: "",
          status: "Failed"
        });
      }
      this.$store.commit("UPLOAD_FINISHED");
    }
  }
}
