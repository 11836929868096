var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h1",
      [
        _vm._v("\n    404- Page not found. Click\n    "),
        _c("router-link", { staticClass: "underline", attrs: { to: "/" } }, [
          _vm._v("here")
        ]),
        _vm._v("  to return home\n  ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }