import { RootState } from "../../types";
import { Role } from "./types";
import { mutations } from "./mutations";
import { Module } from "vuex";
import { getters } from "./getters";
export const state: Role = {
  role: ""
};

export const role: Module<Role, RootState> = {
  state,
  mutations,
  getters
};
