var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "router-view rounded-r rounded-b p-8 mb-2 shadow parent relative"
    },
    [
      _c("form", { staticClass: "w-full" }, [
        _c("div", { staticClass: "mb-4 flex-none block" }, [
          _c("div", { staticClass: "w-full" }, [_vm._v("New password:")]),
          _c(
            "div",
            { staticClass: "w-full sm:px-0 relative" },
            [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass:
                      "w-full rounded p-2 border border-gray-50 focus:outline-none transition pr-8",
                    class: _vm.$validationStatus.status(_vm.$v.password),
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.$v.password.$model)
                        ? _vm._i(_vm.$v.password.$model, null) > -1
                        : _vm.$v.password.$model
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.$v.password.$model,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.$v.password,
                                "$model",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.$v.password,
                                "$model",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.$v.password, "$model", $$c)
                        }
                      }
                    }
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass:
                      "w-full rounded p-2 border border-gray-50 focus:outline-none transition pr-8",
                    class: _vm.$validationStatus.status(_vm.$v.password),
                    attrs: { type: "radio" },
                    domProps: { checked: _vm._q(_vm.$v.password.$model, null) },
                    on: {
                      change: function($event) {
                        return _vm.$set(_vm.$v.password, "$model", null)
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass:
                      "w-full rounded p-2 border border-gray-50 focus:outline-none transition pr-8",
                    class: _vm.$validationStatus.status(_vm.$v.password),
                    attrs: { type: _vm.showPassword ? "text" : "password" },
                    domProps: { value: _vm.$v.password.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.password, "$model", $event.target.value)
                      }
                    }
                  }),
              _vm.password
                ? _c("font-awesome-icon", {
                    staticClass:
                      "password-preview text-md mr-2 text-green-100 absolute cursor-pointer",
                    attrs: { icon: _vm.showPassword ? "eye-slash" : "eye" },
                    on: { click: _vm.togglePassword }
                  })
                : _vm._e(),
              _c("input-validation", {
                attrs: { validationObj: _vm.$v.password }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "mb-4 flex-none block" }, [
          _c("div", { staticClass: "w-full" }, [
            _vm._v("Repeat new password:")
          ]),
          _c(
            "div",
            { staticClass: "w-full sm:px-0" },
            [
              _c("input-component", {
                attrs: {
                  type: "password",
                  validationObj: _vm.$v.repeatPassword
                },
                model: {
                  value: _vm.$v.repeatPassword.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.repeatPassword, "$model", $$v)
                  },
                  expression: "$v.repeatPassword.$model"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "flex flex-col" }, [
        _c(
          "button",
          {
            staticClass: "btn-default btn-width-lg mb-2",
            on: { click: _vm.save }
          },
          [_vm._v("Save")]
        ),
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.generetePassword()
              }
            }
          },
          [
            _c("font-awesome-icon", {
              staticClass: "text-md mr-2 text-green-100 cursor-pointer",
              attrs: { icon: "undo" }
            }),
            _vm._v("Generate password\n    ")
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }