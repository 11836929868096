import Vue from "vue";
import Router from "vue-router";
import NotFoundComponent from "@/components/NotFoundComponent.vue";
import { accountRoutes } from "@/router/accounts";
import { accountTypeRoutes } from "@/router/accountType";
import { applicationRoutes } from "@/router/application";
import { communicationRoutes } from "@/router/communication";
import { communicationTypeRoutes } from "@/router/communicationType";
import { countryRoutes } from "@/router/country";
import { dashboardRoutes } from "@/router/dashboard";
import { entitlementRoutes } from "@/router/entitlement";
import { formRoutes } from "@/router/form";
import { questionRoutes } from "@/router/question";
import { questionTypeRoutes } from "@/router/questionType";
import { organisationRoutes } from "@/router/organisations";
import { statisticsRoutes } from "@/router/statistics";
import { subscriptionRoutes } from "@/router/subscription";
import { thirdPartyRoutes } from "@/router/thirdParty";
import { clientRoutes } from "@/router/client";
import AuthService from "@/services/auth/auth.service";
import store from "@/store/store";

const router = new Router({
  mode: "history",
  routes: [
    dashboardRoutes,
    communicationRoutes,
    communicationTypeRoutes,
    applicationRoutes,
    questionRoutes,
    subscriptionRoutes,
    formRoutes,
    countryRoutes,
    statisticsRoutes,
    questionTypeRoutes,
    accountTypeRoutes,
    entitlementRoutes,
    accountRoutes,
    organisationRoutes,
    thirdPartyRoutes,
    clientRoutes,
    { path: "*", component: NotFoundComponent }
  ]
});

Vue.use(Router);

router.beforeEach((to: any, from: any, next: any) => {
  const auth = new AuthService();
  auth.getUser().then(user => {
    let isLoggedIn = user !== null && !user.expired;
    if (isLoggedIn) {
      next();
      return;
    } else {
      next(auth.login());
    }
  });
});

router.beforeEach((to, from, next: any) => {
  store.dispatch("SET_ROUTE", {
    name: to.name,
    params: to.params
  });
  if (store.getters.getDirtyFlag === true) {
    store.dispatch("SET_DISCARD", true);
    next(false);
    return;
  } else {
    next();
  }
});

export default router;
