var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "border-t border-b border-gray-50 pt-4 pb-4" },
    [
      _c("div", {}, [
        _c("div", { staticClass: "flex flex-row" }, [
          _vm.item.notification.status === "uploading"
            ? _c("div", [_vm._m(0)])
            : _vm.item.notification.status === "Success"
            ? _c(
                "div",
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-lg text-green-100",
                    attrs: { icon: "check" }
                  })
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-lg text-red-300",
                    attrs: { icon: "times" }
                  })
                ],
                1
              ),
          _c("span", { staticClass: "block font-bold ml-2" }, [
            _vm._v("Status: " + _vm._s(_vm.item.notification.status))
          ]),
          _c(
            "div",
            {
              staticClass: "cursor-pointer ml-auto",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("X")]
          )
        ])
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _c("p", [_vm._v(_vm._s(_vm.item.notification.message))]),
        _c("div", { staticClass: "text-gray-100 text-right text-xs mt-4" }, [
          _vm._v(_vm._s(_vm.completionTime))
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hollow-dots-spinner" }, [
      _c("div", { staticClass: "dot" }),
      _c("div", { staticClass: "dot" }),
      _c("div", { staticClass: "dot" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }