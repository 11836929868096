



























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SelfRegistration } from "@/types/selfRegistration";
import { HttpService } from "@/services/api/httpService";
import { AxiosError } from "axios";

@Component
export default class OrganisationDashboardSelfRegistration extends Vue {
  organisationId: string = "";
  httpService = new HttpService<Array<SelfRegistration>>();
  selfRegistrations = new Array<SelfRegistration>();
  selfRegistrationId: string = "";
  domainName: string = "";
  showModal: boolean = false;

  async getData() {
    const response = await this.httpService.getItem(
      `organisations/${this.organisationId}/selfregistration`,
      {
        errorInterceptor: false
      }
    );

    this.selfRegistrations = response.data || new Array<SelfRegistration>();
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;

    this.getData();
  }

  edit(selfRegistrationId: string) {
    this.$router.push({
      name: "organisationSelfRegistrationEdit",
      params: {
        id: this.organisationId,
        selfRegistrationId
      }
    });
  }

  showModalFunction(selfRegistrationId: string, domainName: string) {
    this.showModal = true;
    this.selfRegistrationId = selfRegistrationId;
    this.domainName = domainName;
  }

  checkDate(date: Date) {
    if (date < new Date()) {
      return "bg-red-200";
    }
  }
  async remove() {
    await this.httpService
      .deleteItem(
        `organisations/${this.organisationId}/selfregistration`,
        this.selfRegistrationId,
        { errorInterceptor: false }
      )
      .then(response => {
        this.$notification.success(
          `Self Registration Domain successfully removed!`
        );
      });
    this.showModal = false;
    this.getData();
  }
}
