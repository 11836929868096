












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GroupQuestionType } from "../../../../../types/groupQuestion";
import { Answer } from "../../../../../types/answer";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
@Component({
  validations: {
    groupQuestion: {
      question: { answers: { $each: { questionAnswerText: {} } } }
    }
  }
})
export default class TextAreaQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;
  @Watch("$v.$anyDirty", { immediate: true }) onDirtyFlag() {
    this.$store.dispatch("SET_DIRTY", this.$v.$anyDirty);
  }

  created() {
    if (!this.groupQuestion.question.answers) {
      this.groupQuestion.question.answers = new Array<Answer>();
    } else if (!this.groupQuestion.question.answers.length) {
      this.groupQuestion.question.answers.push(new Answer());
    }
  }
}
