var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.clientType === ""
        ? _c("div", [
            _c("h1", [_vm._v("Add New Client")]),
            _c(
              "ul",
              { staticClass: "flex flex-row" },
              _vm._l(_vm.clientTypes, function(type) {
                return _c(
                  "li",
                  {
                    key: type.key,
                    staticClass:
                      "cursor-pointer rounded overflow-hidden w-64 h-64 shadow-lg text-center pt-20 mx-1 hover:underline hover:opacity-50 hover:shadow-medium",
                    on: {
                      click: function($event) {
                        _vm.clientType = type.name
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-green-100 text-6xl",
                      attrs: { icon: type.fontAwesomeTag }
                    }),
                    _c("h2", { staticClass: "font-bold pt-10" }, [
                      _vm._v(_vm._s(type.name))
                    ])
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.clientType == "Single Page Application"
        ? _c("client-add-spa", {
            on: {
              close: function($event) {
                _vm.clientType = ""
              }
            }
          })
        : _vm._e(),
      _vm.clientType == "Web Application"
        ? _c("client-add-website", {
            on: {
              close: function($event) {
                _vm.clientType = ""
              }
            }
          })
        : _vm._e(),
      _vm.clientType == "Machine"
        ? _c("client-add-machine", {
            on: {
              close: function($event) {
                _vm.clientType = ""
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }