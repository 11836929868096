















import { ThirdParty } from "@/types/thirdParty";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    thirdParty: {
      name: { required, maxLength: maxLength(30) }
    }
  }
})
export default class ThirdPartyAdd extends Vue {
  thirdParty: ThirdParty = new ThirdParty();
  httpService: HttpService<ThirdParty> = new HttpService<ThirdParty>();
  backRoute = { name: "thirdPartyList" };

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let response = await this.httpService.postItem(
        "thirdparty",
        this.thirdParty
      );

      if (response.status < 300) {
        this.$notification.success("Third Party successfully saved");
      }
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
