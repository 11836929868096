var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "modal-wrapper flex flex-col overflow-auto h-full p-8 bg-white ml-auto fixed z-50 top-0 right-0 bottom-0 h-full w-full md:w-1/2"
    },
    [
      _c("h1", [_vm._v("Add/Edit Answers")]),
      _c(
        "div",
        { staticClass: "table-container max-h-screen-80 overflow-y-auto" },
        [
          _c(
            "ul",
            { staticClass: "table w-full" },
            [
              _c("li", { staticClass: "table-header-left pl-24" }, [
                _vm._v("Answers- drag and drop to change order")
              ]),
              _c(
                "draggable",
                {
                  attrs: { list: _vm.questionValues, "ghost-class": "ghost" },
                  on: {
                    start: function($event) {
                      _vm.Dragging = true
                    },
                    end: function($event) {
                      _vm.Dragging = false
                    }
                  }
                },
                _vm._l(_vm.$v.questionValues.$each.$iter, function(
                  questionValue,
                  index
                ) {
                  return _c(
                    "li",
                    { key: index, staticClass: "question py-4 flex" },
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "cursor-pointer text-gray-100 text-xl mt-2",
                        attrs: { icon: "arrows-alt" }
                      }),
                      _c("input-component", {
                        staticClass: "pl-12 flex-grow",
                        attrs: { validationObj: questionValue.name },
                        model: {
                          value: questionValue.name.$model,
                          callback: function($$v) {
                            _vm.$set(questionValue.name, "$model", $$v)
                          },
                          expression: "questionValue.name.$model"
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "li",
                [
                  _c("input-component", {
                    attrs: { placeholder: "Insert new answer..." },
                    model: {
                      value: _vm.$v.questionValue.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.questionValue.name, "$model", $$v)
                      },
                      expression: "$v.questionValue.name.$model"
                    }
                  })
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "btn-default ml-2 btn-width-lg",
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [_vm._v("Add")]
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "ml-auto mt-auto pt-4" }, [
        _c(
          "button",
          {
            staticClass: "btn-secondary btn-width-lg",
            on: {
              click: function($event) {
                return _vm.cancel()
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "btn-default ml-2 btn-width-lg",
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v("OK")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }