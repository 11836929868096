import { MutationTree } from "vuex";
import Vue from "vue";
import { GetForm } from "./types";
import { GroupQuestionType } from "@/types/groupQuestion";
import { Question } from "@/types/question";
import { GroupType } from "@/types/group";
import { FullForm } from "@/types/fullForm";

export const mutations: MutationTree<GetForm> = {
  SET_FORM(state, payLoad) {
    state.form = payLoad;
    if (!state.form.groups.length) {
      state.form.groups.push(new GroupType());
    }
  },
  UPDATE_FORM_NAME(state, payLoad) {
    state.form.name = payLoad;
  },

  ADD_GROUP(state, payLoad) {
    payLoad.uniqueId = Math.floor(Math.random() * 1000000 + 1);
    state.form.groups.push(payLoad);
  },

  REMOVE_GROUP(state, payLoad) {
    state.form.groups.splice(payLoad, 1);
  },

  REMOVE_QUESTION(
    state,
    payLoad: { questionOrder: number; groupIndex: number }
  ) {
    state.form.groups[payLoad.groupIndex].groupQuestions.splice(
      payLoad.questionOrder,
      1
    );
  },

  REMOVE_QUESTION_ID(
    state,
    payLoad: { question: Question; groupIndex: number }
  ) {
    state.form.groups[payLoad.groupIndex].groupQuestions = state.form.groups[
      payLoad.groupIndex
    ].groupQuestions.filter(function(groupQuestion) {
      return groupQuestion.question.questionId !== payLoad.question.questionId;
    });
  },

  ADD_QUESTION(
    state,
    payLoad: { newQuestion: GroupQuestionType; index: number }
  ) {
    if (payLoad.newQuestion.question.questionValues) {
    }
    state.form.groups[payLoad.index].groupQuestions.push(payLoad.newQuestion);
  },

  UPDATE_QUESTION(state, payLoad) {
    state.form.groups[payLoad.index].groupQuestions[
      payLoad.questionIndex
    ].question = payLoad.question;
    state.form.groups[payLoad.index].groupQuestions[
      payLoad.questionIndex
    ].question.questionValues = payLoad.questionValues;
  },

  UPDATE_GROUP_QUESTION(
    state,
    payLoad: {
      groupQuestion: GroupQuestionType;
      groupIndex: number;
      questionIndex: number;
    }
  ) {
    Vue.set(
      state.form.groups[payLoad.groupIndex].groupQuestions,
      payLoad.questionIndex,
      payLoad.groupQuestion
    );
  },

  RESET_FORM(state) {
    state.form = new FullForm();
  },

  UPDATE_GROUP(
    state,
    payLoad: {
      group: GroupType;
      groupIndex: number;
    }
  ) {
    Vue.set(state.form.groups, payLoad.groupIndex, payLoad.group);
  },

  async REMOVE_GROUPS(state, payLoad: Array<GroupType>) {
    for (let group of payLoad) {
      await state.form.groups.splice(group.sortOrder - 1, 1);
    }
  },

  DELETE_QUESTION_ARRAY(
    state,
    payLoad: { groupQuestionId: number; groupId: number }
  ) {
    state.formDelete.questions.push(payLoad);
  },
  DELETE_GROUP_ARRAY(state, payLoad: { groupId: number; formId: number }) {
    state.formDelete.groups.push(payLoad);
  },
  RESET_ARRAYS(state) {
    state.formDelete.groups.length = 0;
    state.formDelete.questions.length = 0;
  },

  SET_QUESTIONS(state, payLoad) {
    state.questionsList = payLoad;
  },

  SIDEMODALSAVE(state, payLoad) {
    state.sideModalSave = payLoad;
  }
};
