
























import { Component, Prop, Vue } from "vue-property-decorator";
import { FullForm } from "../../../../types/fullForm";
import FormGroup from "./FormGroup.vue";
@Component({ components: { FormGroup } })
export default class AccountApplicationForm extends Vue {
  @Prop() formData!: FullForm;
  @Prop() name!: string;
  @Prop() value!: boolean;
}
