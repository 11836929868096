



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ required: true }) showModal!: boolean;
  @Prop({ default: true }) preventBackgroundScrolling!: boolean;
  @Watch("showModal", { immediate: true }) preventScrollingHandler() {
    if (this.showModal == true) {
      document.body.style.setProperty("overflow", "hidden");
    } else {
      document.body.style.removeProperty("overflow");
    }
  }

  created() {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape" && this.showModal == true) {
        this.closeModal();
      }
    };
    document.addEventListener("keyup", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keyup", escapeHandler);
      document.body.style.removeProperty("overflow");
    });
  }

  closeModal() {
    this.$emit("close");
  }
}
