



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Form } from "@/types/form";

@Component
export default class FormList extends Vue {
  showData = false;
  total = 0;
  searchCriteria = "";
  httpServiceArray = new HttpService<Array<Form>>();
  httpService = new HttpService<Form>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber = 1;
  responseArray = new Array<Form>();
  showModal: boolean = false;
  removeData = new Form();
  get title() {
    return "Forms";
  }
  async getList() {
    const response = await this.httpServiceArray.getListSearch(
      "forms",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.responseArray = response.data;
      this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
    } else this.responseArray = new Array<Form>();

    this.showData = true;
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "formsAdd"
    });
  }
  confirmRemove(data: Form) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: Form) {
    await this.httpService.deleteItem("forms", data.formId).then(response => {
      this.responseArray = this.responseArray.filter(
        (item: Form) => item.formId != data.formId,
        this.$notification.success(`${data.name} successfully removed!`)
      );
    });
    this.showModal = false;
    this.getList();
  }
  edit(data: Form) {
    this.$router.push({
      name: `formsEdit`,
      params: { id: data.formId }
    });
  }
}
