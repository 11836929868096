




import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/auth/auth.service";
import GetJson from "@/components/GetJson.vue";
import SearchSelect from "@/components/SearchSelect.vue";

const auth = new AuthService();
@Component({
  components: {
    GetJson,
    SearchSelect
  }
})
export default class AccountInfo extends Vue {}
