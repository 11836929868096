




































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Client } from "@/types/client";

@Component
export default class ClientList extends Vue {
  searching = false;
  message = "";
  showData = false;
  total = 0;
  searchCriteria = "";
  clients: Array<Client> = [];
  httpServiceClient = new HttpService<Array<Client>>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber = 1;
  showModal = false;
  currentPage = 1;
  clientId = "";
  clientIdString = "";
  clientName = "";
  timeout: any = null;

  get disabledButton() {
    return this.$store.state.uploads.disableUploadButton;
  }
  async getList(pageReset?: string) {
    const response = await this.httpServiceClient.getListSearch(
      "clients",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.clients = response.data;
      this.searching = false;
      this.message = "";

      if (response.headers["x-pagination"]) {
        this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
      }
    } else this.message = "no client found";

    if (pageReset) {
      this.pageNumber = 1;
    }
    this.showData = true;
  }
  get title() {
    return "Clients";
  }

  keyMonitor(event: KeyboardEvent, pageReset: string) {
    if (this.searchCriteria) {
      this.searching = true;
      this.message = "searching...";
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getList(pageReset);
      }, 500);
    }
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }

  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  showModalFunction(clientId: string, clientName: string) {
    this.showModal = true;
    this.clientIdString = clientId;
    this.clientName = clientName;
  }
  async remove() {
    await this.httpServiceClient
      .deleteItem("clients", this.clientId)
      .then(response => {
        this.$notification.success(`Client successfully removed!`);
      });
    this.showModal = false;
    this.getList();
  }

  edit(id: string) {
    this.$router.push({
      name: "clientDashboard",
      params: { id }
    });
  }

  add() {
    this.$router.push({ name: "clientAdd" });
  }
}
