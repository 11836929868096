var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded shadow p-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: {
                  tag: "button",
                  to: {
                    name: "organisationSelfRegistrationAdd",
                    params: { id: _vm.organisationId }
                  }
                }
              },
              [_vm._v("Add Domain")]
            )
          ],
          1
        ),
        _vm._m(0),
        _vm.selfRegistrations.length
          ? _c("div", { staticClass: "table-container" }, [
              _c(
                "table",
                { staticClass: "w-full table" },
                [
                  _vm._m(1),
                  _c("tr"),
                  _vm._l(_vm.selfRegistrations, function(selfRegistration) {
                    return _c(
                      "tr",
                      {
                        key: selfRegistration.selfRegistrationId,
                        staticClass: "table-tr transition cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.edit(selfRegistration.selfRegistrationId)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-4 py-4 border border-gray-25 text-left"
                          },
                          [_vm._v(_vm._s(selfRegistration.code))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-4 py-4 border border-gray-25 text-left"
                          },
                          [_vm._v(_vm._s(selfRegistration.domain))]
                        ),
                        _c(
                          "td",
                          { staticClass: "table-data-left" },
                          [
                            _c("tick-cross-date", {
                              attrs: {
                                startDate: selfRegistration.startDate,
                                endDate: selfRegistration.endDate
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-data-center",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.showModalFunction(
                                  selfRegistration.selfRegistrationId,
                                  selfRegistration.domain
                                )
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          : _c("div", [
              _vm._v(
                "There are no self-registration domains associated with this organisation"
              )
            ])
      ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v(
              "Are you sure you want to delete the domain " +
                _vm._s(this.domainName) +
                "?"
            )
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sm:flex flex-col text-xl md:flex-row py-4" },
      [_c("p", [_vm._v("Self  ")]), _c("p", [_vm._v("Registration")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Code")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Domain")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Active")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }