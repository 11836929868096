import { Account } from "./account";
import { Organisation } from "./organisation";

export class AccountOrganisation {
  account: Account;
  organisation: Organisation;
  owner: boolean;
  constructor() {
    this.account = new Account();
    this.organisation = new Organisation();
    this.owner = false;
  }
}
