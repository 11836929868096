




































import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientScope } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
import { ApiResource } from "../../../../types/apiResource";
import { IdentityResource } from "../../../../types/identityResource";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientScope: {
      scope: { required, maxLength: maxLength(200) }
    }
  }
})
export default class ClientScopeSave extends Vue {
  clientScope = new ClientScope();
  newScope = new ClientScope();
  showData = false;
  scopes: (ApiResource | IdentityResource)[] = [];
  httpService = new HttpService<ClientScope>();
  backRoute = {
    name: "clientDashboard",
    id: ""
  };

  async created() {
    await this.getScopes();
    if (this.$route.params.scopeId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/scopes/${this.$route.params.scopeId}`
      );

      this.clientScope = response.data || new ClientScope();
    }
    this.clientScope.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;
    this.showData = true;
  }
  async getScopes() {
    const httpServiceAPI = new HttpService<Array<ApiResource>>();
    const apiResourceresponse = await httpServiceAPI.getList(
      "api-resources/1/scopes"
    );
    const httpServiveIdentity = new HttpService<Array<IdentityResource>>();
    const identityResourceResponse = await httpServiveIdentity.getList(
      "identity-resources"
    );
    const httpServiceScopesAlreadyAdded = new HttpService<Array<ClientScope>>();
    const responseScopesAlreadyAdded = await httpServiceScopesAlreadyAdded.getList(
      `clients/${this.$route.params.id}/scopes`
    );
    const allScopes = [
      ...apiResourceresponse.data,
      ...identityResourceResponse.data
    ];

    if (responseScopesAlreadyAdded.status == 200) {
      this.scopes = allScopes.filter(scope => {
        return !responseScopesAlreadyAdded.data.find(
          function removeScopesAlreadyAdded(scopeAddedAlready) {
            return scope.name === scopeAddedAlready.scope;
          }
        );
      });
    } else this.scopes = allScopes;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let save = "";
      this.clientScope.id
        ? await this.httpService.putItem(
            `clients/${this.clientScope.clientId}/scopes/${this.clientScope.id}`,
            this.clientScope
          )
        : await this.httpService.postItem(
            `clients/${this.clientScope.clientId}/scopes`,
            this.clientScope
          );
      this.$notification.success("Client Scope Successfully Saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
