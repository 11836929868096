



































import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientProperty } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientProperty: {
      key: { required, maxLength: maxLength(250) },
      value: { required, maxLength: maxLength(2000) }
    }
  }
})
export default class ClientPropertySave extends Vue {
  clientProperty = new ClientProperty();
  httpService = new HttpService<ClientProperty>();
  showData = false;
  backRoute = {
    name: "clientDashboard",
    id: ""
  };

  async created() {
    if (this.$route.params.clientPropertyId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/properties/${this.$route.params.clientPropertyId}`
      );

      this.clientProperty = response.data || new ClientProperty();
    }
    this.clientProperty.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;
    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";
      this.clientProperty.id
        ? await this.httpService.putItem(
            `clients/${this.clientProperty.clientId}/properties/${this.clientProperty.id}`,
            this.clientProperty
          )
        : await this.httpService.postItem(
            `clients/${this.clientProperty.clientId}/properties`,
            this.clientProperty
          );
      this.$notification.success("Property Successfully Saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
