



















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Account } from "@/types/account";
import { AccountType } from "@/types/accountType";
import { HttpService } from "@/services/api/httpService";

@Component
export default class AccountDashboardDetails extends Vue {
  accountid: string = "";
  httpServiceAccount = new HttpService<Account>();
  httpServiceAccountType = new HttpService<AccountType>();
  account: Account = new Account();

  accountTypeName: string = "";
  get title() {
    return this.account.fullName;
  }
  async getData() {
    const response = await this.httpServiceAccount.getItem(
      `accounts/${this.accountid}`
    );

    this.account = response.data || new Account();

    const response2 = await this.httpServiceAccountType.getItem(
      `accountTypes/${this.account.accountTypeId}`
    );

    this.accountTypeName =
      response2.data.accountTypeName || this.accountTypeName;

    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.accountid = this.$route.params.id;
    this.getData();
  }

  edit() {
    this.$router.push({ name: "accountsEdit", params: { id: this.accountid } });
  }
}
