











import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DropdownList extends Vue {
  @Prop({ required: true }) data!: object[];
  @Prop({ required: true }) accessValueField!: string;
  @Prop({ default: true }) allowNull!: boolean;
  @Prop() selectedValue!: string | number | null;

  selectedValueDirty: string | number | null = null;

  @Watch("selectedValue", { immediate: true }) onChange() {
    this.selectedValue
      ? (this.selectedValueDirty = this.selectedValue)
      : (this.selectedValueDirty = null);
  }
  @Watch("data", { immediate: true }) change() {
    this.selectedValue
      ? (this.selectedValueDirty = this.selectedValue)
      : (this.selectedValueDirty = null);
  }
  onSelect() {
    this.$emit("selected", this.selectedValueDirty);
  }
}
