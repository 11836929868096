
























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";

import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import {
  isPhone,
  isPostCode
} from "@/services/customValidator/customValidators";

import { Country } from "../../types/country";
import { TitleMixin } from "../../services/mixins/titleMixin";
import { Application } from "../../types/application";

import ClientAddSpa from "./ClientAddSpa.vue";
import ClientAddWebsite from "./ClientAddWebsite.vue";
import ClientAddMachine from "./ClientAddMachine.vue";
@Component({
  mixins: [TitleMixin],
  components: { ClientAddSpa, ClientAddWebsite, ClientAddMachine }
})
export default class ClientAdd extends Vue {
  get title() {
    return "Add Client";
  }
  clientType: string = "";
  clientTypes = [
    { name: "Web Application", fontAwesomeTag: ["fab", "chrome"] },
    { name: "Single Page Application", fontAwesomeTag: "magic" },
    { name: "Machine", fontAwesomeTag: "robot" }
  ];
}
