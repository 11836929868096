import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from "axios";

export class HttpService<T> {
  getList(path: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.get<T>(`${path}`, config);
  }

  getItem(path: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.get<T>(`${path}`, config);
  }

  getListPaging(
    path: string,
    pageSize: number = 1000,
    pageNumber: number = 1,
    orderBy: string = "",
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.get<T>(
      `${path}/?pageSize=${pageSize}&pageNumber=${pageNumber}&OrderBy=${orderBy}`,
      config
    );
  }

  getListSearch(
    path: string,
    pageSize: number = 25,
    pageNumber: number = 1,
    searchQuery: string = "",
    orderBy: string = "",
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.get<T>(
      `${path}/?pageSize=${pageSize}&pageNumber=${pageNumber}&SearchQuery=${searchQuery}`,
      config
    );
  }

  postItem(
    path: string,
    payload?: T,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.post<T>(`${path}`, payload, config);
  }

  putItem(
    path: string,
    payload: T,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.put<T>(`${path}`, payload, config);
  }

  putItemNoId(
    path: string,
    payload: T,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.put<T>(path, payload, config);
  }

  patchItem(
    path: string,
    payload: T,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.patch<T>(`${path}`, payload, config);
  }

  deleteItem(
    path: string,
    id: number | string,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    return axios.delete(`${path}/${id}`, config);
  }
}
