













































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import GetJson from "@/components/GetJson.vue";
import { Organisation } from "../../types/organisation";
@Component({
  components: { GetJson }
})
export default class OrganisationList extends Vue {
  get title() {
    return "Organisations";
  }
  showData = false;
  total: number = 0;
  searchCriteria = "";
  organisations: any = [];
  httpServiceArray = new HttpService<Array<any>>();
  httpService = new HttpService<any>();
  row: any = "";
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  showModal: boolean = false;
  currentPage: number = 1;

  async getList(pageReset?: string) {
    const response = await this.httpServiceArray.getListSearch(
      "organisations",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.organisations = response.data;
      this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
    } else this.organisations = new Array<Organisation>();

    if (pageReset) {
      this.pageNumber = 1;
    }
    this.showData = true;
  }

  reset() {
    this.searchCriteria = "";
    this.getList();
  }

  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  edit(id: string) {
    this.$router.push({ name: "organisationDashboard", params: { id: id } });
  }

  add() {
    this.$router.push({ name: "organisationAdd" });
  }
}
