












import { Component, Prop, Vue } from "vue-property-decorator";
import { GroupQuestionType } from "../../../../../types/groupQuestion";
import { Answer } from "../../../../../types/answer";
import DatePicker from "@/components/DatePicker.vue";
@Component({ components: { DatePicker } })
export default class DateQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;

  checkIfAnswers(answers: Answer[]) {
    if (answers.length) return answers;
    else return answers.push(new Answer());
  }
}
