

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import OnClickOutside from "@/components/OnClickOutside.vue";
import NotificationItem from "./NotificationItem.vue";
@Component({
  components: { NotificationItem, OnClickOutside }
})
export default class NotificationList extends Vue {
  @Prop() listOpen!: boolean;
  close() {
    this.$emit("close", false);
  }
  get notifications() {
    return this.$store.state.uploads.notificationsList;
  }
  mounted() {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
    const listener = (e: any) => {
      if (
        e.target === this.$el ||
        this.$el.contains(e.target) ||
        this.listOpen
      ) {
        return;
      }
      this.close();
    };
    document.addEventListener("mousedown", listener);
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("mousedown", listener);
    });
  }
}
