var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showModal
      ? _c(
          "div",
          {
            staticClass:
              "modal animated fadeIn fixed m-auto p-8 bg-white rounded shadow-lg z-30 w-full md:w-128"
          },
          [
            _c(
              "span",
              {
                staticClass: "float-right cursor-pointer",
                on: { click: _vm.closeModal }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-xl",
                  attrs: { icon: "times" }
                })
              ],
              1
            ),
            _vm._t("default")
          ],
          2
        )
      : _vm._e(),
    _vm.showModal
      ? _c("div", {
          staticClass:
            "z-20 animated fadeIn left-0 right-0 top-0 bottom-0 transition opacity-50 fixed backdrop"
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }