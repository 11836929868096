export class AccountSubscription {
  accountId: string | null;
  accountSubscriptionId?: any;
  subscriptionPackageName: string;
  subscriptionPackageId: any;
  startDate: string | null;
  endDate: string | null;
  trial: boolean;
  perpetual: boolean;
  suspended: boolean;
  notes: string | null;

  constructor() {
    this.accountId = null;
    this.subscriptionPackageName = "";
    this.subscriptionPackageId = null;
    this.startDate = null;
    this.endDate = null;
    this.trial = false;
    this.perpetual = false;
    this.suspended = false;
    this.notes = null;
  }
}
