var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "modal-wrapper flex flex-col overflow-auto h-full p-8 bg-white ml-auto fixed z-50 top-0 right-0 bottom-0 h-full w-full md:w-1/2"
    },
    [
      _c("h1", [_vm._v("Create A New Question")]),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.add($event)
            }
          }
        },
        [
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("New Question:")
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    placeholder: "insert new question..",
                    validationObj: _vm.$v.question.name
                  },
                  model: {
                    value: _vm.$v.question.name.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.question.name, "$model", $$v)
                    },
                    expression: "$v.question.name.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [_vm._v("Required:")]),
            _c("div", { staticClass: "main-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.question.isRequired.$model,
                    expression: "$v.question.isRequired.$model"
                  }
                ],
                attrs: {
                  type: "checkbox",
                  validationObj: _vm.$v.question.isRequired
                },
                domProps: {
                  checked: Array.isArray(_vm.$v.question.isRequired.$model)
                    ? _vm._i(_vm.$v.question.isRequired.$model, null) > -1
                    : _vm.$v.question.isRequired.$model
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.$v.question.isRequired.$model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$v.question.isRequired,
                            "$model",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$v.question.isRequired,
                            "$model",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.$v.question.isRequired, "$model", $$c)
                    }
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("Maximum Length:")
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    type: "number",
                    placeholder: "insert number",
                    validationObj: _vm.$v.question.maximumLength
                  },
                  model: {
                    value: _vm.$v.question.maximumLength.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.question.maximumLength, "$model", $$v)
                    },
                    expression: "$v.question.maximumLength.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("Question Type:")
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("dropdown-list", {
                  attrs: {
                    data: _vm.questionTypes,
                    accessValueField: "questionTypeId",
                    allowNull: false
                  },
                  on: {
                    selected: function($event) {
                      _vm.$v.question.questionTypeId.$model = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [_vm._v(_vm._s(item.name))]
                      }
                    }
                  ])
                }),
                _vm.$v.question.questionTypeId.$error
                  ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
                      !_vm.$v.question.questionTypeId.required
                        ? _c("p", [_vm._v("Question Type Is Required")])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _c("question-value-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.question.questionTypeId == 4 ||
              _vm.question.questionTypeId == 5 ||
              _vm.question.questionTypeId == 8,
            expression:
              "question.questionTypeId==4||question.questionTypeId==5||question.questionTypeId==8"
          }
        ],
        attrs: { values: _vm.questionValues },
        on: {
          addAnswer: function($event) {
            return _vm.add($event)
          }
        }
      }),
      _vm.$v.questionValues.$error
        ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
            !_vm.$v.questionValues.required
              ? _c("p", [
                  _vm._v(
                    "Question answers are required for this type of question"
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", { staticClass: "ml-auto mt-auto pt-4" }, [
        _c(
          "button",
          {
            staticClass: "btn-secondary btn-width-lg",
            on: {
              click: function($event) {
                return _vm.cancel()
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "btn-default ml-2 btn-width-lg",
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v("OK")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }