import { GroupType } from "./group";

export class FullForm {
  formId: number | undefined;
  name: string;
  groups: Array<GroupType>;

  constructor() {
    this.formId = undefined;
    this.name = "";
    this.groups = Array<GroupType>();
  }
}
