import OrganisationDashboard from "@/views/organisations/organisationDashboard/OrganisationDashboard.vue";
import OrganisationList from "@/views/organisations/OrganisationList.vue";
import OrganisationsPage from "@/views/organisations/OrganisationsPage.vue";
import OrganisationAdd from "@/views/organisations/OrganisationAdd.vue";
import OrganisationEdit from "@/views/organisations/OrganisationEdit.vue";
import OrganisationSubscriptionAdd from "@/views/organisations/OrganisationSubscriptionAdd.vue";
import OrganisationApplicationAdd from "@/views/organisations/OrganisationApplicationAdd.vue";
import OrganisationSubscriptionEdit from "@/views/organisations/OrganisationSubscriptionEdit.vue";
import OrganisationSelfRegistrationAdd from "@/views/organisations/OrganisationSelfRegistrationAdd.vue";
import OrganisationSelfRegistrationEdit from "@/views/organisations/OrganisationSelfRegistrationEdit.vue";
import OrganisationIndividualAdd from "@/views/organisations/OrganisationIndividualAdd.vue";
import OrganisationIndividualEdit from "@/views/organisations/OrganisationIndividualEdit.vue";

export const organisationRoutes = {
  path: "/organisations",
  component: OrganisationsPage,
  children: [
    {
      path: "",
      component: OrganisationList,
      name: "organisationList"
    },
    {
      path: "add",
      name: "organisationAdd",
      component: OrganisationAdd,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/subscription/add",
      name: "organisationSubscriptionAdd",
      component: OrganisationSubscriptionAdd,
      props: true
    },
    {
      path: ":id/subscription/edit/:organisationSubscriptionId",
      name: "organisationSubscriptionEdit",
      component: OrganisationSubscriptionEdit,
      props: true
    },
    {
      path: ":id",
      name: "organisationDashboard",
      component: OrganisationDashboard,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/edit",
      name: "organisationEdit",
      component: OrganisationEdit,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/application/add",
      name: "organisationApplicationAdd",
      component: OrganisationApplicationAdd,
      props: true
    },
    {
      path: ":id/selfregistration/add",
      name: "organisationSelfRegistrationAdd",
      component: OrganisationSelfRegistrationAdd,
      props: true
    },
    {
      path: ":id/selfregistration/edit/:selfRegistrationId",
      name: "organisationSelfRegistrationEdit",
      component: OrganisationSelfRegistrationEdit
    },
    {
      path: ":id/individual/add",
      name: "organisationIndividualAdd",
      component: OrganisationIndividualAdd,
      props: true
    },
    {
      path: ":id/individual/edit/:accountId",
      name: "organisationIndividualEdit",
      component: OrganisationIndividualEdit,
      props: true
    }
  ]
};
