

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import DiscardBar from "@/components/DiscardBar.vue";

@Component({
  components: {
    DiscardBar
  }
})
export default class SaveBar extends Vue {
  @Prop({ default: true }) showSave!: boolean;
  @Prop({ default: true }) showBack!: boolean;

  @Prop({ default: "Save" }) saveLabel!: string;
  save() {
    this.$store.commit("SAVE", true);
  }

  back() {
    this.$store.commit("BACK", true);
  }
}
