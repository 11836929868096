


























import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ClientRedirectUri,
  ClientPostLogoutRedirectUri
} from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
import { isUrl } from "@/services/customValidator/customValidators";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientPostLogoutRedirectUri: {
      postLogoutRedirectUri: { required, maxLength: maxLength(250), isUrl }
    }
  }
})
export default class ClientPostLogoutRedirectUriSave extends Vue {
  clientPostLogoutRedirectUri = new ClientPostLogoutRedirectUri();
  httpService = new HttpService<ClientPostLogoutRedirectUri>();
  showData = false;
  backRoute = {
    name: "clientDashboard",
    id: ""
  };

  async created() {
    if (this.$route.params.postLogoutRedirectUriId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/post-logout-redirect-uris/${this.$route.params.postLogoutRedirectUriId}`
      );

      this.clientPostLogoutRedirectUri =
        response.data || new ClientPostLogoutRedirectUri();
    }
    this.clientPostLogoutRedirectUri.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;
    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";
      this.clientPostLogoutRedirectUri.id
        ? await this.httpService.putItem(
            `clients/${this.clientPostLogoutRedirectUri.clientId}/post-logout-redirect-uris/${this.clientPostLogoutRedirectUri.id}`,
            this.clientPostLogoutRedirectUri
          )
        : await this.httpService.postItem(
            `clients/${this.clientPostLogoutRedirectUri.clientId}/post-logout-redirect-uris`,
            this.clientPostLogoutRedirectUri
          );
      this.$notification.success("Post Logout Redirect Uri Successfully Saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
