








































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { QuestionValue } from "@/types/questionValue";
import { Question } from "@/types/question";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
@Component({
  validations: {
    questionValue: {
      name: { required, maxLength: maxLength(300) }
    }
  }
})
export default class questionValueList extends Vue {
  @Prop() values!: Array<QuestionValue>;

  questionValues: Array<QuestionValue> = Array<QuestionValue>();
  questionValue: QuestionValue = new QuestionValue();
  httpServiceArray = new HttpService<Array<QuestionValue>>();
  httpService = new HttpService<QuestionValue>();
  id: string = "";

  showModal: boolean = false;
  add() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.questionValue.isSelected = true;
      this.$emit("addAnswer", this.questionValue);
      this.questionValue = new QuestionValue();
      this.$v.$reset();
    }
  }
  remove(index: number) {
    this.values.splice(index, 1);
  }
}
