var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.checkIfAnswers(_vm.groupQuestion.question.answers), function(
        answer,
        index
      ) {
        return [
          _c("date-picker", {
            key: index,
            staticClass:
              "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
            model: {
              value: answer.questionAnswerDate,
              callback: function($$v) {
                _vm.$set(answer, "questionAnswerDate", $$v)
              },
              expression: "answer.questionAnswerDate"
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }