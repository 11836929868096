

























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Account } from "@/types/account";
import { HttpService } from "@/services/api/httpService";
import moment from "moment";

import AccountPassword from "@/views/accounts/password/AccountPassword.vue";

@Component({
  components: {
    AccountPassword
  }
})
export default class AccountDashboardInformation extends Vue {
  accountid: string = "";
  showModal: boolean = false;

  httpService = new HttpService<Account>();
  httpServicePatchObject = new HttpService<
    [
      {
        op: string;
        path: string;
        value: string | number | boolean;
      }
    ]
  >();
  account: Account = new Account();
  patchObject!: [
    {
      op: string;
      path: string;
      value: boolean;
    }
  ];
  isSuspended: boolean = false;
  isSuspendedText: string = "";
  showPassword: boolean = false;

  async getData() {
    const response = await this.httpService.getItem(
      `accounts/${this.accountid}`
    );

    this.account = response.data || new Account();
    this.isSuspended = response.data.isSuspended;

    this.$emit("finishedLoading", true);
  }
  get initials() {
    let initials: string = "";
    if (this.account.fullName) {
      const names = this.account.fullName.split(" ");

      for (let i = 0; i < names.length; i++) {
        initials += names[i].substring(0, 1).toUpperCase();
      }
    } else initials = "-";
    return initials;
  }
  async suspendAccount() {
    this.isSuspended = !this.isSuspended;
    if (this.isSuspended == true) {
      this.isSuspendedText = "The account has been suspended";
    } else {
      this.isSuspendedText = "The account has been enabled";
    }
    this.patchObject = [
      {
        op: "replace",
        path: "/isSuspended",
        value: this.isSuspended
      }
    ];

    await this.httpServicePatchObject
      .patchItem(`accounts/${this.accountid}`, this.patchObject)
      .then(response => {
        this.$notification.success(`${this.isSuspendedText}!`);

        this.getData();
      });
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.accountid = this.$route.params.id;
    this.getData();
  }

  moment(date: Date) {
    return moment(date).format("DD MMM YYYY, h:mm:ss");
  }

  showModalFunction(accountId: string, firstName: string, lastName: string) {
    this.showModal = true;
    this.accountid = accountId;
  }
  async remove() {
    await this.httpService
      .deleteItem("accounts", this.accountid)
      .then(response => {
        this.$notification.success(`Account successfully removed!`);
      });
    this.showModal = false;
    this.$router.push({
      name: "accountsList"
    });
  }
}
