









import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
@Component
export default class TickCrossDate extends Vue {
  @Prop({ required: true }) startDate!: Date;
  @Prop({ default: moment().format("DD MMM YYYY") }) endDate!: Date;

  evaluateDates(date1: Date, date2: Date) {
    let todayDate = moment(new Date());
    let startDate = moment(this.startDate);
    let endDate = moment(this.endDate);

    return (
      (startDate.isSameOrBefore(todayDate) && this.endDate === null) ||
      (startDate.isSameOrBefore(todayDate) && endDate.isSameOrAfter(todayDate))
    );
  }
}
