var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [_vm._v("Communication Add")]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Communication Name")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("input-component", {
              attrs: { validationObj: _vm.$v.communication.communicationName },
              model: {
                value: _vm.$v.communication.communicationName.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.communication.communicationName,
                    "$model",
                    $$v
                  )
                },
                expression: "$v.communication.communicationName.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Comments")]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("text-area", {
              attrs: { validationObj: _vm.$v.communication.comment },
              model: {
                value: _vm.$v.communication.comment.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.communication.comment, "$model", $$v)
                },
                expression: "$v.communication.comment.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Communication Type")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("get-json", {
              attrs: { url: "communicationtypes" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var response = ref.response
                    return _c("dropdown-list", {
                      staticClass: "dropdown-list",
                      attrs: {
                        data: response,
                        accessValueField: "communicationTypeId",
                        allowNull: false,
                        selectedValue:
                          _vm.$v.communication.communicationTypeId.$model
                      },
                      on: {
                        selected: function($event) {
                          _vm.$v.communication.communicationTypeId.$model = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(_vm._s(item.communicationTypeName))]
                          }
                        }
                      ])
                    })
                  }
                }
              ])
            }),
            _vm.$v.communication.communicationTypeId.$error
              ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
                  !_vm.$v.communication.communicationTypeId.required
                    ? _c("p", [_vm._v("This field is required")])
                    : _vm._e()
                ])
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }