import { render, staticRenderFns } from "./ClientRedirectUriSave.vue?vue&type=template&id=eeac122c&scoped=true&"
import script from "./ClientRedirectUriSave.vue?vue&type=script&lang=ts&"
export * from "./ClientRedirectUriSave.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eeac122c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eeac122c')) {
      api.createRecord('eeac122c', component.options)
    } else {
      api.reload('eeac122c', component.options)
    }
    module.hot.accept("./ClientRedirectUriSave.vue?vue&type=template&id=eeac122c&scoped=true&", function () {
      api.rerender('eeac122c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/client/clientConfiguration/clientRedirectUri/ClientRedirectUriSave.vue"
export default component.exports