


























































































































































import { Component, Vue } from "vue-property-decorator";
import Panel from "@/components/Panel.vue";
import StatisticsChart from "@/components/StatisticsChart.vue";
import DatePicker from "@/components/DatePicker.vue";
import { HttpService } from "@/services/api/httpService";
import { Account } from "../../types/account";
@Component({
  components: {
    Panel,
    DatePicker,
    StatisticsChart
  }
})
export default class Dashboard extends Vue {
  httpServiceArray = new HttpService<Array<Account>>();
  responseArray = new Array<Account>();
  created() {
    this.getList();
  }
  getList() {
    this.httpServiceArray
      .getListSearch("accounts", 10, 1, "")
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
        } else {
          this.responseArray = [];
        }
      });
  }
}
