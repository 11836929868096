export class Country {
  countryId?: any;
  countryName: string;
  countryCode: string;

  constructor() {
    this.countryName = "";
    this.countryCode = "";
  }
}
