var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Add Form")]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("\n        Form Name:\n        "),
              _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    validationObj: _vm.$v.form.name,
                    placeholder: "Insert form name..."
                  },
                  model: {
                    value: _vm.$v.form.name.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.form.name, "$model", $$v)
                    },
                    expression: "$v.form.name.$model"
                  }
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }