























import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientIdPRestriction } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientIdPRestriction: {
      provider: { required, maxLength: maxLength(250) }
    }
  }
})
export default class ClientIdPRestrictionSave extends Vue {
  clientIdPRestriction = new ClientIdPRestriction();
  httpService = new HttpService<ClientIdPRestriction>();
  showData = false;
  backRoute = {
    name: "clientDashboard",
    id: ""
  };

  async created() {
    if (this.$route.params.IdPRestrictionId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/identity-provider-restrictions/${this.$route.params.IdPRestrictionId}`
      );

      this.clientIdPRestriction = response.data || new ClientIdPRestriction();
    }
    this.clientIdPRestriction.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;
    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";
      this.clientIdPRestriction.id
        ? await this.httpService.putItem(
            `clients/${this.clientIdPRestriction.clientId}/identity-provider-restrictions/${this.clientIdPRestriction.id}`,
            this.clientIdPRestriction
          )
        : await this.httpService.postItem(
            `clients/${this.clientIdPRestriction.clientId}/identity-provider-restrictions`,
            this.clientIdPRestriction
          );
      this.$notification.success(
        "Client Identity Resource Restriction Successfully Saved"
      );
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
