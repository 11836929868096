


















import { AccountType } from "@/types/accountType";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    accountType: {
      accountTypeName: { required, maxLength: maxLength(30) }
    }
  }
})
export default class AccountTypeAdd extends Vue {
  accountType: AccountType = new AccountType();
  httpService: HttpService<AccountType> = new HttpService<AccountType>();
  backRoute = { name: "accountTypesList" };

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpService.postItem("accountTypes", this.accountType);
      this.$notification.success("Account type successfully saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
