import "@/assets/css/tailwind.css";
import "../node_modules/animate.css/animate.css";

import { Vue } from "vue-property-decorator";

import Vuelidate from "vuelidate";
import ValidationStatus from "@/services/validationStatus/validationStatus.ts";
import moment from "moment";
import router from "@/router/router";
import axiosExport from "@/services/api/axios";
import store from "@/store/store";
import Notification from "@/services/notification/notification";
import TextAreaComponent from "@/components/inputs/TextAreaComponent.vue";
import Pagination from "@/components/Pagination.vue";
import Modal from "@/components/Modal.vue";
import SaveBar from "@/components/SaveBar.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

import {
  faLock,
  faSpinner,
  faBan,
  faFileImport,
  faUpload,
  faBell,
  faPowerOff,
  faMagic,
  faBuilding,
  faLaptop,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faComment,
  faDesktop,
  faDotCircle,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFileAlt,
  faMailBulk,
  faPencilAlt,
  faRobot,
  faSearch,
  faSignOutAlt,
  faShieldAlt,
  faSlidersH,
  faTimes,
  faTrash,
  faCog,
  faUser,
  faUserCircle,
  faUserPlus,
  faScroll,
  faUndo,
  faArrowsAlt,
  faQuestion,
  faQuestionCircle,
  faEllipsisH,
  faLowVision
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InputComponent from "@/components/inputs/InputComponent.vue";
import CheckboxComponent from "@/components/inputs/CheckboxComponent.vue";
import TableComponent from "@/components/table/TableComponent.vue";
import DropdownList from "@/components/DropdownList.vue";
import TickCross from "@/components/table/TickCross.vue";
import TickCrossDate from "@/components/table/TickCrossDate.vue";
import GetJson from "@/components/GetJson.vue";
import App from "./App.vue";
import PortalVue from "portal-vue";
import IdToObject from "@/services/returnObjectFromId/returnObjectFromId";
import SortList from "@/services/sortList/sortList";
import { TitleMixin } from "@/services/mixins/titleMixin";
import VueCompositionApi from "@vue/composition-api";

library.add(
  faLock,
  faChrome,
  faMagic,
  faSpinner,
  faArrowsAlt,
  faFileImport,
  faDesktop,
  faUserCircle,
  faComment,
  faScroll,
  faDesktop,
  faUserCircle,
  faComment,
  faLaptop,
  faFileAlt,
  faChartBar,
  faSlidersH,
  faEnvelope,
  faBell,
  faRobot,
  faSearch,
  faPencilAlt,
  faTrash,
  faCog,
  faCheck,
  faTimes,
  faShieldAlt,
  faUser,
  faMailBulk,
  faBan,
  faUserPlus,
  faCaretUp,
  faCaretDown,
  faEye,
  faDotCircle,
  faBuilding,
  faPowerOff,
  faEyeSlash,
  faUndo,
  faQuestionCircle,
  faQuestion,
  faEllipsisH,
  faSignOutAlt,
  faUpload
);
Vue.component("get-json", GetJson);
Vue.use(VueCompositionApi);
Vue.use(Notification);
Vue.use(Vuelidate);
Vue.use(ValidationStatus);
Vue.use(IdToObject);
Vue.mixin(TitleMixin);
Vue.use(SortList);
Vue.component("text-area", TextAreaComponent);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("input-component", InputComponent);
Vue.component("checkbox-component", CheckboxComponent);
Vue.component("table-component", TableComponent);
Vue.component("tick-cross", TickCross);
Vue.component("tick-cross-date", TickCrossDate);
Vue.component("dropdown-list", DropdownList);
Vue.component("save-bar", SaveBar);
Vue.component("modal", Modal);
Vue.component("pagination", Pagination);
Vue.component("get-json", GetJson);
Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vue.use(PortalVue);
axiosExport();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app", true);
