





























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GroupType } from "../../../types/group";
import { required, maxLength, numeric } from "vuelidate/lib/validators";

@Component({
  validations: {
    updatedGroup: {
      name: { required, maxLength: maxLength(100) },
      description: { maxLength: maxLength(100) },
      groupLimit: { numeric },
      multipleGroups: { required }
    }
  }
})
export default class ConfigureGroup extends Vue {
  @Prop() questionGroup!: GroupType;
  @Prop() groupIndex!: number;
  showConfigureGroup: boolean = false;
  updatedGroup = new GroupType();

  showModal: boolean = false;

  @Watch("questionGroup", { immediate: true, deep: true }) update() {
    this.updatedGroup = Object.assign({}, this.questionGroup);
  }

  get triggerSave() {
    return this.$store.getters.getSideModalSave;
  }
  @Watch("triggerSave") onSave() {
    if (this.triggerSave) {
      this.save();
    }
  }

  save() {
    this.$v.$touch();
    this.$store.commit("SIDEMODALSAVE", false);
    if (!this.$v.$invalid) {
      this.$store.commit("UPDATE_GROUP", {
        group: this.updatedGroup,
        groupIndex: this.groupIndex
      });
      this.$emit("close");
    } else return;
  }

  removeGroup() {
    this.$store.commit("REMOVE_GROUP", this.groupIndex);
  }
}
