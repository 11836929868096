export class Client {
  id?: number;
  absoluteRefreshTokenLifetime!: number;
  accessTokenLifetime!: number;
  accessTokenType!: number;
  allowRememberConsent!: boolean;
  authorizationCodeLifetime!: number;
  frontChannelLogoutSessionRequired!: boolean;
  backChannelLogoutSessionRequired!: boolean;
  clientClaimsPrefix!: string;
  deviceCodeLifetime!: number;
  enabled!: boolean;
  identityTokenLifetime!: number;
  protocolType!: string;
  refreshTokenExpiration!: number;
  refreshTokenUsage!: number;
  requireClientSecret!: boolean;
  requireConsent!: boolean;
  slidingRefreshTokenLifetime!: number;
  allowAccessTokensViaBrowser!: boolean;
  allowOfflineAccess!: boolean;
  allowPlainTextPkce!: boolean;
  alwaysIncludeUserClaimsInIdToken!: boolean;
  alwaysSendClientClaims!: boolean;
  clientId!: string;
  clientName!: string;
  clientUri!: string;
  consentLifetime!: number;
  description!: string;
  enableLocalLogin!: boolean;
  includeJwtId!: boolean;
  logoUri!: string;
  pairWiseSubjectSalt!: string;
  requirePkce!: boolean;
  updateAccessTokenClaimsOnRefresh!: boolean;
  updated!: Date;
  userCodeType!: number;
  userSsoLifetime!: number;
  applicationId!: number;
  frontChannelLogoutUri!: string;
  lastAccessed!: Date;
  nonEditable!: Boolean;
}

export class CreateClient {
  id: any = null;
  clientId = "";
  description = "";
  scopes: Array<ClientScope> = [];
}

export class CreateWebAppClientBase extends CreateClient {
  clientName = "";
  clientUri = "";
  logoUri: string | null = "";
  applicationId?: string;
  redirectUris = new Array<ClientRedirectUri>();
  postLogoutRedirectUri = new Array<ClientPostLogoutRedirectUri>();
  constructor() {
    super();
    this.redirectUris[0] = new ClientRedirectUri();
    this.postLogoutRedirectUri[0] = new ClientPostLogoutRedirectUri();
  }
}

export class CreateWebAppClient extends CreateWebAppClientBase {
  allowOfflineAccess = false;
  frontChannelLogoutUri: null | string = "";
  backChannelLogoutUri: null | string = "";
  secret = "";
}

export class CreateSpaClient extends CreateWebAppClientBase {
  corsOrigins = new Array<ClientCorsOrigin>();
  constructor() {
    super();
    this.corsOrigins[0] = new ClientCorsOrigin();
  }
}

export class CreateMachineClient extends CreateClient {
  secret = "";
}

export class ClientClaim {
  id?: any;
  clientId: number | null;
  type: string;
  value: string;

  constructor() {
    this.clientId = null;
    this.type = "";
    this.value = "";
  }
}

export class ClientCorsOrigin {
  id?: any;
  clientId?: number | null;
  origin = "";
}
export class ClientProperty {
  id?: any;
  clientId: number | null;
  key: string;
  value: string;

  constructor() {
    this.clientId = null;
    this.key = "";
    this.value = "";
  }
}
export class ClientPostLogoutRedirectUri {
  id?: any;
  clientId: number | null;
  postLogoutRedirectUri: string;

  constructor() {
    this.clientId = null;
    this.postLogoutRedirectUri = "";
  }
}
export class ClientGrantType {
  id?: any;
  clientId: number | null;
  grantType: string;

  constructor() {
    this.clientId = null;
    this.grantType = "";
  }
}
export class ClientScope {
  id?: any;
  clientId: number | null;
  scope: string;

  constructor(scope: string = "") {
    this.clientId = null;
    this.scope = scope;
  }
}
export class ClientSecret {
  id?: any;
  clientId: number | string = "";
  description: string = "";
  type: string = "SharedSecret";
  value: string = "";
  expiration: Date = new Date();
}
export class ClientRedirectUri {
  id?: any;
  clientId: number | null;
  redirectUri: string;

  constructor() {
    this.clientId = null;
    this.redirectUri = "";
  }
}
export class ClientIdPRestriction {
  id?: any;
  clientId: number | null;
  provider: string;

  constructor() {
    this.clientId = null;
    this.provider = "";
  }
}
