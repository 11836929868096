import { FullForm } from "@/types/fullForm";

export class Application {
  applicationId: any;
  formName?: string;
  formId: any;
  applicationName: string;
  allowSelfRegistration: boolean;
  enforceFormCompletion: boolean;
  requireAddress: boolean;
  consumerApplication: boolean;

  constructor() {
    this.applicationId = null;
    this.formId = null;
    this.applicationName = "";
    this.allowSelfRegistration = false;
    this.enforceFormCompletion = false;
    this.requireAddress = false;
    this.consumerApplication = false;
  }
}

export class FullApplication extends Application {
  form: FullForm;

  constructor() {
    super();
    this.form = new FullForm();
  }
}

export class AccountApplicationType {
  formCompleted: boolean;
  constructor() {
    this.formCompleted = false;
  }
}

export class FullAccountApplicationType extends AccountApplicationType {
  application: FullApplication;
  constructor() {
    super();
    this.application = new FullApplication();
  }
}
