var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [_vm._v("Add answer")]),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.add($event)
            }
          }
        },
        [
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [_vm._v("Answer:")]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    placeholder: "Insert new answer...",
                    validationObj: _vm.$v.questionValue.name
                  },
                  model: {
                    value: _vm.$v.questionValue.name.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.questionValue.name, "$model", $$v)
                    },
                    expression: "$v.questionValue.name.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [_vm._v("Sort Order:")]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    type: "number",
                    validationObj: _vm.$v.questionValue.sortOrder
                  },
                  model: {
                    value: _vm.$v.questionValue.sortOrder.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.questionValue.sortOrder, "$model", $$v)
                    },
                    expression: "$v.questionValue.sortOrder.$model"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }