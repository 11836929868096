























import { Form } from "@/types/form";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    form: {
      name: { required, maxLength: maxLength(100) }
    }
  }
})
export default class FormAdd extends Vue {
  backRoute: { name: string; id?: string } = {
    name: "formsList"
  };
  form: Form = new Form();
  httpService: HttpService<Form> = new HttpService<Form>();
  showData = false;
  get title() {
    return "Forms - New";
  }
  mounted() {
    this.showData = true;
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const response = await this.httpService.postItem("forms", this.form);
      this.$notification.success("Form successfully added");
      this.$store.dispatch("SET_DIRTY", false);
      this.$router.push({
        name: "formsEdit",
        params: { id: String(response.data.formId) }
      });
    }
  }
}
