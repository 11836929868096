




















































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SelfRegistration } from "@/types/selfRegistration";
import DatePicker from "@/components/DatePicker.vue";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  endDateGreaterThanStartDate,
  dateGreaterThanToday,
  domain
} from "@/services/customValidator/customValidators";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { Organisation } from "../../types/organisation";

@Component({
  components: { DatePicker },
  validations: {
    selfRegistration: {
      code: { required, maxLength: maxLength(25) },
      domain: { required, domain, maxLength: maxLength(100) },
      startDate: { required },
      endDate: { required, endDateGreaterThanStartDate, dateGreaterThanToday }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationSelfRegistrationAdd extends Vue {
  showData = false;
  organisationName = "";
  organisationId: string = "";
  selfRegistrationId: string = "";
  selfRegistration = new SelfRegistration();
  httpServiceSelfRegistration = new HttpService<SelfRegistration>();

  backRoute = {
    name: "organisationDashboard",
    id: ""
  };
  get title() {
    return `${this.organisationName} - ${this.selfRegistration.domain}`;
  }
  async getData() {
    const httpService = new HttpService<Organisation>();
    const response3 = await httpService.getItem(
      `organisations/${this.organisationId}`
    );
    this.organisationName = response3.data.organisationName;
    await this.httpServiceSelfRegistration
      .getItem(
        `organisations/${this.organisationId}/selfregistration/${this.selfRegistrationId}`,
        {
          errorInterceptor: false
        }
      )
      .then(response => {
        this.selfRegistration = response.data;
      });
    this.showData = true;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.backRoute.id = this.$route.params.id;
    this.organisationId = this.$route.params.id;
    this.selfRegistrationId = this.$route.params.selfRegistrationId;
    this.getData();
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.httpServiceSelfRegistration
        .putItemNoId(
          `organisations/${this.organisationId}/selfregistration`,
          this.selfRegistration
        )
        .then(res => this.$notification.success(`Domain successfully saved!`))
        .then(() => {
          this.$router.push({
            name: "organisationDashboard",
            params: { id: this.organisationId }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
