import { ActionTree } from "vuex";
import { GetForm } from "./types";
import { RootState } from "../../types";
import { GroupQuestionType } from "@/types/groupQuestion";
import { Question } from "@/types/question";
import { HttpService } from "@/services/api/httpService";
import { QuestionValue } from "@/types/questionValue";
import { FullForm } from "@/types/fullForm";
import Vue from "vue";

export const actions: ActionTree<GetForm, RootState> = {
  async SET_FORM({ commit }, payLoad: string) {
    const httpService = new HttpService<FullForm>();
    await httpService.getItem(payLoad).then(response => {
      let formData = response.data;
      formData.groups.forEach(group => {
        group.uniqueId = Math.floor(Math.random() * 1000000 + 1);
      });
      commit("SET_FORM", formData);
    });
  },
  async SAVE_FORM({ commit, state }, payLoad) {
    const httpService = new HttpService<FullForm>();
    const response = await httpService.postItem(payLoad.url, state.form);
    if ((response && response.status === 200) || 204) {
      Vue.prototype.$notification.success("Form Successfully Updated");
    }
  },

  ADD_GROUP({ commit }, payLoad) {
    payLoad.uniqueId = Math.floor(Math.random() * 1000000 + 1);
    commit("ADD_GROUP", payLoad);
  },

  REMOVE_QUESTION(
    { commit },
    payLoad: { questionOrder: number; groupIndex: number }
  ) {
    commit("REMOVE_QUESTION", payLoad);
  },

  REMOVE_QUESTION_ID(
    { commit },
    payLoad: { question: Question; groupIndex: number }
  ) {
    commit("REMOVE_QUESTION_ID", payLoad);
  },

  async ADD_QUESTION(
    { commit },
    payLoad: { question: Question; index: number }
  ) {
    const newQuestion = new GroupQuestionType();
    newQuestion.question = payLoad.question;
    const httpService = new HttpService<Array<QuestionValue>>();
    await httpService
      .getList(`questions/${payLoad.question.questionId}/values`)
      .then(response => {
        if (response.status != 404)
          newQuestion.question.questionValues = response.data;
        if (newQuestion.question.questionValues)
          newQuestion.question.questionValues.sort((a: any, b: any) =>
            a.sortOrder > b.sortOrder ? 1 : -1
          );
      });

    commit("ADD_QUESTION", { newQuestion, index: payLoad.index });
  },

  async UPDATE_QUESTION(
    { commit },
    payLoad: { question: Question; index: number; questionIndex: number }
  ) {
    let questionValues: any = [];
    const httpService = new HttpService<Array<QuestionValue>>();
    await httpService
      .getListPaging(`questions/${payLoad.question.questionId}/values`, 1000, 1)
      .then(response => {
        if (response.status != 404) questionValues = response.data;
        for (let questionValue of questionValues) {
          questionValue.isSelected = true;
        }
        if (questionValues)
          questionValues.sort((a: any, b: any) =>
            a.sortOrder > b.sortOrder ? 1 : -1
          );
      });

    commit("UPDATE_QUESTION", {
      question: payLoad.question,
      questionValues,
      questionIndex: payLoad.questionIndex,
      index: payLoad.index
    });
  },

  async REMOVE_GROUPS_AND_QUESTIONS({ state, commit }) {
    const http = new HttpService();

    await state.formDelete.questions.forEach(async question => {
      await http.deleteItem(
        `groups/${question.groupId}/questions`,
        question.groupQuestionId
      );
    });

    await state.formDelete.groups.forEach(async group => {
      await http.deleteItem(`forms/${group.formId}/groups`, group.groupId);
    });
    commit("RESET_ARRAYS");
  },

  async SET_QUESTIONS({ commit }, payLoad: string) {
    const httpService = new HttpService<Array<Question>>();
    await httpService.getListPaging(payLoad).then(response => {
      commit("SET_QUESTIONS", Vue.prototype.$sortList(response.data, "name"));
    });
  }
};
