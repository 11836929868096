var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("h1", [_vm._v("Client Secret")]),
    _vm.showData == false
      ? _c("div", { staticClass: "loader" })
      : _c(
          "form",
          {
            staticClass: "w-full",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.save($event)
              }
            }
          },
          [
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [
                _vm._v("\n        Type\n        "),
                _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("input-component", {
                    attrs: {
                      type: "text",
                      validationObj: _vm.$v.clientSecret.type
                    },
                    model: {
                      value: _vm.$v.clientSecret.type.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.clientSecret.type, "$model", $$v)
                      },
                      expression: "$v.clientSecret.type.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [
                _vm._v("\n        Value\n        "),
                _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("input-component", {
                    attrs: {
                      type: "text",
                      placeholder: "Please add a new client secret.",
                      validationObj: _vm.$v.clientSecret.value
                    },
                    model: {
                      value: _vm.$v.clientSecret.value.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.clientSecret.value, "$model", $$v)
                      },
                      expression: "$v.clientSecret.value.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [
                _vm._v("Description")
              ]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("text-area", {
                    attrs: {
                      type: "text",
                      validationObj: _vm.$v.clientSecret.description
                    },
                    model: {
                      value: _vm.$v.clientSecret.description.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.clientSecret.description, "$model", $$v)
                      },
                      expression: "$v.clientSecret.description.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [_vm._v("Expiration")]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("date-picker", {
                    staticClass:
                      "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                    attrs: { minDate: _vm.today },
                    model: {
                      value: _vm.clientSecret.expiration,
                      callback: function($$v) {
                        _vm.$set(_vm.clientSecret, "expiration", $$v)
                      },
                      expression: "clientSecret.expiration"
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }