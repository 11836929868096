import { render, staticRenderFns } from "./ApplicationPage.vue?vue&type=template&id=938fbe14&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('938fbe14')) {
      api.createRecord('938fbe14', component.options)
    } else {
      api.reload('938fbe14', component.options)
    }
    module.hot.accept("./ApplicationPage.vue?vue&type=template&id=938fbe14&", function () {
      api.rerender('938fbe14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/application/ApplicationPage.vue"
export default component.exports