var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.questionValues.length === 0
        ? _c("h3", [_vm._v("Please add some answers")])
        : _c("div", { staticClass: "table-container" }, [
            _c(
              "table",
              { staticClass: "w-full table" },
              [
                _vm._m(0),
                _vm._l(_vm.questionValues, function(questionValue) {
                  return _c(
                    "tr",
                    {
                      key: questionValue.questionValueId,
                      staticClass: "table-tr transition",
                      on: {
                        click: function($event) {
                          return _vm.edit(questionValue)
                        }
                      }
                    },
                    [
                      _c("td", { staticClass: "table-data-left" }, [
                        _c("h3", [_vm._v(_vm._s(questionValue.name))])
                      ]),
                      _c("td", { staticClass: "table-data-left" }, [
                        _c("h3", [_vm._v(_vm._s(questionValue.sortOrder))])
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "border border-gray-25 w-24 text-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "px-8 py-4 cursor-pointer",
                              on: {
                                click: function($event) {
                                  return _vm.edit(questionValue)
                                }
                              }
                            },
                            [_vm._v("Edit")]
                          )
                        ]
                      ),
                      _c(
                        "td",
                        {
                          staticClass: "border border-gray-25 w-24 text-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "px-8 py-4 cursor-pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showModalFunction(questionValue)
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v(
              "Are you sure you want to delete " +
                _vm._s(_vm.questionValue.name)
            )
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove(_vm.questionValue.questionValueId)
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Answer")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Sort Order")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Edit")]),
      _c("th", { staticClass: "table-header-left" }, [_vm._v("Delete")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }