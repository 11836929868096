var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "save-bar",
    { attrs: { showSave: _vm.scopes.length > 0 } },
    [
      _vm.showData == false
        ? _c("div", { staticClass: "loader" })
        : [
            _c("h1", [_vm._v("Add Scope")]),
            _c(
              "form",
              {
                staticClass: "w-full",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.save($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "input-container" }, [
                  _c("div", { staticClass: "input-label" }, [
                    _vm._v("Select a Scope")
                  ]),
                  _c(
                    "div",
                    { staticClass: "main-input" },
                    [
                      _vm.scopes.length > 0
                        ? _c("dropdown-list", {
                            staticClass: "dropdown-list",
                            attrs: {
                              data: _vm.scopes,
                              accessValueField: "name",
                              selectedValue: _vm.clientScope.scope,
                              allowNull: false
                            },
                            on: {
                              selected: function($event) {
                                _vm.clientScope.scope = $event
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(_vm._s(item.displayName))]
                                  }
                                }
                              ],
                              null,
                              false,
                              2697667266
                            )
                          })
                        : _c("h3", { staticClass: "mt-2" }, [
                            _vm._v("There are no more available scopes")
                          ]),
                      _vm.$v.clientScope.scope.$error
                        ? _c(
                            "div",
                            { staticClass: "text-red-400 text-xs italic" },
                            [
                              !_vm.$v.clientScope.scope.required
                                ? _c("p", [
                                    _vm._v(
                                      "\n              Please select a client scope\n            "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }