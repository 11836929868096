






















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Account } from "@/types/account";
import { HttpService } from "@/services/api/httpService";
import moment from "moment";
import { Client } from "@/types/client";

@Component({
  components: {}
})
export default class ClientDashboardInformation extends Vue {
  @Prop() clientType!: string;
  httpService = new HttpService<Client>();
  client: Client = new Client();

  async getData(id: string) {
    const response = await this.httpService.getItem(`clients/${id}`);
    this.client = response.data;
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.getData(this.$route.params.id);
  }
}
