export class Communication {
  communicationId?: any;
  communicationTypeName: string;
  communicationTypeId: number | null;
  communicationName: string;
  comment: string;
  links: null;

  constructor() {
    this.communicationTypeName = "";
    this.communicationTypeId = null;
    this.communicationName = "";
    this.comment = "";
    this.links = null;
  }
}
