















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
@Component
export default class TableComponent extends Vue {
  @Prop({ required: false, default: true }) showEdit!: boolean;
  @Prop({ required: false, default: true }) showDelete!: boolean;
  @Prop({ required: true }) responseArray!: {}[];
  @Prop({ required: true }) primaryKey!: number | string;
  @Prop({ required: true }) columns!: {}[];
  @Prop({ default: "table-tr transition" }) tableRowClass!: string;
  @Prop({ default: "table-data-left" }) tableDataClass!: string;
  checkifDate(value: string) {
    let tryDate = moment(value, moment.ISO_8601, true).format();
    if (tryDate != "Invalid date") {
      return true;
    }
  }
}
