var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "textarea",
      _vm._g(
        {
          staticClass:
            "w-full rounded p-2 border border-gray-50 focus:outline-none transition",
          class: _vm.$validationStatus.status(_vm.validationObj),
          attrs: {
            rows: _vm.rows,
            cols: _vm.cols,
            placeholder: _vm.placeholder
          },
          domProps: { value: _vm.value },
          on: { input: _vm.input }
        },
        _vm.listeners
      )
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }