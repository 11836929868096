













































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import axios, { AxiosError } from "axios";
import { ClientPostLogoutRedirectUri } from "@/types/client";

@Component
export default class DashboardClientPostLogoutRedirectUri extends Vue {
  postLogoutRedirectUriId: string = "";
  httpService = new HttpService<ClientPostLogoutRedirectUri>();
  responseArray = new Array<ClientPostLogoutRedirectUri>();
  showModal: boolean = false;
  clientId: any = "";
  removeData = new ClientPostLogoutRedirectUri();
  async getList() {
    let httpService = new HttpService<Array<ClientPostLogoutRedirectUri>>();
    const response = await httpService.getList(
      `clients/${this.clientId}/post-logout-redirect-uris`
    );
    this.responseArray = response.data;
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.clientId = this.$route.params.id;
    this.getList();
  }

  edit(postLogoutRedirectUri: ClientPostLogoutRedirectUri) {
    this.$router.push({
      name: "clientPostLogoutRedirectUriEdit",
      params: {
        id: this.clientId,
        postLogoutRedirectUriId: postLogoutRedirectUri.id
      }
    });
  }

  confirmRemove(data: ClientPostLogoutRedirectUri) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: ClientPostLogoutRedirectUri) {
    const response = await this.httpService.deleteItem(
      `clients/${data.clientId}/post-logout-redirect-uris`,
      data.id
    );

    this.responseArray = this.responseArray.filter(
      (item: ClientPostLogoutRedirectUri) => item.clientId != data.clientId,
      this.$notification.success(
        `${data.postLogoutRedirectUri} successfully removed!`
      )
    );

    this.showModal = false;
    this.getList();
  }
}
