import { MutationTree } from "vuex";

import { SaveBar } from "./types";

export const mutations: MutationTree<SaveBar> = {
  SAVE(state, payLoad) {
    state.save = payLoad;
  },

  SET_DIRTY(state, payLoad) {
    state.dirtyFlag = payLoad;
  },

  SET_DISCARD(state, payLoad) {
    state.discardBar = payLoad;
  },

  BACK(state, payLoad) {
    state.back = payLoad;
  },

  SET_SAVE_BAR(state, payLoad) {
    state.showSave = payLoad;
  },

  SET_ROUTE(state, payLoad) {
    state.forwardRoute = payLoad;
  }
};

// export const mutations: MutationTree<DirtyFlag> = {
//   SET_DIRTY(state, payLoad) {
//     state.dirtyFlag = payLoad;
//   }
// };
