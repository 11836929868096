
























































import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientSecret } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { HttpService } from "../../../../services/api/httpService";
import DatePicker from "@/components/DatePicker.vue";
import { AxiosResponse } from "axios";
@Component({
  components: { DatePicker },
  mixins: [SaveMixin],
  validations: {
    clientSecret: {
      description: { maxLength: maxLength(2000) },
      type: { required, maxLength: maxLength(250) },
      value: { required, minLength: minLength(16), maxLength: maxLength(250) }
    }
  }
})
export default class ClientSecretSave extends Vue {
  clientSecret = new ClientSecret();
  httpService = new HttpService<ClientSecret>();
  backRoute = {
    name: "clientDashboard",
    id: ""
  };
  today = new Date();
  showData = false;
  async created() {
    if (this.$route.params.secretId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/secrets/${this.$route.params.secretId}`
      );

      this.clientSecret = response.data || new ClientSecret();
      this.clientSecret.value = "";
    }
    this.clientSecret.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;

    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";

      let response: AxiosResponse;
      this.clientSecret.id
        ? (response = await this.httpService.putItem(
            `clients/${this.clientSecret.clientId}/secrets/${this.clientSecret.id}`,
            this.clientSecret
          ))
        : (response = await this.httpService.postItem(
            `clients/${this.clientSecret.clientId}/secrets/`,
            this.clientSecret
          ));

      this.$notification.success("Client Secret successfully saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
