var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded p-8 shadow relative mb-8" },
    [
      _c(
        "div",
        { staticClass: "relative" },
        [
          _c(
            "div",
            { staticClass: "btn-group btn-group-positioning" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn-default",
                  attrs: {
                    tag: "button",
                    to: {
                      name: "clientIdPRestrictionAdd",
                      params: { id: _vm.clientId }
                    }
                  }
                },
                [
                  _c("div", { staticClass: "sm:flex flex-col md:flex-row" }, [
                    _c("p", [_vm._v("Add")])
                  ])
                ]
              )
            ],
            1
          ),
          _c("h1", [_vm._v("ID P Restrictions")]),
          _vm.responseArray.length
            ? _c("table-component", {
                attrs: {
                  primaryKey: "id",
                  responseArray: _vm.responseArray,
                  columns: [{ name: "provider", label: "Provider" }]
                },
                on: {
                  remove: function($event) {
                    return _vm.confirmRemove($event)
                  },
                  edit: function($event) {
                    return _vm.edit($event)
                  }
                }
              })
            : _c("div", [
                _vm._v(
                  "\n      There are no Id P restrictions associated with this client\n    "
                )
              ])
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v("\n      Are you sure you want to delete\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.removeData.provider) + "?")])
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.remove(_vm.removeData)
                }
              }
            },
            [_vm._v("\n      Delete\n    ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }