import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { form } from "./modules/formBuilder/index";
import { toggleMenu } from "./modules/toggleMenu/index";
import { saveBar } from "./modules/saveBar/index";
import { role } from "./modules/role/index";
import { uploads } from "./modules/uploads/index";
import { applicationAnswerDeleteArray } from "./modules/applicationAnswer/index";
Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    toggleMenu,
    form,
    saveBar,
    role,
    applicationAnswerDeleteArray,
    uploads
  }
};

export default new Vuex.Store<RootState>(store);
