var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("statistics-chart", {
    class: this.chartHeight,
    attrs: {
      direction: _vm.direction,
      "x-data": _vm.xData,
      "chart-info": _vm.chartInfo,
      "chart-type": _vm.chartType,
      "y-data": _vm.yData,
      chartName: _vm.chartName,
      chartFill: _vm.chartFill,
      chartBackgroundColor: _vm.chartBackgroundColor,
      chartBorderColor: _vm.chartBorderColor,
      maintainAspectRatio: _vm.maintainAspectRatio
    },
    on: { click: _vm.navigateToStatisticsPage }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }