






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import axios, { AxiosError } from "axios";
import { ClientCorsOrigin } from "@/types/client";
import { CreateElement } from "vue";

@Component
export default class DashboardClientCorsOrigin extends Vue {
  corsOriginId: string = "";
  httpService = new HttpService<ClientCorsOrigin>();
  responseArray = new Array<ClientCorsOrigin>();
  showModal: boolean = false;
  clientId: any = "";
  removeData = new ClientCorsOrigin();
  async getList() {
    let httpService = new HttpService<Array<ClientCorsOrigin>>();
    const response = await httpService.getList(
      `clients/${this.clientId}/cors-origins`
    );
    this.responseArray = response.data;
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.clientId = this.$route.params.id;
    this.getList();
  }

  edit(corsOrigin: ClientCorsOrigin) {
    this.$router.push({
      name: "clientCorsOriginEdit",
      params: {
        id: this.clientId,
        corsOriginId: corsOrigin.id
      }
    });
  }

  confirmRemove(data: ClientCorsOrigin) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: ClientCorsOrigin) {
    const response = await this.httpService.deleteItem(
      `clients/${data.clientId}/cors-origins`,
      data.id
    );

    this.responseArray = this.responseArray.filter(
      (item: ClientCorsOrigin) => item.clientId != data.clientId,
      this.$notification.success(`${data.origin} successfully removed!`)
    );

    this.showModal = false;
    this.getList();
  }
}
