






















































import { Component, Vue, Watch } from "vue-property-decorator";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { CommunicationType } from "@/types/communicationType";
import { Communication } from "@/types/communication";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";

@Component({
  validations: {
    communication: {
      communicationName: { required, maxLength: maxLength(100) },
      comment: { maxLength: maxLength(100) },
      communicationTypeId: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class CommunicationEdit extends Vue {
  communication: Communication = new Communication();
  backRoute = {
    name: "communicationsList"
  };
  id: string | number = "";
  httpServiceCommunication = new HttpService<Communication>();

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getCommunication();
  }

  getCommunication() {
    this.httpServiceCommunication
      .getItem(`communications/${this.id}`)
      .then(response => {
        this.communication = response.data;
      });
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpServiceCommunication.putItem(
        `communications/${this.id}`,
        this.communication
      );
      this.$router.push({ name: "communicationsList" });
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
