
















import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { Validation } from "vuelidate";
import InputValidation from "@/components/inputs/InputValidation.vue";

@Component({
  components: {
    InputValidation
  }
})
export default class InputComponent extends Vue {
  @Model("input") value!: string | number;

  @Prop() validationObj!: Validation;
  @Prop() placeholder!: string;
  @Prop({
    default: "text",
    validator: (value: string) => {
      return ["text", "number", "email", "password"].includes(value);
    }
  })
  type!: string;

  @Prop({ default: false }) disabled!: boolean;

  input(event: KeyboardEvent) {
    this.$emit("input", (event.target as HTMLInputElement).value);
  }

  get listeners() {
    const { input, ...listeners } = this.$listeners;
    return listeners;
  }
}
