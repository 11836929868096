var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Edit User")]),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [_vm._v("User:")]),
                _c("div", { staticClass: "main-input" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.organisationAccount.account.email.$model,
                        expression:
                          "$v.organisationAccount.account.email.$model"
                      }
                    ],
                    staticClass:
                      "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                    attrs: { placeholder: "Email", disabled: "true" },
                    domProps: {
                      value: _vm.$v.organisationAccount.account.email.$model
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.organisationAccount.account.email,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm.$v.organisationAccount.account.email.$error
                    ? _c(
                        "div",
                        { staticClass: "text-red-400 text-xs italic" },
                        [
                          !_vm.$v.organisationAccount.account.email.required
                            ? _c("p", [
                                _vm._v(
                                  "Please select an account to add to the Organisation"
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [_vm._v("Owner:")]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("checkbox-component", {
                      model: {
                        value: _vm.$v.organisationAccount.owner.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.organisationAccount.owner,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.organisationAccount.owner.$model"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }