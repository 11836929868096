import ApplicationPage from "@/views/application/ApplicationPage.vue";
import ApplicationEdit from "@/views/application/ApplicationEdit.vue";
import ApplicationAdd from "@/views/application/ApplicationAdd.vue";
import ApplicationList from "@/views/application/ApplicationList.vue";
import ApplicationCommunicationAdd from "@/views/application/applicationCommunication/ApplicationCommunicationAdd.vue";
import ApplicationCommunicationEdit from "@/views/application/applicationCommunication/ApplicationCommunicationEdit.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const applicationRoutes = {
  path: "/applications",
  component: ApplicationPage,
  children: [
    {
      path: "",
      component: ApplicationList,
      name: "applicationsList"
    },
    {
      path: "edit/:id",
      name: "applicationsEdit",
      component: ApplicationEdit
    },
    {
      path: "add",
      name: "applicationsAdd",
      component: ApplicationAdd
    },

    {
      path: "/:id/addcommunication",
      name: "applicationCommunicationAdd",
      component: ApplicationCommunicationAdd
    },

    {
      path: "/:id/communications/:id2",
      name: "applicationCommunicationEdit",
      component: ApplicationCommunicationEdit
    }
  ],
  beforeEnter: roleGuard
};
