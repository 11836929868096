var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value != null
    ? _c("font-awesome-icon", {
        class: _vm.value ? "awesome-tick" : "awesome-cross",
        attrs: { icon: _vm.value ? "check" : "times" }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }