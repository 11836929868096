var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showData
    ? _c("div", { staticClass: "loader" })
    : _c(
        "save-bar",
        { attrs: { showBack: false } },
        [
          _c("h1", [_vm._v("Single Page Application Client Setup")]),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Client Name:\n      ")
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.client.clientName,
                        placeholder: "Client name"
                      },
                      model: {
                        value: _vm.$v.client.clientName.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.clientName, "$model", $$v)
                        },
                        expression: "$v.client.clientName.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Client Id\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.client.clientId,
                        placeholder: "Client ID"
                      },
                      model: {
                        value: _vm.$v.client.clientId.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.clientId, "$model", $$v)
                        },
                        expression: "$v.client.clientId.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("Description:")
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("text-area", {
                      attrs: {
                        validationObj: _vm.$v.client.description,
                        placeholder: "Description"
                      },
                      model: {
                        value: _vm.$v.client.description.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.description, "$model", $$v)
                        },
                        expression: "$v.client.description.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Client URI\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.client.clientUri,
                        placeholder: "client URI"
                      },
                      model: {
                        value: _vm.$v.client.clientUri.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.clientUri, "$model", $$v)
                        },
                        expression: "$v.client.clientUri.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("Logo URI:")
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        placeholder: "Logo URI",
                        validationObj: _vm.$v.client.logoUri
                      },
                      model: {
                        value: _vm.$v.client.logoUri.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.logoUri, "$model", $$v)
                        },
                        expression: "$v.client.logoUri.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("Application:")
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("dropdown-list", {
                      staticClass: "dropdown-list",
                      attrs: {
                        data: _vm.applications,
                        accessValueField: "applicationId",
                        selectedValue: _vm.client.applicationId
                      },
                      on: {
                        selected: function($event) {
                          _vm.client.applicationId = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return _c("li", {}, [
                              _vm._v(_vm._s(item.applicationName))
                            ])
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Redirect URI:\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        placeholder: "Redirect URI",
                        type: "text",
                        validationObj:
                          _vm.$v.client.redirectUris.$each.$iter[0].redirectUri
                      },
                      model: {
                        value:
                          _vm.$v.client.redirectUris.$each.$iter[0].redirectUri
                            .$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.client.redirectUris.$each.$iter[0]
                              .redirectUri,
                            "$model",
                            $$v
                          )
                        },
                        expression:
                          "$v.client.redirectUris.$each.$iter[0].redirectUri.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Post Logout Redirect URI:\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        placeholder: "Post Logout Redirect URI",
                        type: "text",
                        validationObj:
                          _vm.$v.client.postLogoutRedirectUri.$each.$iter[0]
                            .postLogoutRedirectUri
                      },
                      model: {
                        value:
                          _vm.$v.client.postLogoutRedirectUri.$each.$iter[0]
                            .postLogoutRedirectUri.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.client.postLogoutRedirectUri.$each.$iter[0]
                              .postLogoutRedirectUri,
                            "$model",
                            $$v
                          )
                        },
                        expression:
                          "\n            $v.client.postLogoutRedirectUri.$each.$iter[0]\n              .postLogoutRedirectUri.$model\n          "
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Cors Origin:\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        placeholder: "Cors Origin",
                        type: "text",
                        validationObj:
                          _vm.$v.client.corsOrigins.$each.$iter[0].origin
                      },
                      model: {
                        value:
                          _vm.$v.client.corsOrigins.$each.$iter[0].origin
                            .$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.client.corsOrigins.$each.$iter[0].origin,
                            "$model",
                            $$v
                          )
                        },
                        expression:
                          "$v.client.corsOrigins.$each.$iter[0].origin.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [_vm._v("Scopes")]),
                _c(
                  "ul",
                  { staticClass: "w-full" },
                  [
                    _c("input-component", {
                      staticClass: "w-full",
                      attrs: { disabled: "true", type: "text", value: "email" }
                    }),
                    _c("input-component", {
                      attrs: { disabled: "true", type: "text", value: "openid" }
                    }),
                    _c("input-component", {
                      attrs: {
                        disabled: "true",
                        type: "text",
                        value: "profile"
                      }
                    }),
                    _vm._l(_vm.client.scopes, function(scope, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "flex flex-row" },
                        [
                          _c("input-component", {
                            staticClass: "w-9/10",
                            attrs: { disabled: "" },
                            model: {
                              value: scope.scope,
                              callback: function($$v) {
                                _vm.$set(scope, "scope", $$v)
                              },
                              expression: "scope.scope"
                            }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "bg-red-600 w-1/10",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeClientScope(
                                    scope.scope,
                                    index
                                  )
                                }
                              }
                            },
                            [_vm._v("\n            Remove\n          ")]
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ]),
              _vm.scopes.length
                ? _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Add more")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input h-full flex flex-row w-full" },
                      [
                        _c("dropdown-list", {
                          staticClass: "dropdown-list w-9/10",
                          attrs: {
                            data: _vm.scopes,
                            accessValueField: "name",
                            allowNull: true
                          },
                          on: {
                            selected: function($event) {
                              _vm.newClientScope.scope = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item.name))]
                                }
                              }
                            ],
                            null,
                            false,
                            3225635320
                          )
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-1/10 bg-green-100 hover:bg-green-200 text-white",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addClientScope($event)
                              }
                            }
                          },
                          [_vm._v("\n          Add\n        ")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c("portal", { attrs: { to: "BackButtonOverride" } }, [
            _c(
              "button",
              {
                staticClass: "btn-secondary btn-width-md md:btn-width-lg mr-33",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("\n      Back\n    ")]
            )
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }