var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border border-5 py-2 my-2" },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-row items-center relative justify-between",
          on: {
            blur: function($event) {
              _vm.showQuestionIcons = false
            }
          }
        },
        [
          _vm._t("default"),
          _c("h3", [
            _c("strong", { staticClass: "hidden md:inline-block" }, [
              _vm._v("Name:")
            ]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.groupQuestion.alternativeName
                    ? _vm.groupQuestion.alternativeName
                    : _vm.groupQuestion.question.name
                ) +
                "\n    "
            )
          ]),
          _c(
            "div",
            { staticClass: "ml-auto flex cursor-pointer" },
            [
              _c("font-awesome-icon", {
                staticClass: "mx-1",
                attrs: { icon: "cog" },
                on: {
                  click: function($event) {
                    return _vm.configureQuestion()
                  }
                }
              }),
              _c("font-awesome-icon", {
                staticClass: "mx-1 hidden sm:block",
                attrs: { icon: "trash" },
                on: {
                  click: function($event) {
                    _vm.showModal = true
                  }
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "portal",
        { attrs: { to: "formEdit" } },
        [
          _c(
            "transition",
            { attrs: { name: "slide-right" } },
            [
              _vm.showConfigureQuestion
                ? _c(
                    "side-modal",
                    {
                      on: {
                        close: function($event) {
                          _vm.showConfigureQuestion = false
                        }
                      }
                    },
                    [
                      _c("configure-question", {
                        attrs: {
                          groupQuestion: _vm.groupQuestion,
                          questionIndex: _vm.questionOrder,
                          groupIndex: _vm.groupIndex
                        },
                        on: {
                          close: function($event) {
                            _vm.showConfigureQuestion = false
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "portal",
        { attrs: { to: "formEdit" } },
        [
          _c(
            "modal",
            {
              attrs: { showModal: _vm.showModal },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("h2", [_vm._v("Delete")]),
              _c("p", [
                _vm._v("\n        Are you sure you want to remove\n        "),
                _c("strong", [_vm._v(_vm._s(_vm.groupQuestion.question.name))]),
                _vm._v(" from the group?\n      ")
              ]),
              _c(
                "button",
                {
                  staticClass: "modal-button block",
                  on: {
                    click: function($event) {
                      return _vm.removeQuestion()
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }