var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.totalPages > 1
      ? _c(
          "ul",
          { staticClass: "flex mt-8" },
          [
            _c("li", { staticClass: "inline-flex ml-auto" }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-center align-middle whitespace-no-wrap select-none border py-2 px-3 border-gray-50 rounded-l text-sm",
                  attrs: {
                    type: "button",
                    disabled: _vm.isInFirstPage,
                    "aria-label": "Go to first page"
                  },
                  on: { click: _vm.onClickFirstPage }
                },
                [_vm._v("≪")]
              )
            ]),
            _c("li", { staticClass: "inline-flex" }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-center align-middle whitespace-no-wrap select-none border py-2 px-3 border-gray-50 text-sm",
                  attrs: {
                    type: "button",
                    disabled: _vm.isInFirstPage,
                    "aria-label": "Go to previous page"
                  },
                  on: { click: _vm.onClickPreviousPage }
                },
                [_vm._v("<")]
              )
            ]),
            _vm._l(_vm.pages, function(page) {
              return _c("li", { key: page.name, staticClass: "inline-flex" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "text-center align-middle whitespace-no-wrap select-none border py-2 px-3 border-gray-50 font-sm",
                    class: { active: _vm.isPageActive(page.name) },
                    attrs: {
                      type: "button",
                      disabled: page.isDisabled,
                      "aria-label": "Go to page number " + page.name
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClickPage(page.name)
                      }
                    }
                  },
                  [_vm._v(_vm._s(page.name))]
                )
              ])
            }),
            _c("li", { staticClass: "inline-flex" }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-center align-middle whitespace-no-wrap select-none border py-2 px-3 border-gray-50 text-sm",
                  attrs: {
                    type: "button",
                    disabled: _vm.isInLastPage,
                    "aria-label": "Go to next page"
                  },
                  on: { click: _vm.onClickNextPage }
                },
                [_vm._v(">")]
              )
            ]),
            _c("li", { staticClass: "inline-flex" }, [
              _c(
                "button",
                {
                  staticClass:
                    "text-center align-middle whitespace-no-wrap select-none border py-2 px-3 border-gray-50 text-sm rounded-r",
                  attrs: {
                    type: "button",
                    disabled: _vm.isInLastPage,
                    "aria-label": "Go to last page"
                  },
                  on: { click: _vm.onClickLastPage }
                },
                [_vm._v("≫")]
              )
            ])
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }