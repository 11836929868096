var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [_vm._v("Add subscription package")]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Subscription name:")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("input-component", {
              attrs: {
                validationObj:
                  _vm.$v.subscriptionPackage.subscriptionPackageName
              },
              model: {
                value:
                  _vm.$v.subscriptionPackage.subscriptionPackageName.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.subscriptionPackage.subscriptionPackageName,
                    "$model",
                    $$v
                  )
                },
                expression:
                  "$v.subscriptionPackage.subscriptionPackageName.$model"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "input-container" }, [
      _c("div", { staticClass: "input-label" }, [
        _vm._v("Subscription short code:")
      ]),
      _c(
        "div",
        { staticClass: "main-input" },
        [
          _c("input-component", {
            attrs: {
              validationObj: _vm.$v.subscriptionPackage.subscriptionPackageCode
            },
            model: {
              value: _vm.$v.subscriptionPackage.subscriptionPackageCode.$model,
              callback: function($$v) {
                _vm.$set(
                  _vm.$v.subscriptionPackage.subscriptionPackageCode,
                  "$model",
                  $$v
                )
              },
              expression:
                "$v.subscriptionPackage.subscriptionPackageCode.$model"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }