var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return typeof _vm.validationObj != "undefined"
    ? _c("div", [
        _vm.validationObj.$error
          ? _c("div", { staticClass: "text-red-400 text-xs italic px-2" }, [
              !_vm.validationObj.required &&
              _vm.validationObj.hasOwnProperty("required")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v("\n      This field is required\n    ")
                  ])
                : _vm._e(),
              !_vm.validationObj.maxLength &&
              _vm.validationObj.hasOwnProperty("maxLength")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Maximum length is " +
                        _vm._s(_vm.validationObj.$params.maxLength.max) +
                        " characters\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.minLength &&
              _vm.validationObj.hasOwnProperty("minLength")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Minimum length is " +
                        _vm._s(_vm.validationObj.$params.minLength.min) +
                        " characters\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.email &&
              _vm.validationObj.hasOwnProperty("email")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v("\n      Incorrect emial format\n    ")
                  ])
                : _vm._e(),
              !_vm.validationObj.numeric &&
              _vm.validationObj.hasOwnProperty("numeric")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      The input should be a positive number\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.minValue &&
              _vm.validationObj.hasOwnProperty("minValue")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Minimum value is " +
                        _vm._s(_vm.validationObj.$params.minValue.min) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.maxValue &&
              _vm.validationObj.hasOwnProperty("maxValue")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Maximum value is " +
                        _vm._s(_vm.validationObj.$params.maxValue.max) +
                        " characters\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.containNonAlphaNum &&
              _vm.validationObj.hasOwnProperty("containNonAlphaNum")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Must have at least one non alphanumeric character\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.containNum &&
              _vm.validationObj.hasOwnProperty("containNum")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Must have at least one digit ('0'-'9')\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.containUpperCaseLettter &&
              _vm.validationObj.hasOwnProperty("containUpperCaseLettter")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Must have at least one uppercase ('A'-'Z')\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.containLowerCaseLettter &&
              _vm.validationObj.hasOwnProperty("containLowerCaseLettter")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Must have at least one lowercase ('a'-'z')\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.sameAsPassword &&
              _vm.validationObj.hasOwnProperty("sameAsPassword")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v("\n      Must must be the same as password\n    ")
                  ])
                : _vm._e(),
              !_vm.validationObj.domain &&
              _vm.validationObj.hasOwnProperty("domain")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Please insert a valid domain name e.g example.com\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.isUrl &&
              _vm.validationObj.hasOwnProperty("isUrl")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Please insert a valid url beginning with http:// or https://\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.isPhone &&
              _vm.validationObj.hasOwnProperty("isPhone")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v(
                      "\n      Please insert a valid telephone number\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.validationObj.isPostCode &&
              _vm.validationObj.hasOwnProperty("isPostCode")
                ? _c("p", { staticClass: "my-1" }, [
                    _vm._v("\n      Please insert a valid post code\n    ")
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }