


















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Entitlement } from "@/types/entitlement";

@Component
export default class EntitlementList extends Vue {
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<Entitlement>>();
  httpService = new HttpService<Entitlement>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  total = 50;
  responseArray: Array<Entitlement> = new Array<Entitlement>();
  showModal: boolean = false;
  removeData: Entitlement = new Entitlement();

  getList() {
    this.httpServiceArray
      .getListSearch(
        "entitlements",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          //   this.total = JSON.parse(response.headers["x-pagination"]).totalCount; //TODO we need the totalCount to be brought back in the header
        } else this.responseArray = new Array<Entitlement>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "entitlementsAdd"
    });
  }
  confirmRemove(data: Entitlement) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: Entitlement) {
    await this.httpService
      .deleteItem("entitlements", data.entitlementId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: Entitlement) => item.entitlementId != data.entitlementId,
          this.$notification.success(
            `${data.entitlementName} successfully removed!`
          )
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: Entitlement) {
    this.$router.push({
      name: `entitlementsEdit`,
      params: { id: data.entitlementId }
    });
  }
}
