import { RootState } from "../../types";
import { GetForm } from "./types";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";
import { FullForm } from "@/types/fullForm";
import { Question } from "@/types/question";

export const state: GetForm = {
  form: new FullForm(),
  formDelete: {
    questions: [],
    groups: []
  },
  questionsList: new Array<Question>(),
  sideModalSave: false
};

export const form: Module<GetForm, RootState> = {
  state,
  mutations,
  actions,
  getters
};
