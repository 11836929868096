


















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";

@Component
export default class SubscriptionPackageItemList extends Vue {
  total = 100;
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<SubscriptionPackageItem>>();
  httpService = new HttpService<SubscriptionPackageItem>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<SubscriptionPackageItem> = new Array<
    SubscriptionPackageItem
  >();
  showModal: boolean = false;
  removeData: SubscriptionPackageItem = new SubscriptionPackageItem();

  getList() {
    this.httpServiceArray
      .getListSearch(
        "packages",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          // this.total = JSON.parse(response.headers["x-pagination"]).totalCount; //TODO when total count is brought back in the headers we should impletment this.
        } else this.responseArray = new Array<SubscriptionPackageItem>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "packagesAdd"
    });
  }
  confirmRemove(data: SubscriptionPackageItem) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: SubscriptionPackageItem) {
    await this.httpService
      .deleteItem("packages", data.subscriptionPackageId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: SubscriptionPackageItem) =>
            item.subscriptionPackageId != data.subscriptionPackageId,
          this.$notification.success(
            `${data.subscriptionPackageName} successfully removed!`
          )
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: SubscriptionPackageItem) {
    this.$router.push({
      name: `packagesEdit`,
      params: { id: data.subscriptionPackageId }
    });
  }
}
