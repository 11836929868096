var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-white rounded p-8 shadow relative mb-8" },
    [
      _c("div", { staticClass: "relative" }, [
        _c(
          "div",
          { staticClass: "btn-group btn-group-positioning" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-default",
                attrs: {
                  tag: "button",
                  to: { name: "accountsEdit", params: { id: _vm.accountid } }
                }
              },
              [_vm._v("Edit account")]
            )
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "flex -mx-4 flex-wrap" }, [
          _c(
            "div",
            { staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Name:")
                ]),
                _c("label", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.account.fullName))
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Job title:")
                ]),
                _c("label", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.account.jobTitle))
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Email:")
                ]),
                _c("label", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.account.email))
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Account type:")
                ]),
                _c("label", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.accountTypeName))
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4" },
            [
              _c("div", { staticClass: "mb-4" }, [
                _c("label", { staticClass: "block font-bold" }, [
                  _vm._v("Phone number:")
                ]),
                _c("label", { staticClass: "block" }, [
                  _vm._v(_vm._s(_vm.account.phoneNumber))
                ])
              ])
            ]
          ),
          _vm.account.accountAddress
            ? _c(
                "div",
                {
                  staticClass: "xl:w-1/3 lg:w-1/2 md:w-1/3 sm:w-1/2 w-full px-4"
                },
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _c("label", { staticClass: "block font-bold" }, [
                      _vm._v("Company name:")
                    ]),
                    _c("label", { staticClass: "block" }, [
                      _vm._v(_vm._s(_vm.account.accountAddress.companyName))
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]),
        _vm.account.accountAddress
          ? _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Address:")
              ]),
              _c("label", { staticClass: "block" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.account.accountAddress.address1) +
                    ",\n        "
                ),
                _vm.account.accountAddress.address2 !== null
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.account.accountAddress.address2) + ",")
                    ])
                  : _vm._e(),
                _vm.account.accountAddress.address3 !== null
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.account.accountAddress.address3) + ",")
                    ])
                  : _vm._e(),
                _vm.account.accountAddress.town !== null
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.account.accountAddress.town) + ",")
                    ])
                  : _vm._e(),
                _vm.account.accountAddress.county !== null
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.account.accountAddress.county) + ",")
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.account.accountAddress.postCode) +
                    ", " +
                    _vm._s(_vm.account.accountAddress.countryName) +
                    "\n      "
                )
              ])
            ])
          : _vm._e(),
        _c("h2", { staticClass: "mt-12" }, [
          _vm._v("Communication preferences")
        ]),
        _c("div", { staticClass: "table-container" }, [
          _c("table", { staticClass: "w-full table" }, [
            _vm._m(1),
            _vm._m(2),
            _c(
              "tr",
              {
                staticClass: "table-tr transition",
                on: {
                  click: function($event) {
                    return _vm.edit()
                  }
                }
              },
              [
                _c(
                  "td",
                  { staticClass: "table-data-left" },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutTelephone }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "table-data-left" },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutEmail }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass:
                      "px-4 py-4 border border-gray-25 text-center cursor-pointer",
                    attrs: { "data-v-65d96d34": "" }
                  },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutMail }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-data-left",
                    attrs: { "data-v-65d96d34": "" }
                  },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutThirdPartyTelephone }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-data-left",
                    attrs: { "data-v-65d96d34": "" }
                  },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutThirdPartyEmail }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  {
                    staticClass: "table-data-left",
                    attrs: { "data-v-65d96d34": "" }
                  },
                  [
                    _c("tick-cross", {
                      attrs: { value: _vm.account.optOutThirdPartyMail }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sm:flex flex-col text-xl md:flex-row py-4" },
      [_c("p", [_vm._v("Account  ")]), _c("p", [_vm._v("information")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "th",
        {
          staticClass:
            "px-4 py-4 font-medium border border-gray-25 text-center w-1/2",
          attrs: { colspan: "3" }
        },
        [_vm._v("Group communication preferences")]
      ),
      _c(
        "th",
        {
          staticClass:
            "px-4 py-4 font-medium border border-gray-25 text-center w-1/2",
          attrs: { colspan: "3" }
        },
        [_vm._v("Third party communication preferences")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Telephone")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Email")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Post")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Telephone")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Email")]),
      _c("th", { staticClass: "table-header-center" }, [_vm._v("Post")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }