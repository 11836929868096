


















import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { Validation } from "vuelidate";
import InputValidation from "@/components/inputs/InputValidation.vue";

@Component({
  components: {
    InputValidation
  }
})
export default class CheckboxComponent extends Vue {
  @Model("change") checked!: boolean;
  @Prop() validationObj!: Validation;
  @Prop() label!: string;

  change() {
    this.$emit("change", !this.checked);
  }

  @Prop({ default: false }) disabled!: boolean;

  get listeners() {
    const { change, ...listeners } = this.$listeners;
    return listeners;
  }
}
