import { GetterTree } from "vuex";

import { GetForm } from "./types";
import { RootState } from "../../types";
export const getters: GetterTree<GetForm, RootState> = {
  getForm(state) {
    return state.form;
  },

  getQuestions(state) {
    return state.questionsList;
  },

  getSideModalSave(state) {
    return state.sideModalSave;
  }
};
