var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedValueDirty,
            expression: "selectedValueDirty"
          }
        ],
        staticClass: "w-full",
        on: {
          click: _vm.onSelect,
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.selectedValueDirty = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      [
        _c(
          "option",
          { attrs: { disabled: !_vm.allowNull }, domProps: { value: null } },
          [_vm._v("None")]
        ),
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "option",
            { key: index, domProps: { value: item[_vm.accessValueField] } },
            [_vm._t("default", null, { item: item })],
            2
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }