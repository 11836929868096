var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "save-bar",
    [
      !_vm.showData
        ? _c("div", { staticClass: "loader" })
        : [
            _c("h1", [_vm._v("Add an organisation")]),
            _vm.filteredOrganisations.length
              ? _c("form", { staticClass: "w-full" }, [
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("\n          Organisation:\n          "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("dropdown-list", {
                          staticClass: "dropdown-list",
                          attrs: {
                            data: _vm.filteredOrganisations,
                            accessValueField: "organisationId",
                            allowNull: false
                          },
                          on: {
                            selected: function($event) {
                              _vm.$v.organisationId.$model = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item.organisationName))]
                                }
                              }
                            ],
                            null,
                            false,
                            546978986
                          )
                        }),
                        _vm.$v.organisationId.$error
                          ? _c(
                              "div",
                              { staticClass: "text-red-400 text-xs italic" },
                              [
                                !_vm.$v.organisationId.required
                                  ? _c("p", [
                                      _vm._v("Please select an organisation")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Owner:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("checkbox-component", {
                          model: {
                            value: _vm.$v.owner.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.owner, "$model", $$v)
                            },
                            expression: "$v.owner.$model"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _c("h3", [
                  _vm._v(
                    "The account holder cannot be added to any other organisations"
                  )
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }