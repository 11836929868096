





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import OrganisationDashboardInformation from "@/views/organisations/organisationDashboard/OrganisationDashboardInformation.vue";
import OrganisationDashboardIndividuals from "@/views/organisations/organisationDashboard/OrganisationDashboardIndividuals.vue";
import OrganisationDashboardSelfRegistration from "@/views/organisations/organisationDashboard/OrganisationDashboardSelfRegistration.vue";
import OrganisationDashboardSubscriptions from "@/views/organisations/organisationDashboard/OrganisationDashboardSubscriptions.vue";
import OrganisationDashboardApplications from "@/views/organisations/organisationDashboard/OrganisationDashboardApplications.vue";

@Component({
  components: {
    OrganisationDashboardSelfRegistration,
    OrganisationDashboardIndividuals,
    OrganisationDashboardInformation,
    OrganisationDashboardSubscriptions,
    OrganisationDashboardApplications
  }
})
export default class OrganisationDashboard extends Vue {
  information = false;
  selfReg = false;
  subscriptions = false;
  applications = false;
  individuals = false;

  get showData() {
    if (
      this.information &&
      this.selfReg &&
      this.subscriptions &&
      this.individuals &&
      this.applications
    )
      return true;
    else return false;
  }
}
