





























import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountCommunication } from "../../../../types/accountCommunication";

@Component({
  validations: {
    accountCommunications: {}
  }
})
export default class AccountApplicationCommunication extends Vue {
  @Prop() accountCommunications!: Array<AccountCommunication>;
  @Prop() name!: string;
}
