










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AccountPassword } from "@/types/accountPassword";
import { HttpService } from "@/services/api/httpService";
import { AccountAddress } from "@/types/accountAddress";
import InputValidation from "@/components/inputs/InputValidation.vue";

import {
  containNonAlphaNum,
  containNum,
  containUpperCaseLettter,
  containLowerCaseLettter
} from "@/services/customValidator/customValidators";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless,
  sameAs,
  and,
  alphaNum,
  or
} from "vuelidate/lib/validators";
import AccountAdd from "../AccountAdd.vue";

@Component({
  components: {
    InputValidation
  },
  validations: {
    password: {
      required,
      containNonAlphaNum,
      containNum,
      minLength: minLength(8),
      containUpperCaseLettter,
      containLowerCaseLettter
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
      required
    }
  }
})
export default class Password extends Vue {
  id: string = "";
  password: string = "";
  repeatPassword: string = "";
  httpService: HttpService<any> = new HttpService<any>();

  showPassword: boolean = false;

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
  }

  save() {
    this.$v.$touch();

    console;
    if (!this.$v.$invalid) {
      this.httpService
        .postItem(
          `accounts/${this.id}/setpassword`,
          new AccountPassword(this.password)
        )
        .then(() => {
          this.$notification.success("Password successfully updated");
        });
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  generetePassword() {
    this.httpService.getItem("passwords").then(res => {
      this.password = res.data.password;
      this.repeatPassword = res.data.password;
    });

    this.showPassword = true;
  }
}
