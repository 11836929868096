var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Add a subscription")]),
          _vm.filteredSubscriptions.length
            ? _c(
                "form",
                {
                  staticClass: "w-full",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("\n          Subscription:\n          "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("dropdown-list", {
                          attrs: {
                            data: _vm.filteredSubscriptions,
                            accessValueField: "subscriptionPackageId",
                            allowNull: false
                          },
                          on: {
                            selected: function($event) {
                              _vm.$v.organisationSubscription.subscriptionPackageId.$model = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(_vm._s(item.subscriptionPackageName))
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1281236929
                          )
                        }),
                        _vm.$v.organisationSubscription.subscriptionPackageId
                          .$error
                          ? _c(
                              "div",
                              { staticClass: "text-red-400 text-xs italic" },
                              [
                                !_vm.$v.organisationSubscription
                                  .subscriptionPackageId.required
                                  ? _c("p", [
                                      _vm._v(
                                        "Please select a Subscription Package"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Trial:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("checkbox-component", {
                          model: {
                            value: _vm.$v.organisationSubscription.trial.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.organisationSubscription.trial,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.organisationSubscription.trial.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Perpetual:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("checkbox-component", {
                          model: {
                            value:
                              _vm.$v.organisationSubscription.perpetual.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.organisationSubscription.perpetual,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.organisationSubscription.perpetual.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Suspended:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("checkbox-component", {
                          model: {
                            value:
                              _vm.$v.organisationSubscription.suspended.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.organisationSubscription.suspended,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.organisationSubscription.suspended.$model"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("\n          Start Date:\n          "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("div", { staticClass: "main-input" }, [
                      _c(
                        "label",
                        { staticClass: "cursor-pointer" },
                        [
                          _c("date-picker", {
                            staticClass:
                              "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                            class: _vm.$validationStatus.status(
                              _vm.$v.organisationSubscription.startDate
                            ),
                            attrs: { minDate: _vm.today },
                            model: {
                              value:
                                _vm.$v.organisationSubscription.startDate
                                  .$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.organisationSubscription.startDate,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.organisationSubscription.startDate.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.$v.organisationSubscription.startDate.$error
                        ? _c(
                            "div",
                            { staticClass: "text-red-400 text-xs italic" },
                            [
                              !_vm.$v.organisationSubscription.startDate
                                .required
                                ? _c("p", [
                                    _vm._v("Please select a start date")
                                  ])
                                : _vm._e(),
                              !_vm.$v.organisationSubscription.startDate
                                .dateGreaterThanToday
                                ? _c("p", [
                                    _vm._v(
                                      "The date must not be less than today"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("End Date:")
                    ]),
                    _c("div", { staticClass: "main-input" }, [
                      _c(
                        "label",
                        { staticClass: "cursor-pointer" },
                        [
                          _c("date-picker", {
                            staticClass:
                              "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                            class: _vm.$validationStatus.status(
                              _vm.$v.organisationSubscription.endDate
                            ),
                            attrs: { minDate: _vm.today },
                            model: {
                              value:
                                _vm.$v.organisationSubscription.endDate.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.organisationSubscription.endDate,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.organisationSubscription.endDate.$model"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.$v.organisationSubscription.endDate.$invalid
                        ? _c(
                            "div",
                            { staticClass: "text-red-400 text-xs italic" },
                            [
                              !_vm.$v.organisationSubscription.endDate
                                .endDateGreaterThanStartDate
                                ? _c("p", [
                                    _vm._v(
                                      "The End Date must not be less than the Start Date"
                                    )
                                  ])
                                : _vm._e(),
                              !_vm.$v.organisationSubscription.endDate
                                .dateGreaterThanToday
                                ? _c("p", [
                                    _vm._v(
                                      "The date must not be less than today"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("Notes:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input" },
                      [
                        _c("text-area", {
                          attrs: {
                            rows: "5",
                            cols: "50",
                            validationObj: _vm.$v.organisationSubscription.notes
                          },
                          model: {
                            value: _vm.$v.organisationSubscription.notes.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.organisationSubscription.notes,
                                "$model",
                                $$v
                              )
                            },
                            expression:
                              "$v.organisationSubscription.notes.$model"
                          }
                        }),
                        _vm.$v.organisationSubscription.notes.$invalid
                          ? _c(
                              "div",
                              { staticClass: "text-red-400 text-xs italic" },
                              [
                                !_vm.$v.organisationSubscription.notes.maxLength
                                  ? _c("p", [
                                      _vm._v(
                                        "Maximum length is " +
                                          _vm._s(
                                            _vm.$v.organisationSubscription
                                              .notes.$params.maxLength.max
                                          ) +
                                          " characters"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            : _c("h3", [
                _vm._v(
                  "No other subscriptions can be assigned to this organisation."
                )
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }