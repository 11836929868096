

































import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { QuestionValue } from "@/types/questionValue";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength, numeric } from "vuelidate/lib/validators";

@Component({
  validations: {
    questionValue: {
      name: { required, maxLength: maxLength(300) },
      sortOrder: { required, numeric }
    }
  },
  mixins: [SaveMixin]
})
export default class QuestionValueAdd extends Vue {
  questionValue: QuestionValue = new QuestionValue();
  questionId: string = "";
  backRoute = {
    name: "questionsEdit",
    params: { id: "" }
  };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.questionId = this.$route.params.id;
    this.backRoute.params.id = this.questionId;
  }

  async save() {
    this.questionValue.questionValueId = Number(this.questionId);
    let httpService = new HttpService<QuestionValue>();
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await httpService
        .postItem(`questions/${this.questionId}/values`, this.questionValue)
        .then(() => {
          this.$notification.success("Question answer successfully saved");
          this.$store.dispatch("SET_DIRTY", false);

          this.$router.push(this.backRoute);
        });
    }
  }
}
