
































































import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class Pagination extends Vue {
  @Prop({ required: false, default: 3 }) maxVisibleButtons!: number;
  @Prop({ required: true }) total!: number;
  @Prop({ required: true }) currentPage!: number;
  @Prop({ required: true }) perPage!: number;
  @Prop({ required: true }) response!: [];

  get totalPages() {
    return Math.ceil(this.total / this.perPage);
  }

  get computedmaxVisibleButtons() {
    if (this.totalPages > this.maxVisibleButtons) return this.maxVisibleButtons;
    else return this.totalPages;
  }
  get startPage() {
    if (this.currentPage === 1) {
      return 1;
    }

    if (this.currentPage === this.totalPages) {
      return this.totalPages - this.computedmaxVisibleButtons + 1;
    }

    return this.currentPage - 1;
  }
  get endPage() {
    return Math.min(
      this.startPage + this.computedmaxVisibleButtons - 1,
      this.totalPages
    );
  }
  get pages() {
    const range = [];

    for (let i = this.startPage; i <= this.endPage; i += 1) {
      range.push({
        name: i,
        isDisabled: i === this.currentPage
      });
    }

    return range;
  }
  get isInFirstPage() {
    return this.currentPage === 1;
  }
  get isInLastPage() {
    return this.currentPage === this.totalPages;
  }

  get paginatedData() {
    const start = this.currentPage * this.perPage - this.perPage,
      end = start + this.perPage;
    return this.response.slice(start, end);
  }

  onClickFirstPage() {
    this.$emit("pagechanged", 1);
  }
  onClickPreviousPage() {
    this.$emit("pagechanged", this.currentPage - 1);
  }
  onClickPage(page: number) {
    this.$emit("pagechanged", page);
  }
  onClickNextPage() {
    this.$emit("pagechanged", this.currentPage + 1);
  }
  onClickLastPage() {
    this.$emit("pagechanged", this.totalPages);
  }
  isPageActive(page: number) {
    return this.currentPage === page;
  }
}
