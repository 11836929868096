




































































































































































































































































































































































































import { SaveMixin } from "@/services/mixins/saveMixin";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AccountSubscriptions from "@/views/accounts/AccountSubscriptions.vue";
import { HttpService } from "@/services/api/httpService";
import { Account } from "@/types/account";
import { AccountAddress } from "@/types/accountAddress";
import AccountDashboardInformation from "@/views/accounts/accountDashboard/AccountDashboardInformation.vue";
import {
  isPhone,
  isPostCode
} from "@/services/customValidator/customValidators";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { Country } from "../../types/country";
import { AccountType } from "../../types/accountType";

@Component({
  mixins: [SaveMixin],
  components: {
    AccountDashboardInformation
  },
  validations: {
    account: {
      firstName: { required, maxLength: maxLength(50) },
      lastName: { required, maxLength: maxLength(50) },
      jobTitle: {},
      email: { email, required, maxLength: maxLength(256) },
      accountTypeId: { required },
      optOutThirdPartyMail: {},
      optOutThirdPartyEmail: {},
      optOutThirdPartyTelephone: {},
      optOutMail: {},
      optOutEmail: {},
      optOutTelephone: {},

      isActive: {},
      phoneNumber: {
        isPhone
      },

      userName: {}
    },
    address: {
      postCode: {
        isPostCode,
        required: requiredUnless(vm => {
          return (
            !vm.address1 &&
            !vm.address2 &&
            !vm.address3 &&
            !vm.companyName &&
            !vm.town &&
            !vm.county &&
            !vm.countryId
          );
        }),
        maxLength: maxLength(20)
      },
      address1: {
        required: requiredUnless(vm => {
          return (
            !vm.postCode &&
            !vm.address2 &&
            !vm.address3 &&
            !vm.companyName &&
            !vm.town &&
            !vm.county &&
            !vm.countryId
          );
        }),
        maxLength: maxLength(100)
      },
      address2: { maxLength: maxLength(100) },
      address3: { maxLength: maxLength(100) },
      town: { maxLength: maxLength(50) },
      county: { maxLength: maxLength(50) },
      countryId: {
        required: requiredUnless(vm => {
          return (
            !vm.address1 &&
            !vm.address2 &&
            !vm.address3 &&
            !vm.companyName &&
            !vm.town &&
            !vm.county &&
            !vm.postCode
          );
        })
      },
      countryName: {},

      companyName: {}
    }
  }
})
export default class AccountEdit extends Vue {
  id: string = "";
  showData = false;
  account: Account = new Account();
  accountTypes: Array<AccountType> = [];
  address: AccountAddress = new AccountAddress();
  countries = new Array<Country>();
  httpServiceAccount: HttpService<Account> = new HttpService<Account>();
  httpServiceAccountAddress: HttpService<AccountAddress> = new HttpService<
    AccountAddress
  >();
  get title() {
    return `${this.account.fullName} - Edit`;
  }
  backRoute = {
    name: "accountDashboard",
    id: ""
  };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;

    this.getData();
  }

  created() {
    this.backRoute.id = this.$route.params.id;
  }

  async getData() {
    const httpServiceCountry = new HttpService<Array<Country>>();
    const response = await this.httpServiceAccount.getItem(
      `accounts/${this.id}`
    );

    this.account = response.data;

    if (response.data.accountAddress) {
      this.address = response.data.accountAddress;
    }

    const httpService = new HttpService<Array<AccountType>>();

    const response2 = await httpService.getListPaging(
      "accountTypes",
      25,
      1,
      "accountTypeName"
    );
    const response3 = await httpServiceCountry.getListPaging(
      "countries",
      1000,
      1,
      "countryName"
    );
    this.accountTypes = response2.data;
    this.countries = response3.data;
    this.showData = true;

    document.title = `Subscriptions Admin - ${this.account.fullName}`;
  }

  async save() {
    this.account.userName = this.account.email;
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      if (this.address.accountAddressId && this.address.address1 == "") {
        await this.httpServiceAccountAddress.deleteItem(
          "address",
          this.address.accountAddressId
        );
        this.account.accountAddress = null;
      }

      await this.httpServiceAccount.putItem(
        `accounts/${this.id}`,
        this.account
      );
      if (this.address.address1 == "") {
      } else if (
        this.account.accountAddress &&
        this.account.accountAddress.accountAddressId != undefined
      ) {
        await this.httpServiceAccountAddress.putItem(
          `address/${this.account.accountAddress.accountAddressId}`,
          this.address
        );
      } else {
        (this.address.accountId = this.account.id),
          await this.httpServiceAccountAddress.postItem(
            "address",
            this.address
          );
      }

      this.$notification.success(`Account details successfully saved!`);
      this.$store.dispatch("SET_DIRTY", false);
      this.$router.push(this.backRoute);
    }
  }
}
