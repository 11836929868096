export default class NotificationItemType {
  message?: string;
  status?: string;
  startTime?: number;
  endTime?: number;

  constructor(
    message?: string,
    status?: string,
    startTime?: number,
    endTime?: number
  ) {
    (this.message = message),
      (this.status = status),
      (this.startTime = startTime);
    this.endTime = endTime;
  }
}
