
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
@Component
export default class GetJson extends Vue {
  @Prop() url!: string;
  response: {}[] = [];
  loading: boolean = true;
  async created() {
    const response = await axios.get(this.url);
    this.response = response.data;
    this.loading = false;
  }

  @Watch("url") onUrlChange(value: string, oldValue: string) {
    if (value !== oldValue) {
      axios.get(this.url).then(response => {
        this.response = response.data;
        this.loading = false;
      });
    }
  }

  render() {
    if (this.$scopedSlots.default)
      return this.$scopedSlots.default({
        response: this.response,
        loading: this.loading
      });
    else return;
  }
}
