


































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Country } from "../../types/country";
import { TitleMixin } from "../../services/mixins/titleMixin";
import { Application } from "../../types/application";
import {
  CreateClient,
  ClientScope,
  CreateWebAppClient,
  CreateMachineClient
} from "../../types/client";
import DatePicker from "@/components/DatePicker.vue";
import { HttpService } from "@/services/api/httpService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { SaveMixin } from "@/services/mixins/saveMixin";
import InputValidation from "@/components/inputs/InputValidation.vue";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";

import { ApiScope } from "../../types/apiResource";
@Component({
  components: { DatePicker, InputValidation },
  validations: {
    client: {
      clientId: { required, maxLength: maxLength(200) },
      description: { maxLength: maxLength(1000) },
      secret: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(4000)
      },
      scopes: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class ClientAddMAchine extends Vue {
  showData = false;
  apiScopes: Array<ApiScope> = [];
  client = new CreateMachineClient();
  newClientScope = new ClientScope();
  httpServiceCreateClient = new HttpService<CreateClient>();
  selectedValue: string | null = "";
  backRoute = {
    name: "clientList"
  };
  addClientScope() {
    if (this.newClientScope.scope == "") return;
    this.client.scopes.push(this.newClientScope);
    this.apiScopes.map((scope, index) => {
      if (scope.name == this.newClientScope.scope) {
        this.apiScopes.splice(index, 1);
      }
    });
    this.newClientScope = new ClientScope();
    this.selectedValue = null;
  }
  removeClientScope(scope: string, index: number) {
    this.client.scopes.splice(index, 1);
    let newScope = new ApiScope();
    newScope.name = scope;
    this.apiScopes.push(newScope);
  }

  async getScopes() {
    const httpService = new HttpService<Array<ApiScope>>();
    const response = await httpService.getList("api-resources/1/scopes");
    this.apiScopes = response.data;
    this.showData = true;
  }

  async created() {
    await this.getScopes();
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      const response = await this.httpServiceCreateClient.postItem(
        "machine-client",
        this.client
      );

      this.$notification.success("Client details successfully saved");
      this.$store.dispatch("SET_DIRTY", false);

      this.$router.push({
        name: "clientDashboard",
        params: { id: response.data.id }
      });
    }
  }
}
