


























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import axios, { AxiosError } from "axios";

import {
  Application,
  AccountApplicationType,
  FullAccountApplicationType
} from "../../../types/application";

@Component
export default class AccountDashboardApplications extends Vue {
  accountid: string = "";
  httpService = new HttpService<AccountApplicationType>();
  applications = new Array<FullAccountApplicationType>();
  applicationName: string = "";
  todayDate = new Date();
  showModal: boolean = false;
  applicationId: any = "";

  async getData() {
    let httpService = new HttpService<Array<FullAccountApplicationType>>();
    const response = await httpService.getList(
      `accounts/${this.accountid}/fullapplications`
    );
    this.applications = response.data;
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.accountid = this.$route.params.id;

    this.getData();
  }

  edit(accountApplicationId: string) {
    this.$router.push({
      name: "accountApplication",
      params: {
        id: this.accountid,
        applicationId: accountApplicationId
      }
    });
  }

  showModalFunction(applicationId: string, applicationName: string) {
    this.showModal = true;
    this.applicationId = applicationId;
    this.applicationName = applicationName;
  }
  async remove() {
    await this.httpService
      .deleteItem(`accounts/${this.accountid}/applications`, this.applicationId)
      .then(response => {
        this.$notification.success(`Application successfully removed!`);
      });
    this.showModal = false;
    this.getData();
  }
}
