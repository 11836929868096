import { Organisation } from "@/types/organisation";
import { Account } from "@/types/account";
export class OrganisationAccount {
  organisation: Organisation;
  account: Account;
  owner: boolean;
  constructor() {
    this.organisation = new Organisation();
    this.account = new Account();
    this.owner = false;
  }
}

export class OrganisationAccountPost {
  accountId: string | undefined;
  owner: boolean;
  constructor() {
    this.accountId = "";
    this.owner = false;
  }
}
export class OrganisationAccountPut {
  owner: boolean;
  constructor() {
    this.owner = false;
  }
}
