







import Pikaday from "pikaday";
import moment, { Moment } from "moment";
import "pikaday/css/pikaday.css";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class DatePicker extends Vue {
  $refs!: {
    input: HTMLElement;
  };
  @Prop() value!: string;
  @Prop({ default: "DD MMM YYYY" }) format!: string;
  @Prop() minDate!: Date;

  reset() {
    this.$emit("input", null);
  }
  mounted() {
    const picker = new Pikaday({
      field: this.$refs.input,
      format: this.format,
      minDate: this.minDate,
      onSelect: () => {
        this.$emit("input", picker.getDate()!);
      }
    });
  }

  moment(date: Date) {
    let formatedDate: Moment = moment(date);

    if (!formatedDate.isValid()) {
      return "Click to select date";
    }
    return formatedDate.format(this.format).toString();
  }
}
