var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Edit " + _vm._s(_vm.selfRegistration.domain))]),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n          Domain name:\n          "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.selfRegistration.domain,
                        placeholder: "Domain Name"
                      },
                      model: {
                        value: _vm.$v.selfRegistration.domain.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.selfRegistration.domain,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.selfRegistration.domain.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n          Code:\n          "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.selfRegistration.code,
                        placeholder: "Code"
                      },
                      model: {
                        value: _vm.$v.selfRegistration.code.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.selfRegistration.code, "$model", $$v)
                        },
                        expression: "$v.selfRegistration.code.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n          Start Date:\n          "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c("div", { staticClass: "main-input" }, [
                  _c(
                    "label",
                    { staticClass: "cursor-pointer" },
                    [
                      _c("date-picker", {
                        staticClass:
                          "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                        class: _vm.$validationStatus.status(
                          _vm.$v.selfRegistration.startDate
                        ),
                        model: {
                          value: _vm.$v.selfRegistration.startDate.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.selfRegistration.startDate,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.selfRegistration.startDate.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.$v.selfRegistration.startDate.$error
                    ? _c(
                        "div",
                        { staticClass: "text-red-400 text-xs italic" },
                        [
                          !_vm.$v.selfRegistration.startDate.required
                            ? _c("p", [_vm._v("Please select a start date")])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n          End Date:\n          "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c("div", { staticClass: "main-input" }, [
                  _c(
                    "label",
                    { staticClass: "cursor-pointer" },
                    [
                      _c("date-picker", {
                        staticClass:
                          "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
                        class: _vm.$validationStatus.status(
                          _vm.$v.selfRegistration.endDate
                        ),
                        model: {
                          value: _vm.$v.selfRegistration.endDate.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.selfRegistration.endDate,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.selfRegistration.endDate.$model"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.$v.selfRegistration.endDate.$error
                    ? _c(
                        "div",
                        { staticClass: "text-red-400 text-xs italic" },
                        [
                          !_vm.$v.selfRegistration.endDate.required
                            ? _c("p", [_vm._v("Please select an end date")])
                            : _vm._e(),
                          !_vm.$v.selfRegistration.endDate
                            .endDateGreaterThanStartDate
                            ? _c("p", [
                                _vm._v(
                                  "The end date must be after the start date"
                                )
                              ])
                            : _vm._e(),
                          !_vm.$v.selfRegistration.endDate.dateGreaterThanToday
                            ? _c("p", [
                                _vm._v("The end date can't be in the past")
                              ])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }