








































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationAccount } from "@/types/organisationAccount";
import { OrganisationAccountPut } from "@/types/organisationAccount";
import { Account } from "@/types/account";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

@Component({
  mixins: [SaveMixin],
  validations: {
    organisationAccount: {
      organisation: {
        organisationId: { required }
      },
      account: {
        email: { required }
      },
      owner: { required }
    }
  }
})
export default class OrganisationIndividualEdit extends Vue {
  showData = false;
  organisationAccount = new OrganisationAccount();
  organisationId: string = "";
  accountId: string = "";
  httpServiceOrganisationAccountput: HttpService<
    OrganisationAccountPut
  > = new HttpService<OrganisationAccountPut>();
  httpServiceOrganisationAccount: HttpService<
    OrganisationAccount
  > = new HttpService<OrganisationAccount>();
  backRoute: { name: string; id?: string } = {
    name: "organisationDashboard",
    id: ""
  };
  get title() {
    return `${this.organisationAccount.organisation.organisationName} -${this.organisationAccount.account.fullName} `;
  }
  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;
    this.accountId = this.$route.params.accountId;
    this.backRoute.id = this.$route.params.id;
    this.getData();
  }
  async getData() {
    await this.httpServiceOrganisationAccount
      .getItem(
        `organisations/${this.organisationId}/accounts/${this.accountId}`
      )
      .then(response => {
        this.organisationAccount = response.data;
      });
    this.showData = true;
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceOrganisationAccountput
        .putItem(
          `organisations/${this.organisationAccount.organisation.organisationId}/accounts/${this.organisationAccount.account.id}`,
          {
            owner: this.organisationAccount.owner
          }
        )
        .then(res => this.$notification.success(`Account successfully added!`))
        .then(() => {
          this.$router.push({
            name: "organisationDashboard",
            params: {
              id: String(this.organisationAccount.organisation.organisationId)
            }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
