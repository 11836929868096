import { QuestionType } from "@/types/questionType";
import { QuestionValue } from "./questionValue";
import { Answer } from "@/types/answer";

class QuestionBase {
  name: string;
  maximumLength: number;
  isRequired: boolean;
  constructor() {
    this.name = "";
    this.maximumLength = 25;
    this.isRequired = false;
  }
}

export class Question extends QuestionBase {
  questionValues?: Array<QuestionValue>;
  answers?: Array<Answer>;
  questionId: any;
  questionType: QuestionType;

  constructor() {
    super();
    this.questionId = "";
    this.questionType = new QuestionType();
  }
}

export class QuestionAdder extends QuestionBase {
  questionTypeId: any;
  questionId?: any;

  constructor() {
    super();

    this.questionTypeId = "";
  }
}
