import ThirdPartyList from "@/views/configuration/thirdParty/ThirdPartyList.vue";
import ThirdPartyEdit from "@/views/configuration/thirdParty/ThirdPartyEdit.vue";
import ThirdPartyPage from "@/views/configuration/thirdParty/ThirdPartyPage.vue";
import ThirdPartyAdd from "@/views/configuration/thirdParty/ThirdPartyAdd.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const thirdPartyRoutes = {
  path: "/configuration/thirdParties",

  component: ThirdPartyPage,
  children: [
    {
      path: "",
      component: ThirdPartyList,
      name: "thirdPartyList"
    },
    {
      path: "edit/:id",
      name: "thirdPartyEdit",
      component: ThirdPartyEdit
    },
    {
      path: "add",
      name: "thirdPartyAdd",
      component: ThirdPartyAdd
    }
  ],
  beforeEnter: roleGuard
};
