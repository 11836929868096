var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-white rounded p-8 shadow relative" }, [
    _c("h1", [_vm._v("User activity")]),
    _c(
      "div",
      { staticClass: "xxl:overflow-y-scroll relative xxl:activity-height" },
      [
        _c("div", { staticClass: "ml-5 border-l-2 border-gray-25 relative" }, [
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _c("p", { staticClass: "font-bold" }, [_vm._v("Logged out")]),
              _vm._m(0)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(1),
              _vm._m(2)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(3),
              _vm._m(4)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _c("p", { staticClass: "font-bold" }, [_vm._v("Logged in")]),
              _vm._m(5)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass: "text-red-600 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _c("p", { staticClass: "font-bold text-red-600" }, [
                _vm._v("Login timed out")
              ]),
              _vm._m(6)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(7),
              _vm._m(8)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(9),
              _vm._m(10)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(11),
              _vm._m(12)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(13),
              _vm._m(14)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(15),
              _vm._m(16)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative mb-6" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _vm._m(17),
              _vm._m(18)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pl-10 relative" },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "text-green-100 text-3xl absolute left-0 dot-circle",
                attrs: { icon: "dot-circle" }
              }),
              _c("p", { staticClass: "font-bold" }, [_vm._v("Logged in")]),
              _vm._m(19)
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("14:38")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Downloaded certificate for\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13467")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("14:37")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Took course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13467")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("14:35")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("14:07")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:39")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13467")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:10")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13463")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:08")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13139")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:08")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#12461")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:08")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13433")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:08")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-bold" }, [
      _vm._v("\n          Viewed course\n          "),
      _c("a", { staticClass: "text-green-100 italic", attrs: { href: "#" } }, [
        _vm._v("#13412")
      ]),
      _vm._v(" on Optician CET\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:08")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n          01/05/2019,\n          "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("12:07")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }