var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c("div", { staticClass: "flex md:-mx-4 mb-8 flex-wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "w-full mb-8 sm:w-full sm:mb-8 md:w-1/2 lg:w-1/2 xl:w-1/4 xl:mb-0 md:px-4"
        },
        [
          _c("div", { staticClass: "bg-white rounded p-5 shadow" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "bg-gray-10 p-4 flex-initial rounded-full w-14 h-14"
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-2xl",
                    attrs: { icon: "user" }
                  })
                ],
                1
              ),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "flex mt-8" }, [
              _c("div", { staticClass: "flex-initial" }, [
                _vm._v("Last month: 63")
              ]),
              _c(
                "div",
                { staticClass: "flex-initial ml-auto" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "caret-up" }
                  }),
                  _vm._v("9.23%\n          ")
                ],
                1
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full mb-8 sm:w-full sm:mb-8 md:w-1/2 lg:w-1/2 xl:w-1/4 xl:mb-0 md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-green-200 rounded p-5 shadow text-white" },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-gray-10 p-4 inline-block flex-initial rounded-full w-14 h-14"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-2xl text-green-200",
                      attrs: { icon: "user-plus" }
                    })
                  ],
                  1
                ),
                _vm._m(1)
              ]),
              _c("div", { staticClass: "flex mt-8" }, [
                _c("div", { staticClass: "flex-initial" }, [
                  _vm._v("Last month: 74")
                ]),
                _c(
                  "div",
                  { staticClass: "flex-initial ml-auto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "caret-up" }
                    }),
                    _vm._v("9.23%\n          ")
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full mb-8 sm:w-full sm:mb-8 md:w-1/2 md:mb-0 lg:w-1/2 xl:w-1/4 xl:mb-0 md:px-4"
        },
        [
          _c("div", { staticClass: "bg-white rounded p-5 shadow" }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "bg-gray-10 p-4 inline-block flex-initial rounded-full w-14 h-14"
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-2xl",
                    attrs: { icon: "eye" }
                  })
                ],
                1
              ),
              _vm._m(2)
            ]),
            _c("div", { staticClass: "flex mt-8" }, [
              _c("div", { staticClass: "flex-initial" }, [
                _vm._v("Last month: 5345")
              ]),
              _c(
                "div",
                { staticClass: "flex-initial ml-auto" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "caret-up" }
                  }),
                  _vm._v("9.23%\n          ")
                ],
                1
              )
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/4 xl:mb-0 md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-red-500 rounded p-5 shadow text-white" },
            [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-gray-10 p-4 inline-block flex-initial rounded-full w-14 h-14"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-2xl text-red-500",
                      attrs: { icon: "ban" }
                    })
                  ],
                  1
                ),
                _vm._m(3)
              ]),
              _c("div", { staticClass: "flex mt-8" }, [
                _c("div", { staticClass: "flex-initial" }, [
                  _vm._v("Last month: 5")
                ]),
                _c(
                  "div",
                  { staticClass: "flex-initial ml-auto" },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "caret-up" }
                    }),
                    _vm._v("9.23%\n          ")
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "flex md:-mx-4 mb-8 flex-wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "w-full mb-8 sm:w-full sm:mb-8 md:w-full lg:w-full xl:w-1/2 xl:mb-0 md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-white rounded p-5 shadow h-full" },
            [
              _c("h2", [_vm._v("Active users")]),
              _c("panel", {
                attrs: {
                  direction: "accountTypes",
                  "x-data": "accountTypeName",
                  "chart-info": "Number of Users over the last 10 weeks",
                  "y-data": "accountTypeId",
                  "chart-type": "line",
                  chartName: "Chart1",
                  chartFill: false,
                  chartBorderColor: ["#3fc8d7"],
                  chartBackgroundColor: ["#71cdd7"],
                  maintainAspectRatio: false,
                  chartHeight: "h-screen-20"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "w-full sm:w-full md:w-full lg:w-full xl:w-1/2 md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-white rounded p-5 shadow h-full" },
            [
              _c("h2", [_vm._v("Client connections")]),
              _c("panel", {
                attrs: {
                  direction: "accountTypes",
                  "x-data": "accountTypeName",
                  "chart-info": "Number of Users over the last 10 weeks",
                  "y-data": "accountTypeId",
                  "chart-type": "bar",
                  chartName: "Chart2",
                  chartBorderColor: ["#f66580", "#f9c56d", "#3fc8d7"],
                  chartBackgroundColor: ["#f4a7b5", "#f8e0b6", "#71cdd7"],
                  maintainAspectRatio: false,
                  chartHeight: "h-screen-20"
                }
              })
            ],
            1
          )
        ]
      )
    ]),
    _c("div", { staticClass: "flex md:-mx-4 flex-wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "w-full mb-8 sm:w-full md:w-full lg:w-full sm:mb-8 xl:w-3/5 xl:mb-0 md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-white rounded p-5 shadow h-full" },
            [
              _c("h2", [_vm._v("New users")]),
              _c("table-component", {
                attrs: {
                  primaryKey: "accountId",
                  responseArray: _vm.responseArray,
                  columns: [
                    { name: "firstName", label: "First Name" },
                    { name: "lastName", label: "Last Name" },
                    { name: "email", label: "Email" },
                    { name: "isActive", label: "Is Active?" }
                  ]
                },
                on: {
                  remove: function($event) {
                    return _vm.confirmRemove($event)
                  },
                  edit: function($event) {
                    return _vm.edit($event)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full hidden sm:hidden md:hidden lg:hidden xl:w-2/5 xl:block md:px-4"
        },
        [
          _c(
            "div",
            { staticClass: "bg-white rounded p-5 shadow h-full" },
            [
              _c("h2", [_vm._v("User type (liblynx vs ingress)")]),
              _c("panel", {
                attrs: {
                  direction: "accountTypes",
                  "x-data": "accountTypeName",
                  "chart-info": "Number of Users over the last 10 weeks",
                  "y-data": "accountTypeId",
                  "chart-type": "doughnut",
                  chartName: "Chart3",
                  chartBorderColor: ["#f66580", "#f9c56d", "#3fc8d7"],
                  chartBackgroundColor: ["#f4a7b5", "#f8e0b6", "#71cdd7"],
                  maintainAspectRatio: false,
                  chartHeight: "md:pie-chart-height"
                }
              })
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-initial text-right ml-auto" }, [
      _c("h1", { staticClass: "mb-2 text-2xl" }, [_vm._v("74")]),
      _c("p", [_vm._v("New users")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-initial text-right ml-auto" }, [
      _c("h1", { staticClass: "mb-1 text-2xl" }, [_vm._v("96")]),
      _c("p", [_vm._v("Unconfirmed accounts")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-initial text-right ml-auto" }, [
      _c("h1", { staticClass: "mb-1 text-2xl" }, [_vm._v("5210")]),
      _c("p", [_vm._v("Unique visits")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-initial text-right ml-auto" }, [
      _c("h1", { staticClass: "mb-1 text-2xl" }, [_vm._v("2")]),
      _c("p", [_vm._v("Suspended accounts")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }