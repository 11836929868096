import { RootState } from "../../types";
import { DeleteAnswers } from "./types";
import { mutations } from "./mutations";
import { Module } from "vuex";

export const state: DeleteAnswers = {
  deleteArray: []
};

export const applicationAnswerDeleteArray: Module<DeleteAnswers, RootState> = {
  state,
  mutations
};
