import SubscriptionPackagePage from "@/views/subscriptionPackages/SubscriptionPackagePage.vue";
import SubscriptionPackageList from "@/views/subscriptionPackages/SubscriptionPackageList.vue";
import SubscriptionPackageEdit from "@/views/subscriptionPackages/SubscriptionPackageEdit.vue";
import SubscriptionPackageAdd from "@/views/subscriptionPackages/SubscriptionPackageAdd.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const subscriptionRoutes = {
  path: "/configuration/packages",
  component: SubscriptionPackagePage,
  children: [
    {
      path: "",
      name: "packagesList",
      component: SubscriptionPackageList
    },
    {
      path: "package/:id",
      name: "packagesEdit",
      component: SubscriptionPackageEdit
    },
    {
      path: "package/",
      name: "packagesAdd",
      component: SubscriptionPackageAdd
    }
  ],
  beforeEnter: roleGuard
};
