var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "padding-sm sm:padding-sm lg:padding-lg xl:padding-lg",
      attrs: { id: "app", "data-server-rendered": "true" }
    },
    [
      _vm.isLoggedIn
        ? _c(
            "div",
            [
              _c("on-click-outside", { attrs: { close: _vm.close } }, [
                _c(
                  "header",
                  { staticClass: "fixed w-full top-0 z-10" },
                  [_c("header-component"), _c("navigation")],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "md:p-10 lg:px-20 xl:px-20 w-full h-full mb-16"
                },
                [
                  _c("portal-target", { attrs: { name: "app", multiple: "" } }),
                  _c(
                    _vm.layout,
                    { tag: "component", staticClass: "w-full" },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }