var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dropdown-list", {
        attrs: {
          data: _vm.groupQuestion.question.questionValues,
          accessValueField: "questionValueId",
          allowNull: !_vm.groupQuestion.isRequired,
          selectedValue:
            _vm.groupQuestion.question.answers[0].questionAnswerValueId
        },
        on: {
          selected: function($event) {
            _vm.groupQuestion.question.answers[0].questionAnswerValueId = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(item.name))]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }