








































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationApplication } from "@/types/organisationApplication";
import { AxiosError } from "axios";
import { Form } from "../../../types/form";
import { Application } from "../../../types/application";

@Component
export default class OrganisationDashboardApplications extends Vue {
  organisationId: string = "";
  httpService = new HttpService<Array<OrganisationApplication>>();
  applications: Array<OrganisationApplication> = new Array<
    OrganisationApplication
  >();

  applicationName: string = "";
  applicationId: number | string = "";
  showModal: boolean = false;

  async getData() {
    let httpService = new HttpService<Array<Form>>();
    const response = await this.httpService.getItem(
      `organisations/${this.organisationId}/applications`
    );
    const response2 = await httpService.getList("forms");

    if (response.status != 404) {
      for (let item of response.data) {
        if (item.application.formId)
          item.application.formName = this.$returnObject(
            item.application.formId,
            response2.data,
            "formId"
          ).name;
      }
    }
    this.applications = response.data || new Array<OrganisationApplication>();

    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;

    this.getData();
  }

  showModalFunction(applicationId: string, applicationName: string) {
    this.showModal = true;
    this.applicationId = applicationId;
    this.applicationName = applicationName;
  }
  async remove() {
    await this.httpService
      .deleteItem(
        `organisations/${this.organisationId}/applications`,
        this.applicationId,

        { errorInterceptor: false }
      )
      .then(response => {
        this.applications = this.applications.filter(
          (item: OrganisationApplication) =>
            item.application.applicationId != this.applicationId,
          this.$notification.success(
            `${this.applicationName} successfully removed!`
          )
        );
      });
    this.showModal = false;
    this.getData();
  }
}
