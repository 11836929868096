var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "save-bar",
    [
      !_vm.showData
        ? _c("div", { staticClass: "loader" })
        : [
            _c("h1", [
              _vm._v(_vm._s(_vm.organisation.organisation.organisationName))
            ]),
            _c("form", { staticClass: "w-full" }, [
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [_vm._v("Owner:")]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("checkbox-component", {
                      model: {
                        value: _vm.$v.organisation.owner.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.organisation.owner, "$model", $$v)
                        },
                        expression: "$v.organisation.owner.$model"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }