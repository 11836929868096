


















import { QuestionType } from "@/types/questionType";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    questionType: {
      name: { required, maxLength: maxLength(50) }
    }
  }
})
export default class QuestionTypeEdit extends Vue {
  questionType: QuestionType = new QuestionType();
  id: number | string = 0;
  httpService: HttpService<QuestionType> = new HttpService<QuestionType>();
  backRoute = { name: "questionTypesList" };

  async save() {
    this.$store.commit("SAVE", false);

    if (!this.$v.$dirty && !this.$v.$invalid) {
      this.$router.push(this.backRoute);

      return;
    }
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpService
        .putItem(`questiontypes/${this.id}`, this.questionType)
        .then(response => {
          if (response) {
            this.$notification.success("Question type successfully saved");
          }
        });
      this.$router.push({ name: "questionTypesList" });
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getList();
  }
  async getList() {
    await this.httpService
      .getItem(`questiontypes/${this.id}`)
      .then(response => {
        this.questionType = response.data || new QuestionType();
      });
  }
}
