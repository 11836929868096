import CountryList from "@/views/configuration/countries/CountryList.vue";
import CountryAdd from "@/views/configuration/countries/CountryAdd.vue";
import CountryEdit from "@/views/configuration/countries/CountryEdit.vue";
import CountryPage from "@/views/configuration/countries/CountryPage.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const countryRoutes = {
  path: "/configuration/countries",

  component: CountryPage,
  children: [
    {
      path: "",
      component: CountryList,
      name: "countriesList"
    },
    {
      path: "edit/:id",
      name: "countriesEdit",
      component: CountryEdit,
      props: true
    },
    {
      path: "add",
      name: "countriesAdd",
      component: CountryAdd,
      props: true
    }
  ],

  beforeEnter: roleGuard
};
