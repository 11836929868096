






























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Account } from "@/types/account";

@Component
export default class AccountsList extends Vue {
  searching = false;
  message = "";

  showData = false;
  total = 0;
  searchCriteria = "";
  accounts = new Array<Account>();
  httpServiceAccount = new HttpService<Array<Account>>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber = 1;
  showModal = false;
  currentPage = 1;
  accountId = "";
  firstName = "";
  lastName = "";
  timeout: any = null;

  get disabledButton() {
    return this.$store.state.uploads.disableUploadButton;
  }
  async getList(pageReset?: string) {
    const response = await this.httpServiceAccount.getListSearch(
      "accounts",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.accounts = response.data;
      this.searching = false;
      this.message = "";
      this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
    } else this.message = "no accounts found";

    if (pageReset) {
      this.pageNumber = 1;
    }
    this.showData = true;
  }
  get title() {
    return "Individuals";
  }

  keyMonitor(event: KeyboardEvent, pageReset: string) {
    if (this.searchCriteria) {
      this.searching = true;
      this.message = "searching...";
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getList(pageReset);
      }, 500);
    }
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }

  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  showModalFunction(accountId: string, firstName: string, lastName: string) {
    this.showModal = true;
    this.accountId = accountId;
    this.firstName = firstName;
    this.lastName = lastName;
  }
  async remove() {
    await this.httpServiceAccount
      .deleteItem("accounts", this.accountId)
      .then(response => {
        this.$notification.success(`Account successfully removed!`);
      });
    this.showModal = false;
    this.getList();
  }

  edit(id: string) {
    this.$router.push({ name: "accountDashboard", params: { id: id } });
  }
  importData() {
    this.$router.push({ name: "accountListImport" });
  }
  add() {
    this.$router.push({ name: "accountsAdd" });
  }
}
