import EntitlementList from "@/views/entitlements/EntitlementList.vue";
import EntitlementEdit from "@/views/entitlements/EntitlementEdit.vue";
import EntitlementPage from "@/views/entitlements/EntitlementPage.vue";
import EntitlementAdd from "@/views/entitlements/EntitlementAdd.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const entitlementRoutes = {
  path: "/configuration/entitlements",
  component: EntitlementPage,
  children: [
    {
      path: "",
      name: "entitlementsList",
      component: EntitlementList
    },
    {
      path: "edit/:id",
      name: "entitlementsEdit",
      component: EntitlementEdit
    },
    {
      path: "add",
      name: "entitlementsAdd",
      component: EntitlementAdd
    }
  ],

  beforeEnter: roleGuard
};
