




















import { ThirdParty } from "@/types/thirdParty";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    thirdParty: {
      name: { required, maxLength: maxLength(30) }
    }
  }
})
export default class ThirdPartyAdd extends Vue {
  thirdParty: ThirdParty = new ThirdParty();
  httpService: HttpService<ThirdParty> = new HttpService<ThirdParty>();
  id: number | string = 0;
  backRoute = { name: "thirdPartyList" };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getItem();
  }
  async getItem() {
    let response = await this.httpService.getItem(`thirdparty/${this.id}`);
    this.thirdParty = response.data || new ThirdParty();
  }

  async save() {
    this.$store.commit("SAVE", false);
    if (!this.$v.$invalid) {
      let response = await this.httpService.putItem(
        `thirdparty/${this.id}`,
        this.thirdParty
      );
      if (response.status < 300) {
        this.$notification.success("Third party successfully updated");
      }
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
