var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c(
      "div",
      [
        _c("h1", [_vm._v("Edit application")]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Application name")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("input-component", {
                attrs: { validationObj: _vm.$v.application.applicationName },
                model: {
                  value: _vm.$v.application.applicationName.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.application.applicationName, "$model", $$v)
                  },
                  expression: "$v.application.applicationName.$model"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Allow self registration")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("checkbox-component", {
                model: {
                  value: _vm.application.allowSelfRegistration,
                  callback: function($$v) {
                    _vm.$set(_vm.application, "allowSelfRegistration", $$v)
                  },
                  expression: "application.allowSelfRegistration"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Enforce completion?")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("checkbox-component", {
                model: {
                  value: _vm.application.enforceFormCompletion,
                  callback: function($$v) {
                    _vm.$set(_vm.application, "enforceFormCompletion", $$v)
                  },
                  expression: "application.enforceFormCompletion"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Require address?")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("checkbox-component", {
                model: {
                  value: _vm.application.requireAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.application, "requireAddress", $$v)
                  },
                  expression: "application.requireAddress"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Is this a consumer application?")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("checkbox-component", {
                model: {
                  value: _vm.application.consumerApplication,
                  callback: function($$v) {
                    _vm.$set(_vm.application, "consumerApplication", $$v)
                  },
                  expression: "application.consumerApplication"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Form associated with application?")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("dropdown-list", {
                attrs: {
                  data: _vm.forms,
                  accessValueField: "formId",
                  selectedValue: _vm.application.formId
                },
                on: {
                  selected: function($event) {
                    _vm.application.formId = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(_vm._s(item.name))]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _c("application-communication-list", {
          attrs: { application: _vm.application }
        }),
        _c(
          "button",
          {
            staticClass: "mt-8 btn-default",
            on: {
              click: function($event) {
                return _vm.gotoAddCommunication()
              }
            }
          },
          [_vm._v("Add New Communication")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }