


















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Country } from "@/types/country";
@Component
export default class CountryList extends Vue {
  total = 0;
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<Country>>();
  httpService = new HttpService<Country>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<Country> = new Array<Country>();
  showModal: boolean = false;
  removeData: Country = new Country();

  getList() {
    this.httpServiceArray
      .getListSearch(
        "countries",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          //  this.total = JSON.parse(response.headers["x-pagination"]).totalCount; //TODO once the totalCount is brought back in headers we can add this.
        } else this.responseArray = new Array<Country>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "countriesAdd"
    });
  }
  confirmRemove(data: Country) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: Country) {
    await this.httpService
      .deleteItem("countries", data.countryId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: Country) => item.countryId != data.countryId,
          this.$notification.success(
            `${data.countryName} successfully removed!`
          )
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: Country) {
    this.$router.push({
      name: `countriesEdit`,
      params: { id: data.countryId }
    });
  }
}
