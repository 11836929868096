


































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import { OrganisationSubscription } from "@/types/organisationSubscription";
import { AxiosError } from "axios";
import moment from "moment";
@Component
export default class OrganisationDashboardSubscriptions extends Vue {
  organisationId: string = "";
  httpService = new HttpService<Array<OrganisationSubscription>>();
  httpServiceSubscription = new HttpService<Array<SubscriptionPackageItem>>();
  subscriptions: Array<OrganisationSubscription> = new Array<
    OrganisationSubscription
  >();
  organisationSubscriptionId: string = "";
  subscriptionPackageName: string = "";
  todayDate = new Date();
  showModal: boolean = false;

  async getData() {
    const response = await this.httpService.getItem(
      `organisations/${this.organisationId}/subscriptions`,
      {
        errorInterceptor: false
      }
    );
    const response2 = await this.httpServiceSubscription.getListPaging(
      "packages"
    );
    if (response.status != 404) {
      for (let item of response.data) {
        item.subscriptionPackageName = this.$returnObject(
          item.subscriptionPackageId,
          response2.data,
          "subscriptionPackageId"
        ).subscriptionPackageName;
      }
    }
    this.subscriptions = response.data || new Array<OrganisationSubscription>();
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;

    this.getData();
  }
  moment(date: Date) {
    if (!date) {
      return "";
    }
    return moment(date).format("DD MMM YYYY");
  }

  edit(organisationSubscriptionId: string) {
    this.$router.push({
      name: "organisationSubscriptionEdit",
      params: {
        id: this.organisationId,
        organisationSubscriptionId: organisationSubscriptionId
      }
    });
  }

  showModalFunction(
    organisationSubscriptionId: string,
    subscriptionPackageName: string
  ) {
    this.showModal = true;
    this.organisationSubscriptionId = organisationSubscriptionId;
    this.subscriptionPackageName = subscriptionPackageName;
  }
  async remove() {
    await this.httpService
      .deleteItem(
        `organisations/${this.organisationId}/subscriptions`,
        this.organisationSubscriptionId,
        { errorInterceptor: false }
      )
      .then(response => {
        this.$notification.success(`Subscription successfully removed!`);
      });
    this.showModal = false;
    this.getData();
  }
}
