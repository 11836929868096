import { Vue, Component } from "vue-property-decorator";

@Component
export class TitleMixin extends Vue {
  get title() {
    return "";
  }
  updated() {
    if (this.title) document.title = `Subscriptions Admin - ${this.title}`;
  }
}
