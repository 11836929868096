




















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Organisation } from "@/types/organisation";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { isPhone } from "@/services/customValidator/customValidators";
import { AccountAddress } from "@/types/accountAddress";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  validations: {
    organisation: {
      contact: { required, maxLength: maxLength(50) },
      organisationName: { required, maxLength: maxLength(100) },
      telephone: { required, maxLength: maxLength(50), isPhone }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationEdit extends Vue {
  showData = false;
  organisationId: any = "";
  organisation: Organisation = new Organisation();
  httpService: HttpService<Organisation> = new HttpService<Organisation>();

  backRoute = {
    name: "organisationDashboard",
    id: ""
  };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;
    this.backRoute.id = this.$route.params.id;
    this.getData();
  }
  async getData() {
    const response = await this.httpService.getItem(
      `organisations/${this.organisationId}`
    );

    this.organisation = response.data || new Organisation();

    this.showData = true;
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      await this.httpService
        .putItem(`organisations/${this.organisationId}`, this.organisation)
        .then(() => {
          this.$notification.success("Organisation details successfully saved");
          this.$store.dispatch("SET_DIRTY", false);
          this.$router.push(this.backRoute);
        });
    }
  }
}
