var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("div", [
      _c("h1", [
        _vm._v(
          _vm._s(_vm.$v.form.entitlementName.$model) +
            " (" +
            _vm._s(_vm.$v.form.shortCode.$model) +
            ")"
        )
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [
          _vm._v("Entitlement Name:")
        ]),
        _c(
          "div",
          { staticClass: "main-input" },
          [
            _c("input-component", {
              attrs: { validationObj: _vm.$v.form.entitlementName },
              model: {
                value: _vm.$v.form.entitlementName.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.form.entitlementName, "$model", $$v)
                },
                expression: "$v.form.entitlementName.$model"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "input-container" }, [
        _c("div", { staticClass: "input-label" }, [_vm._v("Short Code:")]),
        _c("div", { staticClass: "main-input" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.form.shortCode.$model,
                expression: "$v.form.shortCode.$model"
              }
            ],
            class: [
              "w-full h-8 rounded p-2 border border-gray-50 focus:outline-none transition",
              _vm.$validationStatus.status(_vm.$v.form.shortCode)
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.$v.form.shortCode.$model },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.$v.form.shortCode, "$model", $event.target.value)
              }
            }
          }),
          _vm.$v.form.shortCode.$error
            ? _c("div", { staticClass: "text-red-400 text-xs italic" }, [
                !_vm.$v.form.shortCode.required
                  ? _c("p", [_vm._v("This field is required")])
                  : _vm._e(),
                !_vm.$v.form.shortCode.maxLength
                  ? _c("p", [
                      _vm._v(
                        "Maximum length is " +
                          _vm._s(_vm.$v.form.shortCode.$params.maxLength.max) +
                          " characters"
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }