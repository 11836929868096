var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showData ? _c("div", { staticClass: "loader" }) : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showData,
            expression: "showData"
          }
        ],
        staticClass: "lg:flex md:-mx-4"
      },
      [
        _c(
          "div",
          { staticClass: "lg:userAccount md:px-4" },
          [
            _c("organisation-dashboard-information", {
              staticClass: "mb-8 lg:mb-0",
              on: {
                finishedLoading: function($event) {
                  _vm.information = true
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "lg:userAccountSpacing md:px-4" },
          [
            _c("organisation-dashboard-self-registration", {
              staticClass: "mb-8",
              on: {
                finishedLoading: function($event) {
                  _vm.selfReg = true
                }
              }
            }),
            _c("organisation-dashboard-subscriptions", {
              staticClass: "mb-8",
              on: {
                finishedLoading: function($event) {
                  _vm.subscriptions = true
                }
              }
            }),
            _c("organisation-dashboard-applications", {
              staticClass: "mb-8",
              on: {
                finishedLoading: function($event) {
                  _vm.applications = true
                }
              }
            }),
            _c("organisation-dashboard-individuals", {
              staticClass: "mb-8",
              on: {
                finishedLoading: function($event) {
                  _vm.individuals = true
                }
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }