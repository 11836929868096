


















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { QuestionType } from "@/types/questionType";
@Component
export default class QuestionTypeList extends Vue {
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<QuestionType>>();
  httpService = new HttpService<QuestionType>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  total = 0;
  pageNumber: number = 1;
  responseArray: Array<QuestionType> = new Array<QuestionType>();
  showModal: boolean = false;
  removeData: QuestionType = new QuestionType();

  getList() {
    this.httpServiceArray
      .getListSearch(
        "questionTypes",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
        } else this.responseArray = new Array<QuestionType>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "questionTypesAdd"
    });
  }
  confirmRemove(data: QuestionType) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: QuestionType) {
    await this.httpService
      .deleteItem("questionTypes", data.questionTypeId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: QuestionType) => item.questionTypeId != data.questionTypeId,
          this.$notification.success(`${data.name} successfully removed!`)
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: QuestionType) {
    this.$router.push({
      name: `questionTypesEdit`,
      params: { id: data.questionTypeId }
    });
  }
}
