
































































































































































import { Component, Prop, Vue, Model } from "vue-property-decorator";
import { Validation } from "vuelidate";

@Component
export default class InputValidation extends Vue {
  @Prop() validationObj!: Validation;
}
