import ClientList from "@/views/client/ClientList.vue";
import ClientPage from "@/views/client/ClientPage.vue";
import ClientAdd from "@/views/client/ClientAdd.vue";
import DashboardClient from "@/views/client/clientDashboard/DashboardClient.vue";
import ClientScopeSave from "@/views/client/clientConfiguration/clientScope/ClientScopeSave.vue";
import ClientInformationSave from "@/views/client/ClientInformationSave.vue";
import ClientClaimSave from "@/views/client/clientConfiguration/clientClaim/ClientClaimSave.vue";
import ClientCorsOriginSave from "@/views/client/clientConfiguration/clientCorsOrigin/ClientCorsOriginSave.vue";
import ClientGrantTypeSave from "@/views/client/clientConfiguration/clientGrantType/ClientGrantTypeSave.vue";
import ClientRedirectUriSave from "@/views/client/clientConfiguration/clientRedirectUri/ClientRedirectUriSave.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
import ClientPostLogoutRedirectUriSave from "@/views/client/clientConfiguration/clientPostLogoutRedirectUri/ClientPostLogoutRedirectUriSave.vue";
import ClientPropertySave from "@/views/client/clientConfiguration/clientProperty/ClientPropertySave.vue";
import ClientIdPRestrictionSave from "@/views/client/clientConfiguration/clientIdPRestriction/ClientIdPRestrictionSave.vue";
import ClientSecretSave from "@/views/client/clientConfiguration/clientSecret/ClientSecretSave.vue";
export const clientRoutes = {
  path: "/clients",

  component: ClientPage,
  children: [
    {
      path: "",
      component: ClientList,
      name: "clientList"
    },

    {
      path: "add",
      name: "clientAdd",
      component: ClientAdd,
      props: true
    },
    {
      path: ":id",
      name: "clientDashboard",
      component: DashboardClient,
      meta: { layout: "simple" }
    },
    {
      path: ":id/information",
      name: "clientInformationEdit",
      component: ClientInformationSave
    },
    {
      path: ":id/scopes/add",
      name: "clientScopeAdd",
      component: ClientScopeSave,
      props: true
    },
    {
      path: ":id/scopes/:scopeId",
      name: "clientScopeEdit",
      component: ClientScopeSave,
      props: true
    },
    {
      path: ":id/claims/add",
      name: "clientClaimAdd",
      component: ClientClaimSave,
      props: true
    },
    {
      path: ":id/claims/:claimId",
      name: "clientClaimEdit",
      component: ClientClaimSave,
      props: true
    },
    {
      path: ":id/grantType/add",
      name: "clientGrantTypeAdd",
      component: ClientGrantTypeSave,
      props: true
    },
    {
      path: ":id/grantType/:grantTypeId",
      name: "clientGrantTypeEdit",
      component: ClientGrantTypeSave,
      props: true
    },
    {
      path: ":id/corsOrigin/add",
      name: "clientCorsOriginAdd",
      component: ClientCorsOriginSave,
      props: true
    },
    {
      path: ":id/corsOrigin/:corsOriginId",
      name: "clientCorsOriginEdit",
      component: ClientCorsOriginSave,
      props: true
    },
    {
      path: ":id/redirectUri/add",
      name: "clientRedirectUriAdd",
      component: ClientRedirectUriSave,
      props: true
    },
    {
      path: ":id/redirectUri/:redirectUriId",
      name: "clientRedirectUriEdit",
      component: ClientRedirectUriSave,
      props: true
    },
    {
      path: ":id/postLogoutUri/add",
      name: "clientPostLogoutRedirectUriAdd",
      component: ClientPostLogoutRedirectUriSave,
      props: true
    },
    {
      path: ":id/postLogoutUri/:postLogoutRedirectUriId",
      name: "clientPostLogoutRedirectUriEdit",
      component: ClientPostLogoutRedirectUriSave,
      props: true
    },
    {
      path: ":id/clientProperty/add",
      name: "clientPropertyAdd",
      component: ClientPropertySave,
      props: true
    },
    {
      path: ":id/clientProperty/:clientPropertyId",
      name: "clientPropertyEdit",
      component: ClientPropertySave,
      props: true
    },
    {
      path: ":id/IdPRestriction/add",
      name: "clientIdPRestrictionAdd",
      component: ClientIdPRestrictionSave,
      props: true
    },
    {
      path: ":id/IdPRestriction/:IdPRestrictionId",
      name: "clientIdPRestrictionEdit",
      component: ClientIdPRestrictionSave,
      props: true
    },
    {
      path: ":id/secrets/add",
      name: "clientSecretAdd",
      component: ClientSecretSave,
      props: true
    },
    {
      path: ":id/secrets/:secretId",
      name: "clientSecretEdit",
      component: ClientSecretSave,
      props: true
    }
  ]

  // beforeEnter: roleGuard
};
