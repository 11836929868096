



















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Organisation } from "@/types/organisation";
import { OrganisationAccountPut } from "@/types/organisationAccount";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required } from "vuelidate/lib/validators";
import { AccountOrganisation } from "../../../types/accountOrganisation";
import { Account } from "../../../types/account";

@Component({
  mixins: [SaveMixin],
  validations: {
    organisation: { owner: {} }
  }
})
export default class AccountOrganisationEdit extends Vue {
  showData = false;
  accountName = "";
  accountId = "";
  organisationId = "";
  organisation = new AccountOrganisation();
  backRoute = {
    name: "accountDashboard",
    id: ""
  };
  get title() {
    return `${this.accountName} - ${this.organisation.organisation.organisationName}`;
  }
  async getData() {
    const httpServiceAccount = new HttpService<Account>();
    const response3 = await httpServiceAccount.getItem(
      `accounts/${this.accountId}`
    );
    this.accountName = response3.data.fullName;
    const httpAccountOrganisations = new HttpService<AccountOrganisation>();
    const response = await httpAccountOrganisations.getItem(
      `accounts/${this.accountId}/organisations/${this.organisationId}`
    );
    this.organisation = response.data;
    this.showData = true;
  }

  @Watch("$route", { immediate: true }) getId() {
    this.accountId = this.$route.params.id;
    this.organisationId = this.$route.params.accountOrganisationId;
    this.backRoute.id = this.$route.params.id;
    this.getData();
  }

  async save() {
    const httpService = new HttpService<OrganisationAccountPut>();
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const response = await httpService.putItem(
        `organisations/${this.organisationId}/accounts/${this.accountId}`,
        {
          owner: this.organisation.owner
        }
      );

      if (response.status != 404)
        this.$notification.success(`successfully updated!`);

      this.$router.push({
        name: "accountDashboard",
        params: { id: this.accountId }
      }),
        this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
