





























































import { Component, Vue, Watch } from "vue-property-decorator";

import { QuestionAdder } from "@/types/question";
import { QuestionType } from "@/types/questionType";
import { HttpService } from "@/services/api/httpService";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";

@Component({
  mixins: [SaveMixin],
  validations: {
    question: {
      name: { required },
      isRequired: {},
      maximumLength: {},
      questionTypeId: { required }
    }
  }
})
export default class QuestionAdd extends Vue {
  question: QuestionAdder = new QuestionAdder();
  questionTypes: Array<QuestionType> = Array<QuestionType>();
  backRoute = { name: "questionsList" };

  created() {
    let httpService = new HttpService<Array<QuestionType>>();
    httpService
      .getList("questiontypes")
      .then(response => (this.questionTypes = response.data));
  }

  async save() {
    let httpService = new HttpService<QuestionAdder>();
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await httpService.postItem("questions", this.question).then(response => {
        this.$router.push({
          name: "questionsEdit",
          params: { id: String(response.data.questionId) }
        });

        this.$notification.success("Question successfully saved");
        this.$store.dispatch("SET_DIRTY", false);
      });
    }
  }
}
