var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-initial my-1" }, [
    _c(
      "div",
      { staticClass: "relative rounded" },
      [
        _c("on-click-outside", { attrs: { close: _vm.close } }, [
          _c("div", [
            _c(
              "span",
              {
                staticClass: "search-reset cursor-pointer",
                on: {
                  click: function($event) {
                    _vm.searchCriteria = ""
                  }
                }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "text-md",
                  attrs: { icon: "times" },
                  on: { click: _vm.close }
                })
              ],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchCriteria,
                  expression: "searchCriteria"
                }
              ],
              staticClass:
                "search-input h-8 w-full block border-none bg-gray-100 text-gray-700 py-1 px-10 leading-tight focus:bg-white focus:outline-none transition rounded-full",
              attrs: { type: "search", placeholder: "search..." },
              domProps: { value: _vm.searchCriteria },
              on: {
                keydown: function($event) {
                  return _vm.keyMonitor($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchCriteria = $event.target.value
                }
              }
            }),
            _c("div", [
              _c(
                "ul",
                {
                  ref: "searchResults",
                  staticClass:
                    "bg-gray-100 ml-4 z-50 w-5/6 overflow-y-auto max-h-48 absolute"
                },
                [
                  _vm.searching
                    ? _c("li", { staticClass: "py-2 px-3 cursor-default" }, [
                        _vm._v(_vm._s(_vm.message))
                      ])
                    : _vm._l(_vm.filteredResults, function(result, index) {
                        return _c(
                          "li",
                          {
                            key: result.id,
                            staticClass: "py-2 px-3 cursor-pointer",
                            class: {
                              "bg-gray-900": index === _vm.highlightedIndex
                            },
                            on: {
                              click: function($event) {
                                return _vm.select(result.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(result.fullName) +
                                " - " +
                                _vm._s(result.email)
                            )
                          ]
                        )
                      })
                ],
                2
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }