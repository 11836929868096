





































































import { Component, Vue, Watch } from "vue-property-decorator";
import QuestionValueList from "@/views/configuration/questions/questionValue/QuestionValueList.vue";
import { QuestionAdder as QuestionEditor, Question } from "@/types/question";
import { QuestionType } from "@/types/questionType";
import { HttpService } from "@/services/api/httpService";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";

@Component({
  mixins: [SaveMixin],
  components: {
    QuestionValueList
  },
  validations: {
    question: {
      name: { required },
      isRequired: {},
      maximumLength: {},
      questionTypeId: { required }
    }
  }
})
export default class QuestionEdit extends Vue {
  question: QuestionEditor = new QuestionEditor();
  questionTypes: Array<QuestionType> = Array<QuestionType>();
  id: number = 0;
  backRoute = { name: "questionsList" };

  created() {
    let httpService = new HttpService<Array<QuestionType>>();
    httpService
      .getList("questiontypes")
      .then(response => (this.questionTypes = response.data))
      .catch(error => {
        console.error(error);
      });
  }
  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = Number(this.$route.params.id);
    this.getQuestion();
  }

  async getQuestion() {
    let httpService = new HttpService<Question>();
    await httpService.getItem(`questions/${this.id}`).then(response => {
      this.question.maximumLength = response.data.maximumLength;
      this.question.questionId = response.data.questionId;
      this.question.isRequired = response.data.isRequired;
      this.question.name = response.data.name;
      this.question.questionTypeId = response.data.questionType.questionTypeId;
    });
  }

  async add() {
    await this.save();
    this.$router.push({ name: "questionValueAdd" });
  }

  async save() {
    this.$store.commit("SAVE", false);
    let httpService = new HttpService<QuestionEditor>();
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.question.questionTypeId = this.question.questionTypeId;
      await httpService
        .putItem(`questions/${this.id}`, this.question)
        .then(response => {
          this.$router.push(this.backRoute);
          this.$notification.success("Question successfully updated!");
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
