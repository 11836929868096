import { AccountAddress } from "@/types/accountAddress";

export class Account {
  id?: any;
  accountTypeId: any;
  avatar: string | null;
  email: string | null;
  userName: string | null;
  firstName: string | null;
  fullName: string;
  isActive: boolean;
  isSuspended: boolean;
  jobTitle: string | null;
  lastName: string | null;
  createdDate: Date | null;
  modifiedDate: Date | null;
  optOutEmail: boolean | null;
  optOutMail: boolean | null;
  optOutTelephone: boolean | null;
  optOutThirdPartyEmail: boolean | null;
  optOutThirdPartyMail: boolean | null;
  optOutThirdPartyTelephone: boolean | null;
  phoneNumber: string | null;
  salutation: string | null;
  accountAddress: AccountAddress | null;

  constructor() {
    this.accountTypeId = null;
    this.avatar = null;
    this.email = null;
    this.userName = null;
    this.firstName = null;
    this.fullName = "";
    this.isActive = false;
    this.isSuspended = false;
    this.jobTitle = null;
    this.lastName = null;
    this.createdDate = new Date();
    this.modifiedDate = new Date();
    this.optOutEmail = null;
    this.optOutMail = null;
    this.optOutTelephone = null;
    this.optOutThirdPartyEmail = null;
    this.optOutThirdPartyMail = null;
    this.optOutThirdPartyTelephone = null;
    this.phoneNumber = null;
    this.salutation = null;
    this.accountAddress = null;
  }
}
