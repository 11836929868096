import CommunicationAdd from "@/views/communications/CommunicationAdd.vue";
import CommunicationEdit from "@/views/communications/CommunicationEdit.vue";
import CommunicationList from "@/views/communications/CommunicationList.vue";
import CommunicationPage from "@/views/communications/CommunicationPage.vue";
import { roleGuard } from "@/services/routeGuards/rolesGuard";
export const communicationRoutes = {
  path: "/configuration/communications",
  component: CommunicationPage,
  children: [
    {
      path: "",
      component: CommunicationList,
      name: "communicationsList"
    },
    {
      path: "edit/:id",
      name: "communicationsEdit",
      component: CommunicationEdit
    },
    {
      path: "add",
      name: "communicationsAdd",
      component: CommunicationAdd
    }
  ]
  // beforeEnter: roleGuard
};
