var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Configure Form")]),
    _c("label", { staticClass: "leading-loose" }, [_vm._v("Form Name:")]),
    _c(
      "div",
      [
        _c("input-component", {
          attrs: { validationObj: _vm.$v.updatedName },
          model: {
            value: _vm.$v.updatedName.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.updatedName, "$model", $$v)
            },
            expression: "$v.updatedName.$model"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }