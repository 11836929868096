var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", {
          staticClass:
            "loader router-view bg-white rounded-r rounded-b p-8 shadow parent relative"
        })
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "router-view bg-white rounded-r rounded-b p-8 shadow parent relative"
      },
      [
        _c("form", { staticClass: "w-full" }, [
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("\n          Organisation name:\n          "),
              _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    validationObj: _vm.$v.organisation.organisationName,
                    placeholder: "Organisation name"
                  },
                  model: {
                    value: _vm.$v.organisation.organisationName.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.organisation.organisationName,
                        "$model",
                        $$v
                      )
                    },
                    expression: "$v.organisation.organisationName.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("\n          Contact:\n          "),
              _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    validationObj: _vm.$v.organisation.contact,
                    placeholder: "Organisation contact"
                  },
                  model: {
                    value: _vm.$v.organisation.contact.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.organisation.contact, "$model", $$v)
                    },
                    expression: "$v.organisation.contact.$model"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("\n          Contact telephone:\n          "),
              _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("input-component", {
                  attrs: {
                    validationObj: _vm.$v.organisation.telephone,
                    placeholder: "Organisation telephone"
                  },
                  model: {
                    value: _vm.$v.organisation.telephone.$model,
                    callback: function($$v) {
                      _vm.$set(_vm.$v.organisation.telephone, "$model", $$v)
                    },
                    expression: "$v.organisation.telephone.$model"
                  }
                })
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }