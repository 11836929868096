
















import { Component, Prop, Vue } from "vue-property-decorator";
import { GroupQuestionType } from "../../../../../types/groupQuestion";
import { Answer } from "../../../../../types/answer";

@Component
export default class DropdownQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;

  created() {
    if (
      this.groupQuestion.question.answers &&
      this.groupQuestion.question.answers.length
    ) {
      this.groupQuestion.question.answers[0].questionAnswerText = null;
      this.groupQuestion.question.answers[0].questionAnswerDate = null;
    }
    if (!this.groupQuestion.question.answers) {
      this.groupQuestion.question.answers = new Array<Answer>();
    } else if (!this.groupQuestion.question.answers.length) {
      this.groupQuestion.question.answers.push(new Answer());
    }
  }
}
