



























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Communication } from "@/types/communication";
@Component
export default class CommunicationList extends Vue {
  total = 100;
  searching = false;
  message = "";
  timeout: any = null;
  showData = false;
  searchCriteria = "";
  httpServiceArray = new HttpService<Array<Communication>>();
  httpService = new HttpService<Communication>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<Communication> = new Array<Communication>();
  showModal: boolean = false;
  removeData: Communication = new Communication();

  async getList(pageReset?: string) {
    const response = await this.httpServiceArray.getListSearch(
      "communications",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.responseArray = response.data;
      this.searching = false;
      this.message = "";
      this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
    } else this.message = "no accounts found";
    if (pageReset) {
      this.pageNumber = 1;
    }
    this.showData = true;
  }
  get title() {
    return "Communications";
  }

  //   keyMonitor(event: KeyboardEvent, pageReset: string) {
  //     if (this.searchCriteria) {
  //       this.searching = true;
  //       this.message = "searching...";
  //       clearTimeout(this.timeout);
  //       this.timeout = setTimeout(() => {
  //         this.getList(pageReset);
  //       }, 500);
  //     }
  //   } //TODO implement searching
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "communicationsAdd"
    });
  }
  confirmRemove(data: Communication) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: Communication) {
    const response = await this.httpService.deleteItem(
      "communications",
      data.communicationId
    );

    this.responseArray = this.responseArray.filter(
      (item: Communication) => item.communicationId != data.communicationId,
      this.$notification.success(
        `${data.communicationName} successfully removed!`
      )
    );

    this.showModal = false;
    this.getList();
  }
  edit(data: Communication) {
    this.$router.push({
      name: `communicationsEdit`,
      params: { id: data.communicationId }
    });
  }
}
