var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showData
    ? _c("div", { staticClass: "loader" })
    : _c(
        "div",
        [
          _c("h1", [_vm._v("Communications")]),
          _c("div", { staticClass: "btn-group btn-group-positioning" }, [
            _c(
              "button",
              {
                staticClass: "btn-default",
                on: {
                  click: function($event) {
                    return _vm.add()
                  }
                }
              },
              [_vm._v("Add New")]
            )
          ]),
          _c(
            "div",
            {
              staticClass:
                "flex mb-4 simple-list-dropdown-search flex-wrap sm:flex-wrap"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "simple-list-dropdown flex-initial mr-auto w-full sm:w-full md:w-auto mb-4 md:mb-0"
                },
                [
                  _vm._v("\n      Show:\n      "),
                  _c("dropdown-list", {
                    staticClass:
                      "inline-block focus:border-none focus:outline-none focus:bg-gray-50 transition focus:border-1 ml-2 mr-2",
                    attrs: {
                      data: [
                        { page: 10 },
                        { page: 25 },
                        { page: 50 },
                        { page: 100 }
                      ],
                      accessValueField: "page",
                      selectedValue: _vm.pageSize ? _vm.pageSize : 10,
                      allowNull: false
                    },
                    on: {
                      selected: function($event) {
                        _vm.pageSize = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(_vm._s(item.page))]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          ),
          _c("table-component", {
            attrs: {
              primaryKey: "communicationId",
              responseArray: _vm.responseArray,
              columns: [
                { name: "communicationName", label: "Communication Name" },
                { name: "communicationTypeName", label: "Communication Type" },
                { name: "comment", label: "Comment" }
              ]
            },
            on: {
              remove: function($event) {
                return _vm.confirmRemove($event)
              },
              edit: function($event) {
                return _vm.edit($event)
              }
            }
          }),
          _c("pagination", {
            staticClass: "simple-list-pagination",
            attrs: {
              total: _vm.total,
              "per-page": _vm.pageSize,
              maxVisibleButtons: 3,
              "current-page": _vm.pageNumber,
              response: _vm.responseArray
            },
            on: {
              pagechanged: function($event) {
                _vm.pageNumber = $event
              }
            }
          }),
          _c(
            "modal",
            {
              attrs: { showModal: _vm.showModal },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("h2", [_vm._v("Delete")]),
              _c("p", [
                _vm._v("\n      Are you sure you want to delete\n      "),
                _c("strong", [_vm._v(_vm._s(_vm.removeData.communicationName))])
              ]),
              _c(
                "button",
                {
                  staticClass: "modal-button block",
                  on: {
                    click: function($event) {
                      return _vm.remove(_vm.removeData)
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }