





























































import axios from "axios";
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Application } from "@/types/application";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { Communication } from "@/types/communication";
import { Form } from "@/types/form";
import { required, maxLength } from "vuelidate/lib/validators";

@Component({
  validations: {
    application: {
      applicationName: { required, maxLength: maxLength(50) }
    }
  },
  mixins: [SaveMixin]
})
export default class ApplicationAdd extends Vue {
  backRoute = {
    name: "applicationsList"
  };
  forms: Array<Form> = Array<Form>();
  application: Application = new Application();

  created() {
    let httpService = new HttpService<Array<Form>>();
    httpService
      .getList("forms")
      .then(response => (this.forms = response.data))
      .catch(error => {
        console.error(error);
      });
  }

  async save() {
    this.$store.commit("SAVE", false);

    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpService = new HttpService<Application>();
      await httpService
        .postItem("applications", this.application)
        .then(response => {
          this.$router.push({
            name: "applicationsEdit",
            params: { id: String(response.data.applicationId) }
          });
        });
      this.$notification.success("Application successfully added");
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
