




















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Organisation } from "@/types/organisation";
import { HttpService } from "@/services/api/httpService";

@Component
export default class OrganisationDashboardInformation extends Vue {
  organisationId: string = "";
  httpService = new HttpService<Organisation>();
  organisation: Organisation = new Organisation();
  showModal: boolean = false;
  get title() {
    return this.organisation.organisationName;
  }
  async getData() {
    const response = await this.httpService.getItem(
      `organisations/${this.organisationId}`
    );

    this.organisation = response.data || new Organisation();

    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.organisationId = this.$route.params.id;
    this.getData();
  }

  async edit() {
    this.$router.push({
      name: "organisationEdit",
      params: { id: this.organisationId }
    });
  }
  get initials() {
    const names = this.organisation.organisationName.split(" ");

    let initials: string = "";
    initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  async remove() {
    await this.httpService
      .deleteItem("organisations", this.organisationId)
      .then(response => {
        this.$notification.success(`Organisation successfully removed!`);
      });
    this.showModal = false;
    this.$router.push({
      name: "organisationList"
    });
  }
}
