





















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { Organisation } from "@/types/organisation";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredUnless
} from "vuelidate/lib/validators";
import { isPhone } from "@/services/customValidator/customValidators";
import { AccountAddress } from "@/types/accountAddress";
import { SaveMixin } from "@/services/mixins/saveMixin";

@Component({
  validations: {
    organisation: {
      contact: { required, maxLength: maxLength(50) },
      organisationName: { required, maxLength: maxLength(100) },
      telephone: { required, maxLength: maxLength(50), isPhone }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationAdd extends Vue {
  showData = false;
  id: any = "";
  organisation = new Organisation();
  httpService = new HttpService<Organisation>();

  backRoute = {
    name: "organisationList"
  };
  mounted() {
    this.showData = true;
  }
  get title() {
    return "Organisation - New";
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      const response = await this.httpService.postItem(
        "organisations",
        this.organisation
      );

      this.id = response.data.organisationId;

      this.$notification.success("Organisation details successfully saved");
      this.$store.dispatch("SET_DIRTY", false);
      this.$router.push({
        name: "organisationDashboard",
        params: { id: this.id }
      });
    }
  }
}
