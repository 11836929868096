var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col shadow-2xl overflow-auto h-full p-8 bg-white ml-auto mt-14 fixed z-50 top-0 right-0 bottom-0 h-full w-full md:notification-sidebar"
    },
    [
      _c("div", { staticClass: "flex justify-between" }, [
        _c("h2", { staticClass: "mt-0" }, [_vm._v("Notifications")]),
        _c(
          "div",
          {
            staticClass: "cursor-pointer",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_vm._v("X")]
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          !_vm.notifications.length
            ? _c("p", { staticClass: "text-sm" }, [
                _vm._v("You have no notifications :-(")
              ])
            : _vm._e(),
          _vm._l(_vm.notifications, function(item, index) {
            return _c(
              "ul",
              { key: index },
              [
                _c("notification-item", { attrs: { item: item, index: index } })
              ],
              1
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }