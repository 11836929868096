var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showData
    ? _c("div", { staticClass: "loader" })
    : _c(
        "save-bar",
        { attrs: { showBack: false } },
        [
          _c("h1", [_vm._v("Machine Client Setup")]),
          _c(
            "form",
            {
              attrs: { lass: "w-full" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Client Id\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        validationObj: _vm.$v.client.clientId,
                        placeholder: "Client ID"
                      },
                      model: {
                        value: _vm.$v.client.clientId.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.clientId, "$model", $$v)
                        },
                        expression: "$v.client.clientId.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("Description:")
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("text-area", {
                      attrs: {
                        validationObj: _vm.$v.client.description,
                        placeholder: "Description"
                      },
                      model: {
                        value: _vm.$v.client.description.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.description, "$model", $$v)
                        },
                        expression: "$v.client.description.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v("\n        Secret\n        "),
                  _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                ]),
                _c(
                  "div",
                  { staticClass: "main-input" },
                  [
                    _c("input-component", {
                      attrs: {
                        placeholder: "Secret",
                        validationObj: _vm.$v.client.secret
                      },
                      model: {
                        value: _vm.$v.client.secret.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.client.secret, "$model", $$v)
                        },
                        expression: "$v.client.secret.$model"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.apiScopes.length
                ? _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "input-label" }, [
                      _vm._v("\n        Add Scope\n        "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "main-input h-full flex flex-row w-full" },
                      [
                        _c("dropdown-list", {
                          staticClass: "dropdown-list w-9/10",
                          attrs: {
                            data: _vm.apiScopes,
                            accessValueField: "name",
                            selectedValue: _vm.selectedValue,
                            allowNull: true
                          },
                          on: {
                            selected: function($event) {
                              _vm.newClientScope.scope = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item.name))]
                                }
                              }
                            ],
                            null,
                            false,
                            3225635320
                          )
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-1/10 bg-green-100 hover:bg-green-200 text-white",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.addClientScope($event)
                              }
                            }
                          },
                          [_vm._v("Add")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "input-container" }, [
                _c("div", { staticClass: "input-label" }, [_vm._v("Scopes")]),
                _c(
                  "ul",
                  { staticClass: "w-full" },
                  [
                    _vm._l(_vm.client.scopes, function(scope, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "flex flex-row" },
                        [
                          _c("input-component", {
                            staticClass: "w-9/10",
                            attrs: {
                              disabled: "",
                              placholder: "select from list",
                              value: scope.scope
                            }
                          }),
                          _c(
                            "button",
                            {
                              staticClass: "bg-red-600 w-1/10",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.removeClientScope(
                                    scope.scope,
                                    index
                                  )
                                }
                              }
                            },
                            [_vm._v("Remove")]
                          )
                        ],
                        1
                      )
                    }),
                    _vm.$v.client.scopes.$error
                      ? _c(
                          "div",
                          { staticClass: "text-red-400 text-xs italic px-2" },
                          [
                            !_vm.$v.client.scopes.required
                              ? _c("p", { staticClass: "my-1" }, [
                                  _vm._v("Please select a scope from the list")
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          ),
          _c("portal", { attrs: { to: "BackButtonOverride" } }, [
            _c(
              "button",
              {
                staticClass: "btn-secondary btn-width-md md:btn-width-lg mr-33",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_vm._v("Back")]
            )
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }