
















import { Component, Prop, Vue } from "vue-property-decorator";
import GroupQuestion from "../../../../forms/groups/questions/GroupQuestion.vue";
import { GroupQuestionType } from "../../../../../types/groupQuestion";
import { Answer } from "../../../../../types/answer";
import { QuestionValue } from "../../../../../types/questionValue";

@Component
export default class RadioQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;
  created() {
    if (
      this.groupQuestion.question.answers &&
      this.groupQuestion.question.answers.length
    ) {
      this.groupQuestion.question.answers[0].questionAnswerText = null;
      this.groupQuestion.question.answers[0].questionAnswerDate = null;
    }
    if (!this.groupQuestion.question.answers) {
      this.groupQuestion.question.answers = new Array<Answer>();
    } else if (!this.groupQuestion.question.answers.length) {
      this.groupQuestion.question.answers.push(new Answer());
    }
  }
  checkAnswer(value: QuestionValue) {
    let checked = false;
    if (this.groupQuestion.question.answers) {
      if (
        value.questionValueId ===
        this.groupQuestion.question.answers[0].questionAnswerValueId
      ) {
        checked = true;
      }
    }

    return checked;
  }
  checkRadio(value: QuestionValue, selected: boolean, answer: Answer) {
    if (this.groupQuestion.question.answers) {
      if (!selected) {
        this.groupQuestion.question.answers[0].questionAnswerValueId =
          value.questionValueId;
      }
    }
  }
}
