var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass:
            "bg-gray-20 border-gray-25 shadow-md border-t-2 fixed left-0 right-0 py-2 text-center text-right px-10 lg:px-20 bottom-0 w-full"
        },
        [
          _vm.showBack
            ? _c(
                "button",
                {
                  staticClass:
                    "btn-secondary mx-1 btn-width-md md:btn-width-lg",
                  on: {
                    click: function($event) {
                      return _vm.back()
                    }
                  }
                },
                [_vm._v("Back")]
              )
            : _c("portal-target", {
                staticClass: "-mb-11",
                attrs: { name: "BackButtonOverride" }
              }),
          _vm.showSave
            ? _c(
                "button",
                {
                  staticClass: "btn-default mx-1 btn-width-md md:btn-width-lg",
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.saveLabel))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [_vm.$store.getters.getDiscardBar ? _c("discard-bar") : _vm._e()],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }