




















































import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientScope } from "@/types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import { HttpService } from "@/services/api/httpService";
import { Client } from "@/types/client";
import { Application } from "../../types/application";
import { isUrl } from "@/services/customValidator/customValidators";

@Component({
  mixins: [SaveMixin],
  validations: {
    client: {
      id: {},
      absoluteRefreshTokenLifetime: { required, numeric },
      accessTokenLifetime: { required, numeric },
      accessTokenType: { required, numeric },
      allowAccessTokensViaBrowser: {},
      allowOfflineAccess: {},
      allowPlainTextPkce: {},
      allowRememberConsent: {},
      alwaysIncludeUserClaimsInIdToken: {},
      alwaysSendClientClaims: {},
      authorizationCodeLifetime: { required, numeric },
      backChannelLogoutSessionRequired: {},
      backChannelLogoutUri: { maxLength: maxLength(2000), isUrl },
      clientClaimsPrefix: { maxLength: maxLength(200) },
      clientId: { required, maxLength: maxLength(200) },
      clientName: { maxLength: maxLength(200) },
      clientUri: { maxLength: maxLength(2000), isUrl },
      consentLifetime: { numeric },
      description: { maxLength: maxLength(1000) },
      enableLocalLogin: {},
      enabled: {},
      frontChannelLogoutSessionRequired: {},
      frontChannelLogoutUri: { maxLength: maxLength(2000), isUrl },
      identityTokenLifetime: { required, numeric },
      includeJwtId: {},
      logoUri: { maxLength: maxLength(2000), isUrl },
      pairWiseSubjectSalt: { maxLength: maxLength(200) },
      protocolType: { required, maxLength: maxLength(200) },
      refreshTokenExpiration: { required, numeric },
      refreshTokenUsage: { required, numeric },
      requireClientSecret: {},
      requireConsent: {},
      requirePkce: {},
      slidingRefreshTokenLifetime: { required, numeric },
      updateAccessTokenClaimsOnRefresh: {},
      applicationId: {},
      //   created: { required },
      deviceCodeLifetime: { required, numeric },
      lastAccessed: {},
      nonEditable: {},
      updated: {},
      userCodeType: { maxLength: maxLength(100) },
      userSsoLifetime: { numeric }
    }
  }
})
export default class ClientDashboardInformation extends Vue {
  httpService = new HttpService<Client>();
  httpServiceApplication = new HttpService<Array<Application>>();
  client: Client = new Client();
  applications = new Array<Application>();
  showData = false;
  backRoute = {
    name: "clientDashboard",
    id: ""
  };
  async getData(id: string) {
    const response = await this.httpService.getItem(`clients/${id}`);
    this.client = response.data;
    const response2 = await this.httpServiceApplication.getList("applications");
    this.applications = response2.data;
    this.showData = true;
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.getData(this.$route.params.id);
  }
  uniformName(name: string): string {
    if (name == "applicationId") return "Application";
    let uniformName = name[0].toUpperCase() + name.slice(1);

    let lengthBeforeSplit = 0;
    let lengthAfterSplit = 0;
    let nameArray = [];
    while (uniformName) {
      uniformName = uniformName.replace(/([a-z])([A-Z])/, "$1 $2");
      lengthBeforeSplit = nameArray.length;
      nameArray = uniformName.split(" ");
      lengthAfterSplit = nameArray.length;
      if (lengthBeforeSplit == lengthAfterSplit) break;
    }
    return uniformName;
  }
  nonEditableProperty(key: string) {
    if (key == "id" || key == "updated" || key == "lastAccessed") return true;
    else return false;
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpService.putItem(`clients/${this.client.id}`, this.client);

      this.$notification.success("Client Successfully Saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
