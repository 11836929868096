var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-group" },
    [
      _vm._t("default"),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "px-3 py-2 md:px-8 md:py-4 bg-green-100 text-white handle"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-row items-center" },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-white",
                    attrs: { icon: "arrows-alt" }
                  }),
                  _c("h3", { staticClass: "mb-0 ml-6" }, [
                    _c("strong", { staticClass: "hidden md:inline-block" }, [
                      _vm._v("Group Name:")
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.questionGroup.name) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-auto text-md flex cursor-pointer" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mx-1",
                        attrs: { icon: "cog" },
                        on: {
                          click: function($event) {
                            _vm.showconfigureGroup = true
                          }
                        }
                      }),
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$store.getters.getForm.groups.length > 1,
                            expression: "$store.getters.getForm.groups.length>1"
                          }
                        ],
                        staticClass: "mx-1 hidden sm:block",
                        attrs: { icon: "trash" },
                        on: {
                          click: function($event) {
                            _vm.showModal = true
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "md:p-8" },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "question-group-container",
                    attrs: {
                      list: _vm.questionGroup.groupQuestions,
                      disabled: !_vm.enabled,
                      handle: ".handle"
                    },
                    on: {
                      start: function($event) {
                        _vm.Dragging = true
                      },
                      end: function($event) {
                        _vm.Dragging = false
                      }
                    }
                  },
                  "draggable",
                  _vm.draggableOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    { attrs: { type: "transition", name: "flip-list" } },
                    _vm._l(_vm.questionGroup.groupQuestions, function(
                      question,
                      index
                    ) {
                      return _c("group-question", {
                        key: question.question.questionId,
                        staticClass: "question handle py-4 px-4",
                        attrs: {
                          groupId: _vm.questionGroup.formGroupId,
                          questionOrder: index,
                          groupIndex: _vm.groupIndex,
                          groupQuestion: _vm.groupQuestion(question, index)
                        },
                        on: {
                          configQ: function($event) {
                            ;(_vm.questiontoConfigure = $event),
                              (_vm.configureQuestion = true)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "btn-group float-none mt-4" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-blank-bordered",
                    on: {
                      click: function($event) {
                        _vm.showAddQuestion = true
                      }
                    }
                  },
                  [_vm._v("Add Question")]
                )
              ])
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right" } },
            [
              _vm.showAddQuestion
                ? _c(
                    "side-modal",
                    {
                      on: {
                        close: function($event) {
                          _vm.showAddQuestion = false
                        }
                      }
                    },
                    [
                      _c("add-question", {
                        attrs: {
                          groupQuestions: _vm.questionGroup.groupQuestions,
                          groupIndex: _vm.groupIndex
                        },
                        on: {
                          close: function($event) {
                            _vm.showAddQuestion = false
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide-right" } },
        [
          _vm.showconfigureGroup
            ? _c(
                "side-modal",
                {
                  on: {
                    close: function($event) {
                      _vm.showconfigureGroup = false
                    }
                  }
                },
                [
                  _c("configure-group", {
                    attrs: {
                      questionGroup: _vm.questionGroup,
                      groupIndex: _vm.groupIndex
                    },
                    on: {
                      close: function($event) {
                        _vm.showconfigureGroup = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "modal",
        {
          attrs: { showModal: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("h2", [_vm._v("Delete")]),
          _c("p", [
            _vm._v("\n      Are you sure you want to delete\n      "),
            _c("strong", [_vm._v(_vm._s(_vm.questionGroup.name))]),
            _vm._v(" and any questions it contains from the form?\n    ")
          ]),
          _c(
            "button",
            {
              staticClass: "modal-button block",
              on: {
                click: function($event) {
                  return _vm.removeGroup()
                }
              }
            },
            [_vm._v("Delete")]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }