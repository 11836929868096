























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import OnClickOutside from "@/components/OnClickOutside.vue";
@Component({
  components: { OnClickOutside }
})
export default class SideModal extends Vue {
  created() {
    document.body.style.setProperty("overflow", "hidden");
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keyup", escapeHandler);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keyup", escapeHandler);
    });
  }
  close() {
    this.$emit("close");
  }
  save() {
    this.$store.commit("SIDEMODALSAVE", true);
  }
  destroyed() {
    document.body.style.removeProperty("overflow");
  }
}
