




















import { AccountType } from "@/types/accountType";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    accountType: {
      accountTypeName: { required, maxLength: maxLength(30) }
    }
  }
})
export default class AccountTypeAdd extends Vue {
  accountType: AccountType = new AccountType();
  httpService: HttpService<AccountType> = new HttpService<AccountType>();
  id: number | string = 0;
  backRoute = { name: "accountTypesList" };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getList();
  }
  async getList() {
    await this.httpService.getItem(`accountTypes/${this.id}`).then(response => {
      this.accountType = response.data || new AccountType();
    });
  }

  async save() {
    this.$store.commit("SAVE", false);
    if (!this.$v.$invalid) {
      await this.httpService.putItem(
        `accountTypes/${this.id}`,
        this.accountType
      );

      this.$notification.success("Account type successfully updated");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
