import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export class SaveMixin extends Vue {
  backRoute = { name: "" };

  @Watch("$v.$anyDirty", { immediate: true }) onDirtyFlag() {
    this.$store.dispatch("SET_DIRTY", this.$v.$anyDirty);
  }

  save() {}

  get triggerSave() {
    return this.$store.getters.getSaveEvent;
  }
  @Watch("triggerSave") onSave() {
    if (this.triggerSave) {
      this.save();
    }
  }
  get back() {
    return this.$store.getters.getBackEvent;
  }

  @Watch("back") onBack() {
    if (this.$store.getters.getBackEvent) {
      this.$store.commit("BACK", false);
      this.$router.push(this.backRoute);
    }
  }
}
