var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-white rounded shadow p-8" }, [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "rounded-full h-40 w-40 center m-auto mb-8 flex items-center bg-green-100 text-white justify-center"
            },
            [
              _c("span", { staticClass: "text-6xl" }, [
                _vm._v(_vm._s(_vm.initials))
              ])
            ]
          ),
          _c("div", { staticClass: "items-center" }, [
            _c("div", { staticClass: "mb-4" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.account.fullName))
              ])
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("Member since:")
              ]),
              _c("label", { staticClass: "block" }, [
                _vm._v(_vm._s(_vm.moment(_vm.account.createdDate)))
              ])
            ]),
            _c("div", { staticClass: "mb-8" }, [
              _c("label", { staticClass: "block font-bold" }, [
                _vm._v("User last updated:")
              ]),
              _c("label", { staticClass: "block" }, [
                _vm._v(_vm._s(_vm.moment(_vm.account.modifiedDate)))
              ])
            ]),
            _c(
              "div",
              { staticClass: "mt-16" },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn-default text-center mb-2",
                    on: {
                      click: function($event) {
                        _vm.showPassword = !_vm.showPassword
                      }
                    }
                  },
                  [_vm._v("Change password")]
                ),
                _vm.showPassword ? _c("account-password") : _vm._e(),
                !_vm.account.isSuspended
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-default btn-delete text-center mb-2",
                        on: {
                          click: function($event) {
                            return _vm.suspendAccount()
                          }
                        }
                      },
                      [_vm._v("Suspend account")]
                    )
                  : _vm._e(),
                _vm.account.isSuspended
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-default btn-approve text-center mb-2",
                        on: {
                          click: function($event) {
                            return _vm.suspendAccount()
                          }
                        }
                      },
                      [_vm._v("Enable account")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "btn-default btn-delete text-center",
                    on: {
                      click: function($event) {
                        _vm.showModal = true
                      }
                    }
                  },
                  [_vm._v("Delete account")]
                )
              ],
              1
            )
          ]),
          _c(
            "modal",
            {
              attrs: { showModal: _vm.showModal },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("h2", [_vm._v("Delete")]),
              _c("p", [
                _vm._v(
                  "Are you sure you want to delete " +
                    _vm._s(this.account.firstName) +
                    " " +
                    _vm._s(this.account.lastName) +
                    "?"
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "modal-button block",
                  on: {
                    click: function($event) {
                      return _vm.remove()
                    }
                  }
                },
                [_vm._v("Delete")]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }