export class SubscriptionPackageItem {
  subscriptionPackageName: string;
  subscriptionPackageCode: string;
  subscriptionPackageId?: any;
  links: {}[];
  packageEntitlements: [];

  constructor() {
    this.subscriptionPackageName = "";
    this.subscriptionPackageCode = "";
    this.links = [];
    this.packageEntitlements = [];
  }
}
