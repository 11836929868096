



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Communication } from "@/types/communication";
import { ApplicationCommunication } from "@/types/applicationCommunication";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength, numeric } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  validations: {
    applicationCommunication: {
      sortOrder: { required, numeric },
      communicationId: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class ApplicationCommunicationAdd extends Vue {
  applicationCommunication: ApplicationCommunication = new ApplicationCommunication();
  communications: Array<Communication> = Array<Communication>();
  backRoute: { name: string; id?: string } = {
    name: "applicationsEdit",
    id: ""
  };
  id: string = "";

  created() {
    this.backRoute.id = this.$route.params.id;
    this.id = this.$route.params.id;
    let httpService = new HttpService<Array<Communication>>();
    httpService.getList("communications").then(response => {
      if (response.status != 404) {
        this.communications = response.data;
      }
    });

    this.applicationCommunication.applicationId = Number(this.id);
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpService = new HttpService<ApplicationCommunication>();
      await httpService.postItem(
        `applications/${this.id}/communications`,
        this.applicationCommunication
      );

      this.$router.push({
        name: "applicationsEdit",
        params: { id: String(this.id) }
      });
      this.$notification.success(
        "Application Communication successfully added"
      );
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
