export class Answer {
  accountQuestionAnswerId?: number;
  GroupSet: number;
  questionId: number | null;
  questionAnswerValueId: number | null;
  questionAnswerText: string | null;
  questionAnswerDate: string | null;
  modifiedDate: string;
  createdDate: string;
  accountId?: string;

  constructor() {
    this.GroupSet = 1;
    (this.questionAnswerValueId = null),
      (this.questionAnswerText = null),
      (this.questionAnswerDate = null),
      (this.questionId = null);
    this.modifiedDate = "";
    this.createdDate = "";
  }
}
