var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Search For A Question Or Create A New One:")]),
      _c("div", [
        _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.groupQuestion.question.name,
                  expression: "!groupQuestion.question.name"
                }
              ],
              staticClass: "mb-4"
            },
            [
              _c("p", { staticClass: "leading-loose" }, [_vm._v("Questions:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchFilter,
                    expression: "searchFilter"
                  }
                ],
                staticClass:
                  "rounded-l w-5/6 p-1 md:p-2 border-l border-t border-b border-gray-50 hover:bg-white focus:outline-none transition pr-8",
                class: _vm.$validationStatus.status(
                  _vm.$v.groupQuestion.question.name
                ),
                attrs: { placeholder: "search..." },
                domProps: { value: _vm.searchFilter },
                on: {
                  keyup: _vm.keyMonitor,
                  focus: function($event) {
                    _vm.optionsShown = true
                  },
                  blur: function($event) {
                    _vm.optionsShown = false
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchFilter = $event.target.value
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass:
                    "w-1/6 bg-green-100 border-r border-t border-b border-gray-50 p-1 md:p-2 text-white rounded-r cursor-pointer transition",
                  on: {
                    click: function($event) {
                      _vm.newQuestion = true
                    }
                  }
                },
                [_vm._v("New")]
              ),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.optionsShown && _vm.filteredQuestions.length > 0,
                      expression: "optionsShown && filteredQuestions.length>0"
                    }
                  ],
                  ref: "questions",
                  staticClass:
                    "bg-white w-5/6 border border-gray-50 z-auto overflow-auto max-h-48"
                },
                _vm._l(_vm.filteredQuestions, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass:
                        "cursor-pointer p-1 block text-black leading-none",
                      class: { "bg-gray-20": index === _vm.highlightedIndex },
                      on: {
                        mouseover: function($event) {
                          _vm.highlightedIndex = index
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.selectQuestion(item)
                        },
                        mousedown: function($event) {
                          return _vm.selectQuestion(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm.$v.groupQuestion.question.name.$error
                ? _c(
                    "div",
                    { staticClass: "text-red-400 text-xs italic px-2" },
                    [
                      _vm.$v.groupQuestion.question.name.$invalid
                        ? _c("p", { staticClass: "my-1" }, [
                            _vm._v(
                              "Pleae Select A Question Or Create A New One"
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          )
        ])
      ]),
      _vm.groupQuestion.question.questionId
        ? _c("div", [
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Question Name:")
              ]),
              _c(
                "div",
                [
                  _c("input-component", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.groupQuestion.question.name,
                      callback: function($$v) {
                        _vm.$set(_vm.groupQuestion.question, "name", $$v)
                      },
                      expression: "groupQuestion.question.name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Question Type:")
              ]),
              _c(
                "div",
                [
                  _c("input-component", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.groupQuestion.question.questionType.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.groupQuestion.question.questionType,
                          "name",
                          $$v
                        )
                      },
                      expression: "groupQuestion.question.questionType.name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Alternative Name:")
              ]),
              _c(
                "div",
                [
                  _c("input-component", {
                    attrs: {
                      validationObj: _vm.$v.groupQuestion.alternativeName,
                      placeholder: "Alternative Name"
                    },
                    model: {
                      value: _vm.$v.groupQuestion.alternativeName.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.groupQuestion.alternativeName,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.groupQuestion.alternativeName.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Is Required")
              ]),
              _c(
                "div",
                [
                  _c("checkbox-component", {
                    model: {
                      value: _vm.$v.groupQuestion.isRequired.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.groupQuestion.isRequired, "$model", $$v)
                      },
                      expression: "$v.groupQuestion.isRequired.$model"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("label", { staticClass: "leading-loose" }, [
                _vm._v("Column Position")
              ]),
              _c("div", [
                _c("label", { staticClass: "opt-out active block" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.groupQuestion.columnPosition.$model,
                        expression: "$v.groupQuestion.columnPosition.$model"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: 0,
                      checked: _vm._q(
                        _vm.$v.groupQuestion.columnPosition.$model,
                        0
                      )
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.$v.groupQuestion.columnPosition,
                          "$model",
                          0
                        )
                      }
                    }
                  }),
                  _vm._v(" Left\n        ")
                ]),
                _c("label", { staticClass: "opt-out block" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.groupQuestion.columnPosition.$model,
                        expression: "$v.groupQuestion.columnPosition.$model"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: 1,
                      checked: _vm._q(
                        _vm.$v.groupQuestion.columnPosition.$model,
                        1
                      )
                    },
                    on: {
                      change: function($event) {
                        return _vm.$set(
                          _vm.$v.groupQuestion.columnPosition,
                          "$model",
                          1
                        )
                      }
                    }
                  }),
                  _vm._v(" Right\n        ")
                ])
              ])
            ]),
            _c("h2", { staticClass: "mt-12" }, [_vm._v("Answers")]),
            _vm.groupQuestion.question.questionValues
              ? _c(
                  "div",
                  _vm._l(_vm.groupQuestion.question.questionValues, function(
                    singleValue
                  ) {
                    return _c("ul", { key: singleValue.questionValueId }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: singleValue.isSelected,
                              expression: "singleValue.isSelected"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(singleValue.isSelected)
                              ? _vm._i(singleValue.isSelected, null) > -1
                              : singleValue.isSelected
                          },
                          on: {
                            change: function($event) {
                              var $$a = singleValue.isSelected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      singleValue,
                                      "isSelected",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      singleValue,
                                      "isSelected",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(singleValue, "isSelected", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(singleValue.name) +
                            "\n        "
                        )
                      ])
                    ])
                  }),
                  0
                )
              : _c("p", [
                  _vm._v(
                    "There are no answers associated with this type of question"
                  )
                ])
          ])
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade-in-out" } },
        [
          _vm.newQuestion
            ? _c("new-question", {
                attrs: { groupIndex: _vm.groupIndex },
                on: {
                  close: function($event) {
                    _vm.newQuestion = false
                  },
                  newQuestion: function($event) {
                    return _vm.selectQuestion($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }