



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { AccountType } from "@/types/accountType";
@Component
export default class AccountTypeList extends Vue {
  total = 0;
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<AccountType>>();
  httpService = new HttpService<AccountType>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<AccountType> = new Array<AccountType>();
  showModal: boolean = false;
  removeData: AccountType = new AccountType();

  getList() {
    this.httpServiceArray
      .getListSearch(
        "accountTypes",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.responseArray = response.data;
          this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
        } else this.responseArray = new Array<AccountType>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "accountTypesAdd"
    });
  }
  confirmRemove(data: AccountType) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: AccountType) {
    await this.httpService
      .deleteItem("accountTypes", data.accountTypeId)
      .then(response => {
        this.responseArray = this.responseArray.filter(
          (item: AccountType) => item.accountTypeId != data.accountTypeId,
          this.$notification.success(
            `${data.accountTypeName} successfully removed!`
          )
        );
      });
    this.showModal = false;
    this.getList();
  }
  edit(data: AccountType) {
    this.$router.push({
      name: `accountTypesEdit`,
      params: { id: data.accountTypeId }
    });
  }
}
