

























import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/views/Navigation/Navigation.vue";

import HeaderComponent from "@/components/HeaderComponent.vue";
import Default from "@/views/layouts/Default.vue";
import simple from "@/views/layouts/Simple.vue";
import AuthService from "@/services/auth/auth.service";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { config } from "@fortawesome/fontawesome-svg-core";
import OnClickOutside from "@/components/OnClickOutside.vue";

import SearchSelect from "@/components/SearchSelect.vue";

const defaultLayout: string = "default";
const auth = new AuthService();

@Component({
  components: {
    simple,
    Default,
    HeaderComponent,
    Navigation,
    OnClickOutside,
    SearchSelect
  }
})
export default class App extends Vue {
  isLoggedIn: boolean = false;
  get layout() {
    return this.$route.meta.layout || defaultLayout;
  }
  get showNotifications() {
    return this.$store.state.uploads.showNotifications;
  }
  created() {
    axios.defaults.errorInterceptor = true;
    axios.interceptors.request.use(
      (config: AxiosRequestConfig): AxiosRequestConfig => {
        config.validateStatus = (status: number) => {
          return (status >= 200 && status <= 300) || status === 404;
        };
        return config;
      }
    );

    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        let errorMessage = "";
        if (!error.response.data) {
          errorMessage = error.response.statusText;
          this.$notification.error(errorMessage);
        } else if (error.response.data.length > 0) {
          error.response.data.forEach((error: { description: string }) => {
            this.$notification.error(error.description);
          });
        } else {
          errorMessage = error.response.data.errorMessage;
          this.$notification.error(errorMessage);
        }

        return Promise.reject(error.response);
      }
    );
  }

  mounted() {
    auth.getUser().then(user => {
      this.isLoggedIn = user !== null && !user.expired;
    });
  }

  close() {
    this.$store.commit("TOGGLE_MENU", false);
  }
}
