


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Communication } from "@/types/communication";
import { ApplicationCommunication } from "@/types/applicationCommunication";
import { HttpService } from "@/services/api/httpService";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength, numeric } from "vuelidate/lib/validators";

@Component({
  validations: {
    applicationCommunication: {
      sortOrder: { required, numeric },
      communicationId: { required },
      communicationName: {}
    }
  },
  mixins: [SaveMixin]
})
export default class ApplicationCommunicationEdit extends Vue {
  applicationCommunication: ApplicationCommunication = new ApplicationCommunication();
  communications: Array<Communication> = Array<Communication>();
  backRoute: { name: string; id?: string } = {
    name: "applicationsEdit",
    id: ""
  };
  applicationId: number = 0;
  communicationId: number = 0;

  created() {
    this.backRoute.id = this.$route.params.id;
  }

  async getApplicationCommunication() {
    let httpService = new HttpService<ApplicationCommunication>();
    await httpService
      .getItem(
        `applications/${this.applicationId}/communications/${this.communicationId}`
      )
      .then(response => {
        this.applicationCommunication =
          response.data || new ApplicationCommunication();
      });
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.applicationId = Number(this.$route.params.id);
    this.communicationId = Number(this.$route.params.id2);
    let httpService = new HttpService<Array<Communication>>();
    httpService
      .getList("communications")
      .then(response => (this.communications = response.data));

    this.applicationCommunication.applicationId = Number(this.applicationId);
    this.getApplicationCommunication();
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpService = new HttpService<ApplicationCommunication>();
      await httpService.putItem(
        `applications/${this.applicationId}/communications/${this.communicationId}`,
        this.applicationCommunication
      );

      this.$router.push(this.backRoute);
      this.$notification.success(
        "Application Communication successfully edited"
      );
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
