


















import { QuestionType } from "@/types/questionType";
import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    questionType: {
      name: { required, maxLength: maxLength(50) }
    }
  }
})
export default class AccountTypeAdd extends Vue {
  questionType: QuestionType = new QuestionType();
  httpService: HttpService<QuestionType> = new HttpService<QuestionType>();
  backRoute = { name: "questionTypesList" };

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      await this.httpService
        .postItem("questiontypes", this.questionType)
        .then(response => {
          if (response) {
            this.$notification.success("Question type successfully saved");
          }
        });
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
