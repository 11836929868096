var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex md:-mx-4 xl:w-full flex-grow flex-col" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoader == true,
              expression: "showLoader == true"
            }
          ],
          staticClass: "bg-white text-white rounded-lg"
        },
        [
          _c(
            "div",
            { class: "bg-green-100 rounded-lg text-left w-" + _vm.width },
            [_vm._v("Loading...")]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoader == false,
              expression: "showLoader == false"
            }
          ],
          staticClass: "md:px-4 xl:w-full xxl:w-full"
        },
        [
          _c("client-information", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.spaOrWebOrMachine,
                expression: "spaOrWebOrMachine"
              }
            ],
            attrs: { clientType: _vm.clientType },
            on: {
              finishedLoading: function($event) {
                return _vm.increaseLoadingBar()
              }
            }
          }),
          _c("div", { staticClass: "flex flex-col xl:flex-row" }, [
            _c(
              "div",
              { staticClass: "flex flex-col xl:w-1/2" },
              [
                _c("client-scope", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWebOrMachine,
                      expression: "spaOrWebOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("cors-origin", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spa,
                      expression: "spa"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("redirect-uri", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWeb,
                      expression: "spaOrWeb"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("client-secret", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.webOrMachine,
                      expression: "webOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-col xl:ml-8 xl:w-1/2" },
              [
                _c("client-claim", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWebOrMachine,
                      expression: "spaOrWebOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("grant-type", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWebOrMachine,
                      expression: "spaOrWebOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("logout-uri", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWeb,
                      expression: "spaOrWeb"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("client-property", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWebOrMachine,
                      expression: "spaOrWebOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                }),
                _c("idp-restriction", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spaOrWebOrMachine,
                      expression: "spaOrWebOrMachine"
                    }
                  ],
                  on: {
                    finishedLoading: function($event) {
                      return _vm.increaseLoadingBar()
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }