var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.showData,
          expression: "!showData"
        }
      ],
      staticClass: "loader"
    }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showData,
            expression: "showData"
          }
        ],
        staticClass: "lg:flex md:-mx-4"
      },
      [
        _c(
          "div",
          { staticClass: "lg:userAccount md:px-4" },
          [
            _c("account-dashboard-information", {
              staticClass: "mb-8 lg:mb-0",
              on: {
                finishedLoading: function($event) {
                  _vm.information = $event
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "lg:userAccountSpacing md:px-4" }, [
          _c("div", { staticClass: "xxl:flex md:-mx-4" }, [
            _c(
              "div",
              { staticClass: "md:px-4 xxl:w-full xl:w-full" },
              [
                _c("account-dashboard-details", {
                  on: {
                    finishedLoading: function($event) {
                      _vm.details = true
                    }
                  }
                }),
                _c("account-dashboard-subscriptions", {
                  staticClass: "mb-8",
                  on: {
                    finishedLoading: function($event) {
                      _vm.subscriptions = true
                    }
                  }
                }),
                _c("account-dashboard-applications", {
                  on: {
                    finishedLoading: function($event) {
                      _vm.applications = true
                    }
                  }
                }),
                _c("account-dashboard-organisation", {
                  on: {
                    finishedLoading: function($event) {
                      _vm.organisations = true
                    }
                  }
                })
              ],
              1
            ),
            false
              ? _c(
                  "div",
                  { staticClass: "md:px-4 xxl:w-1/3 xl:w-full relative" },
                  [
                    _vm._v(
                      "\n          //TODO add account dashboard activity once the end points have been made\n          "
                    ),
                    _c("account-dashboard-activity")
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }