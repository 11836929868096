var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("on-click-outside", { attrs: { close: _vm.close } }, [
    _c(
      "div",
      {
        staticClass:
          "modal-wrapper flex flex-col overflow-auto h-full p-8 bg-white ml-auto fixed z-50 top-0 right-0 bottom-0 h-full w-full md:w-1/2"
      },
      [
        _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
        _vm._t("default"),
        _c("div", { staticClass: "ml-auto mt-auto pt-4" }, [
          _c(
            "button",
            {
              staticClass: "btn-secondary m-1 btn-width-lg",
              on: { click: _vm.close }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "btn-default m-1 btn-width-lg",
              on: { click: _vm.save }
            },
            [_vm._v("OK")]
          )
        ]),
        _c("portal", { attrs: { to: "formEdit" } }, [
          _c("div", {
            staticClass:
              "z-20 animated fadeIn left-0 right-0 top-0 bottom-0 transition opacity-50 fixed backdrop"
          })
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }