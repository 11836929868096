export class OrganisationSubscription {
  organisationId?: string;
  organisationSubscriptionId?: number;
  subscriptionPackageName: string;
  subscriptionPackageId: any;
  startDate: string;
  endDate: string | null;
  trial: boolean;
  perpetual: boolean;
  suspended: boolean;
  notes: string | null;

  constructor() {
    this.subscriptionPackageName = "";
    this.subscriptionPackageId = undefined;
    this.startDate = "";
    this.endDate = null;
    this.trial = false;
    this.perpetual = false;
    this.suspended = false;
    this.notes = null;
  }
}
