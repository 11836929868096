export class Entitlement {
  entitlementId?: any;
  entitlementName: string;
  shortCode: string;

  constructor() {
    this.entitlementName = "";
    this.shortCode = "";
  }
}
