


































import { SaveMixin } from "@/services/mixins/saveMixin";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Entitlement } from "@/types/entitlement";
import { HttpService } from "@/services/api/httpService";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
@Component({
  validations: {
    form: {
      entitlementName: { required, maxLength: maxLength(50) },
      shortCode: { required, maxLength: maxLength(10) }
    }
  },
  mixins: [SaveMixin]
})
export default class EntitlementItem extends Vue {
  id: number = 0;
  entitlement: Entitlement = new Entitlement();
  backRoute = {
    name: "entitlementsList"
  };

  httpService() {
    return new HttpService<Entitlement>();
  }

  getEntitlement() {
    this.httpService()
      .getItem(`entitlements/${this.id}`)
      .then(res => {
        this.entitlement = res.data || new Entitlement();
      });
  }

  get form() {
    return this.entitlement;
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = Number(this.$route.params.id);
    this.getEntitlement();
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.httpService()
        .putItem(`entitlements/${this.id}`, this.entitlement)
        .then(() => {
          this.$router.push(this.backRoute);
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
