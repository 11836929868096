




























import { Component, Vue, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

@Component({
  validations: {
    subscriptionPackage: {
      subscriptionPackageName: { required, maxLength: maxLength(50) },
      subscriptionPackageCode: { required, maxLength: maxLength(10) }
    }
  },
  mixins: [SaveMixin]
})
export default class SubscriptionPackage extends Vue {
  id: string = "";
  subscriptionPackage: SubscriptionPackageItem = new SubscriptionPackageItem();
  httpServiceSubscriptionPackage: HttpService<
    SubscriptionPackageItem
  > = new HttpService<SubscriptionPackageItem>();
  backRoute = {
    name: "packagesList"
  };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
  }

  save() {
    this.$v.$touch();
    this.$store.commit("SAVE", false);
    if (!this.$v.$invalid) {
      this.httpServiceSubscriptionPackage
        .postItem("packages", this.subscriptionPackage)
        .then(res => {
          this.$router.push({
            name: "packagesEdit",
            params: { id: String(res.data.subscriptionPackageId) }
          });
          this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
