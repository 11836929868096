



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { ThirdParty } from "@/types/thirdParty";
@Component
export default class ThirdPartyList extends Vue {
  total = 0;
  searchCriteria: string = "";
  httpServiceArray = new HttpService<Array<ThirdParty>>();
  httpService = new HttpService<ThirdParty>();
  pageSize: any = localStorage.getItem("pageSize")
    ? localStorage.getItem("pageSize")
    : 25;
  pageNumber: number = 1;
  responseArray: Array<ThirdParty> = new Array<ThirdParty>();
  showModal: boolean = false;
  removeData: ThirdParty = new ThirdParty();

  async getList() {
    let response = await this.httpServiceArray.getListSearch(
      "thirdparty",
      this.pageSize,
      this.pageNumber,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.responseArray = response.data;
      this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
    } else this.responseArray = new Array<ThirdParty>();
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  @Watch("pageSize", { immediate: true }) onPageSize() {
    localStorage.setItem("pageSize", this.pageSize);
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }
  created() {
    this.getList();
  }

  add() {
    this.$router.push({
      name: "thirdPartyAdd"
    });
  }
  confirmRemove(data: ThirdParty) {
    this.showModal = true;
    this.removeData = data;
  }
  async remove(data: ThirdParty) {
    let response = await this.httpService.deleteItem("thirdparty", data.id);
    this.responseArray = this.responseArray.filter(
      (item: ThirdParty) => item.id != data.id
    );
    if (response.status != 404)
      this.$notification.success(`${data.name} successfully removed!`);

    this.showModal = false;
    this.getList();
  }
  edit(data: ThirdParty) {
    this.$router.push({
      name: `thirdPartyEdit`,
      params: { id: data.id }
    });
  }
}
