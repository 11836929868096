var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "save-bar",
    [
      !_vm.showData
        ? _c("div", { staticClass: "loader" })
        : [
            _vm.formData
              ? _c(
                  "div",
                  { staticClass: "overflow-hidden" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          mouseenter: function($event) {
                            _vm.showEdit = true
                          },
                          mouseleave: function($event) {
                            _vm.showEdit = false
                          },
                          click: function($event) {
                            ;(_vm.showConfigureForm = true), !_vm.showEdit
                          }
                        }
                      },
                      [
                        _c("h1", { staticClass: "inline-block mb-3" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formData.name) +
                              "\n          "
                          ),
                          _c(
                            "span",
                            { staticClass: "text-sm" },
                            [
                              _vm.showEdit
                                ? _c("font-awesome-icon", {
                                    staticClass: "cursor-pointer text-gray-100",
                                    attrs: { icon: "pencil-alt" }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "draggable",
                          _vm._b(
                            {
                              attrs: {
                                list: _vm.formData.groups,
                                handle: ".handle"
                              },
                              on: {
                                start: function($event) {
                                  _vm.isDragging = true
                                },
                                end: function($event) {
                                  _vm.isDragging = false
                                }
                              }
                            },
                            "draggable",
                            _vm.dragOptions,
                            false
                          ),
                          [
                            _c(
                              "transition-group",
                              {
                                attrs: { type: "transition", name: "flip-list" }
                              },
                              _vm._l(_vm.formData.groups, function(
                                formGroup,
                                index
                              ) {
                                return _c("question-group", {
                                  key: formGroup.uniqueId,
                                  staticClass: "relative",
                                  attrs: {
                                    formId: _vm.formData.formId,
                                    groupIndex: index,
                                    questionGroup: _vm.questionGroup(
                                      formGroup,
                                      index
                                    )
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "btn-group w-full" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-default block mx-auto",
                              on: { click: _vm.addQuestionGroup }
                            },
                            [_vm._v("Add New Group")]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "transition",
                      { attrs: { name: "slide-right" } },
                      [
                        _vm.showConfigureForm
                          ? _c(
                              "side-modal",
                              {
                                on: {
                                  close: function($event) {
                                    _vm.showConfigureForm = false
                                  }
                                }
                              },
                              [
                                _c("configure-form", {
                                  attrs: { name: _vm.formData.name },
                                  on: {
                                    close: function($event) {
                                      _vm.showConfigureForm = false
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("portal-target", { attrs: { name: "formEdit", multiple: "" } })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }