var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Add an application")]),
          _vm.filteredApplications.length
            ? _c(
                "form",
                {
                  staticClass: "w-full",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "md:flex mb-4 flex-none block" }, [
                    _c("div", { staticClass: "md:w-2/5-2 h-8 w-full" }, [
                      _vm._v("Application:")
                    ]),
                    _c(
                      "div",
                      { staticClass: "md:w-3/5-2 md:px-3 h-8 w-full sm:px-0" },
                      [
                        _c("dropdown-list", {
                          attrs: {
                            data: _vm.filteredApplications,
                            accessValueField: "applicationId",
                            allowNull: false
                          },
                          on: {
                            selected: function($event) {
                              _vm.$v.organisationApplication.applicationId.$model = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item.applicationName))]
                                }
                              }
                            ],
                            null,
                            false,
                            4045101090
                          )
                        }),
                        _vm.$v.organisationApplication.applicationId.$error
                          ? _c(
                              "div",
                              { staticClass: "text-red-400 text-xs italic" },
                              [
                                !_vm.$v.organisationApplication.applicationId
                                  .required
                                  ? _c("p", [
                                      _vm._v("Please select an application")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            : _c("h3", [
                _vm._v(
                  "There are no other applications that can be added to the organisation"
                )
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }