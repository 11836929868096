









import { Component, Vue } from "vue-property-decorator";
import NavigationItems from "./NavigationItems.vue";

@Component({
  components: {
    NavigationItems
  }
})
export default class Navigation extends Vue {
  get isMenuOpen() {
    return this.$store.getters.isMenuOpen;
  }
}
