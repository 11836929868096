var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c(
      "div",
      [
        _c("h1", [
          _vm._v(
            _vm._s(_vm.subscriptionPackage.subscriptionPackageName) +
              " Subscription package"
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Subscription package name:")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("input-component", {
                attrs: {
                  validationObj:
                    _vm.$v.subscriptionPackage.subscriptionPackageName
                },
                model: {
                  value:
                    _vm.$v.subscriptionPackage.subscriptionPackageName.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.subscriptionPackage.subscriptionPackageName,
                      "$model",
                      $$v
                    )
                  },
                  expression:
                    "$v.subscriptionPackage.subscriptionPackageName.$model"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }, [
            _vm._v("Subscription package code:")
          ]),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("input-component", {
                attrs: {
                  validationObj:
                    _vm.$v.subscriptionPackage.subscriptionPackageCode
                },
                model: {
                  value:
                    _vm.$v.subscriptionPackage.subscriptionPackageCode.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.subscriptionPackage.subscriptionPackageCode,
                      "$model",
                      $$v
                    )
                  },
                  expression:
                    "$v.subscriptionPackage.subscriptionPackageCode.$model"
                }
              })
            ],
            1
          )
        ]),
        _c("h1", { staticClass: "mt-10" }, [_vm._v("Entitlements")]),
        _vm.subscriptionPackage.packageEntitlements.length < 1
          ? _c("div", { staticClass: "flex mb-4" }, [
              _c("div", { staticClass: "input-label" }),
              _c("div", { staticClass: "main-input" }, [
                _c("div", { staticClass: "w-full flex" }, [
                  _c("div", { staticClass: "w-full inline-flex" }, [
                    _c("input", {
                      staticClass:
                        "h-8 rounded p-2 border border-gray-50 focus:outline-none transition w-full",
                      attrs: {
                        type: "text",
                        value: "No entilements",
                        disabled: ""
                      }
                    })
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm._l(_vm.subscriptionPackage.packageEntitlements, function(
          entitlement
        ) {
          return _c(
            "div",
            {
              key: entitlement.entitlement.entitlementId,
              staticClass: "input-container"
            },
            [
              _c("div", { staticClass: "input-label" }, [
                _vm._v(_vm._s(entitlement.entitlement.entitlementName) + ":")
              ]),
              _c("div", { staticClass: "main-input" }, [
                _c("div", { staticClass: "w-full flex" }, [
                  _c("div", { staticClass: "w-full inline-flex" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: entitlement.entitlement.shortCode,
                          expression: "entitlement.entitlement.shortCode"
                        }
                      ],
                      staticClass:
                        "h-8 rounded p-2 border border-gray-50 focus:outline-none transition w-full",
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: entitlement.entitlement.shortCode },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            entitlement.entitlement,
                            "shortCode",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn bg-red-600 text-white text-center reset-btn px-3 ml-2 rounded inline-flex",
                      on: {
                        click: function($event) {
                          return _vm.removeEntitlement(
                            entitlement.entitlement.entitlementId
                          )
                        }
                      }
                    },
                    [_vm._v("X")]
                  )
                ])
              ])
            ]
          )
        }),
        _c("div", { staticClass: "input-container" }, [
          _c("div", { staticClass: "input-label" }),
          _c(
            "div",
            { staticClass: "main-input" },
            [
              _c("dropdown-list", {
                staticClass: "w-auto inline-block",
                attrs: {
                  data: _vm.entitlements,
                  accessValueField: "entitlementId",
                  allowNull: false
                },
                on: {
                  selected: function($event) {
                    ;(_vm.entitlement.entitlementId = $event),
                      _vm.disableAddButton()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var item = ref.item
                      return [_vm._v(_vm._s(item.entitlementName))]
                    }
                  }
                ])
              }),
              _c(
                "button",
                {
                  staticClass: "reset-btn btn-default",
                  attrs: { disabled: _vm.addButtonDisabled },
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [_vm._v("Add Entitlement")]
              )
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }