


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import InputComponent from "@/components/inputs/InputComponent.vue";
import QuestionGroup from "@/views/forms/groups/QuestionGroup.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
import Draggable from "vuedraggable";
import { GroupType } from "../../types/group";
import { FullForm } from "../../types/fullForm";
import { GetForm } from "../../store/modules/formBuilder/types";
import ConfigureForm from "./ConfigureForm.vue";
import SideModal from "@/components/SideModal.vue";
@Component({
  validations: {
    formData: {
      name: { required, maxLength: maxLength(100) }
    }
  },
  components: { QuestionGroup, Draggable, ConfigureForm, SideModal },
  mixins: [SaveMixin]
})
export default class FormEdit extends Vue {
  showData = false;
  backRoute = {
    name: "formsList"
  };
  showConfigureForm = false;
  showEdit: boolean = false;

  get formData() {
    return this.$store.getters.getForm;
  }

  questionGroup(formGroup: GroupType, index: number) {
    formGroup.sortOrder = index + 1;
    return formGroup;
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.getList(this.$route.params.id);
  }

  async getList(id: string) {
    await this.$store.dispatch("SET_FORM", `fullforms/${id}`);

    await this.$store.dispatch("SET_QUESTIONS", "questions");
    this.showData = true;
    document.title = `Subscriptions Admin - ${this.formData.name}`;
  }
  addQuestionGroup() {
    this.$store.commit("ADD_GROUP", new GroupType());
    this.$v.$touch();
  }
  async save() {
    this.$store.commit("SAVE", false);
    let removeGroupArray: GroupType[] = [];
    this.$store.getters.getForm.groups.forEach((group: GroupType) => {
      if (group.groupQuestions.length === 0) {
        removeGroupArray.push(group);
        if (group.formGroupId) {
          this.$store.commit("DELETE_GROUP_ARRAY", {
            groupId: group.formGroupId,
            formId: this.formData.formId
          });
        }
      }
    });

    this.$store.commit("REMOVE_GROUPS", removeGroupArray);
    await this.$store.dispatch("REMOVE_GROUPS_AND_QUESTIONS");
    await this.$store.dispatch("SAVE_FORM", { url: `fullforms/` });
    this.$router.push(this.backRoute);
    this.$store.dispatch("SET_DIRTY", false);
  }

  get dragOptions() {
    return {
      animation: 0.3,
      group: "groups",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  destroyed() {
    this.$store.commit("RESET_FORM");
  }
}
