import AccountAdd from "@/views/accounts/AccountAdd.vue";
import AccountDashboard from "@/views/accounts/accountDashboard/AccountDashboard.vue";
import AccountApplicationEdit from "@/views/accounts/accountApplication/AccountApplicationEdit.vue";
import AccountApplicationAdd from "@/views/accounts/accountApplication/AccountApplicationAdd.vue";
import AccountEdit from "@/views/accounts/AccountEdit.vue";
import AccountsList from "@/views/accounts/AccountsList.vue";
import AccountsPage from "@/views/accounts/AccountsPage.vue";
import AccountOrganisationAdd from "@/views/accounts/organisations/AccountOrganisationAdd.vue";
import AccountOrganisationEdit from "@/views/accounts/organisations/AccountOrganisationEdit.vue";
import AccountSubscriptionAdd from "@/views/accounts/accountSubscription/AccountSubscriptionAdd.vue";
import AccountSubscriptionEdit from "@/views/accounts/accountSubscription/AccountSubscriptionEdit.vue";
import AccountListImport from "@/views/accounts/AccountListImport.vue";
export const accountRoutes = {
  path: "/individuals",

  component: AccountsPage,
  children: [
    {
      path: "",
      component: AccountsList,
      name: "accountsList",
      meta: { title: "Subscriptions Admin - Individuals" }
    },
    {
      path: "add",
      name: "accountsAdd",
      component: AccountAdd,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: "import",
      name: "accountListImport",
      component: AccountListImport,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/edit",
      name: "accountsEdit",
      component: AccountEdit,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/subscription/add",
      name: "accountSubscriptionAdd",
      component: AccountSubscriptionAdd,
      props: true
    },
    {
      path: ":id/subscription/edit/:accountSubscriptionId",
      name: "accountSubscriptionEdit",
      component: AccountSubscriptionEdit,
      props: true
    },
    {
      path: ":id",
      name: "accountDashboard",
      component: AccountDashboard,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/applicationadd",
      name: "accountApplicationAdd",
      component: AccountApplicationAdd,
      props: true
    },
    {
      path: ":id/application/edit/:applicationId",
      name: "accountApplication",
      component: AccountApplicationEdit,
      props: true,
      meta: { layout: "simple" }
    },
    {
      path: ":id/organisation/add",
      name: "accountOrganisationAdd",
      component: AccountOrganisationAdd,
      props: true
    },
    {
      path: ":id/organisation/edit/:accountOrganisationId",
      name: "accountOrganisationEdit",
      component: AccountOrganisationEdit,
      props: true
    }
  ]
};
