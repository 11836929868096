















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import Draggable from "vuedraggable";
import { QuestionType } from "../../../../../types/questionType";
import { Question, QuestionAdder } from "../../../../../types/question";
import { HttpService } from "../../../../../services/api/httpService";

import { QuestionValue } from "../../../../../types/questionValue";
import GroupQuestion from "../GroupQuestion.vue";
import QuestionAdd from "../../../../configuration/questions/QuestionAdd.vue";
import { GroupQuestionType } from "../../../../../types/groupQuestion";

const myQuestion: QuestionAdder = new QuestionAdder();
@Component({
  components: { Draggable },
  validations: {
    questionValues: {
      $each: {
        name: { required },
        sortOrder: {}
      }
    },

    questionValue: { name: { required } }
  }
})
export default class NewQuestion extends Vue {
  @Prop() groupIndex!: number;
  @Prop() questionIndex!: number;
  @Prop() groupQuestion!: GroupQuestionType;

  question: QuestionAdder = new QuestionAdder();
  questionValue = new QuestionValue();
  questionValues = Array<QuestionValue>();
  questionTypes: Array<QuestionType> = Array<QuestionType>();
  lengthOfOriginalValues = 0;
  async created() {
    let httpService = new HttpService<Array<QuestionValue>>();
    const response = await httpService.getListPaging(
      `questions/${this.groupQuestion.question.questionId}/values`,
      1000,
      1
    );
    this.questionValues = response.data;
    this.questionValues.sort((a: any, b: any) =>
      a.sortOrder > b.sortOrder ? 1 : -1
    );
  }
  @Watch("question", { deep: true }) updateVuelidate() {
    myQuestion.questionTypeId = this.question.questionTypeId;
  }
  add() {
    this.$v.$touch();
    if (this.$v.questionValue && !this.$v.$invalid) {
      this.questionValues.push(this.questionValue);
      this.questionValue = new QuestionValue();
      this.$v.questionValue.$reset();
    }
  }

  async save() {
    if (this.$v.questionValues) this.$v.questionValues.$touch();
    if (this.$v.questionValues && !this.$v.questionValues.$invalid) {
      let httpService = new HttpService<QuestionValue>();
      let i = 0;
      for (let value of this.questionValues) {
        i++;
        value.sortOrder = i;

        if (!value.questionValueId) {
          const response = await httpService.postItem(
            `questions/${this.groupQuestion.question.questionId}/values`,
            value
          );
          value.isSelected = true;
        } else if (value.questionValueId) {
          await httpService.putItem(
            `questions/${this.groupQuestion.question.questionId}/values/${value.questionValueId}`,
            value
          );
        }
      }

      this.$store.dispatch("UPDATE_QUESTION", {
        question: this.groupQuestion.question,
        index: this.groupIndex,
        questionIndex: this.questionIndex
      });

      this.$emit("close");
    }
  }
  cancel() {
    this.$emit("close");
  }
}
