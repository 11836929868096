var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    !_vm.showData
      ? _c("div", { staticClass: "loader" })
      : _c("div", [
          _c("h1", [_vm._v("Edit Client")]),
          _c(
            "form",
            {
              staticClass: "w-full",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _vm._l(_vm.client, function(value, key) {
                return [
                  !_vm.nonEditableProperty(key)
                    ? _c("div", { key: key, staticClass: "input-container" }, [
                        _c(
                          "div",
                          { staticClass: "input-label leading-normal" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.uniformName(key)) +
                                "\n            "
                            ),
                            _vm.$v.client[key].hasOwnProperty("required")
                              ? _c("span", { staticClass: "text-red-600" }, [
                                  _vm._v("*")
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "main-input" },
                          [
                            key == "applicationId"
                              ? _c("dropdown-list", {
                                  attrs: {
                                    data: _vm.applications,
                                    accessValueField: "applicationId",
                                    selectedValue: _vm.client.applicationId,
                                    allowNull: true
                                  },
                                  on: {
                                    selected: function($event) {
                                      _vm.$v.client.applicationId.$model = $event
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _vm._v(_vm._s(item.applicationName))
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              : typeof value != "boolean"
                              ? _c("input-component", {
                                  attrs: { validationObj: _vm.$v.client[key] },
                                  model: {
                                    value: _vm.$v.client[key].$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.client[key],
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.client[key].$model"
                                  }
                                })
                              : _c("checkbox-component", {
                                  model: {
                                    value: _vm.$v.client[key].$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.client[key],
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.client[key].$model"
                                  }
                                })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }