






















import { Component, Vue, Watch } from "vue-property-decorator";
import { ClientCorsOrigin } from "../../../../types/client";
import { SaveMixin } from "@/services/mixins/saveMixin";
import { required, maxLength } from "vuelidate/lib/validators";
import { isUrl } from "@/services/customValidator/customValidators";
import { HttpService } from "../../../../services/api/httpService";
@Component({
  mixins: [SaveMixin],
  validations: {
    clientCorsOrigin: {
      origin: { required, maxLength: maxLength(250), isUrl }
    }
  }
})
export default class ClientCorsOriginSave extends Vue {
  clientCorsOrigin = new ClientCorsOrigin();
  httpService = new HttpService<ClientCorsOrigin>();
  showData = false;
  backRoute = {
    name: "clientDashboard",
    id: ""
  };

  async created() {
    if (this.$route.params.corsOriginId) {
      const response = await this.httpService.getItem(
        `clients/${this.$route.params.id}/cors-origins/${this.$route.params.corsOriginId}`
      );

      this.clientCorsOrigin = response.data || new ClientCorsOrigin();
    }
    this.clientCorsOrigin.clientId = Number(this.$route.params.id);
    this.backRoute.id = this.$route.params.id;
    this.showData = true;
  }

  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();

    if (!this.$v.$invalid) {
      let save = "";
      this.clientCorsOrigin.id
        ? await this.httpService.putItem(
            `clients/${this.clientCorsOrigin.clientId}/cors-origins/${this.clientCorsOrigin.id}`,
            this.clientCorsOrigin
          )
        : await this.httpService.postItem(
            `clients/${this.clientCorsOrigin.clientId}/cors-origins`,
            this.clientCorsOrigin
          );
      this.$notification.success("Client Cors Origin Successfully Saved");
      this.$router.push(this.backRoute);
      this.$store.dispatch("SET_DIRTY", false);
    }
  }
}
