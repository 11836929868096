





















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { GroupType } from "../../../../types/group";
import { Question } from "../../../../types/question";
import { GroupQuestionType } from "../../../../types/groupQuestion";
import { required, minLength } from "vuelidate/lib/validators";
import NewQuestion from "./newQuestion/NewQuestion.vue";
import OnClickOutside from "@/components/OnClickOutside.vue";
import { HttpService } from "../../../../services/api/httpService";
import { QuestionType } from "../../../../types/questionType";
import { QuestionValue } from "../../../../types/questionValue";

@Component({
  components: { NewQuestion, OnClickOutside },
  validations: {
    groupQuestion: {
      alternativeName: {},
      isRequired: {},
      question: { name: { required } },
      columnPosition: {}
    }
  }
})
export default class AddQuestion extends Vue {
  @Prop() groupIndex!: number;

  newQuestion = false;
  showAddQuestion = false;
  question = new Question();
  searchFilter: string = "";
  optionsShown: boolean = false;
  filteredQuestions = Array<Question>();
  httpService = new HttpService<Question>();
  httsServiceValues = new HttpService<Array<QuestionValue>>();
  groupQuestion = new GroupQuestionType();
  highlightedIndex: number = 0;
  searchList() {
    this.filteredQuestions = this.questionsToFilter.filter((question: any) => {
      return question.name
        .toLowerCase()
        .includes(this.searchFilter.toLowerCase());
    });
  }
  @Watch("questionsToFilter", { immediate: true }) onchange() {
    this.searchList();
  }
  @Watch("searchFilter", { immediate: true }) eek() {
    this.searchList();
  }
  get triggerSave() {
    return this.$store.getters.getSideModalSave;
  }
  @Watch("triggerSave") onSave() {
    if (this.triggerSave) {
      this.save();
    }
  }
  change(e: number) {
    this.highlightedIndex = e;
  }
  async selectQuestion(question: Question) {
    Vue.set(this.groupQuestion, "question", question);
    if (this.groupQuestion.question.questionId) {
      await this.httsServiceValues
        .getListPaging(
          `questions/${this.groupQuestion.question.questionId}/values`,
          1000,
          1
        )
        .then(response => {
          if (response.status === 404) return;
          else
            for (let questionValue of response.data) {
              questionValue.isSelected = true;
            }
          Vue.set(this.groupQuestion.question, "questionValues", response.data);
        });
    }
    this.searchFilter = "";
    this.optionsShown = false;
  }

  get questionsToFilter() {
    let allowedQuestions: Question[] = [];
    if (this.$store.getters.getQuestions.length) {
      const allUsedQuestions: Question[] = [];

      const tempVariableOne = this.$store.getters.getForm.groups.map(
        (group: GroupType) => {
          return group.groupQuestions.map(
            (groupQuestion: GroupQuestionType) => {
              return allUsedQuestions.push(groupQuestion.question);
            }
          );
        }
      );

      allowedQuestions = this.$store.getters.getQuestions.filter(
        (questionOne: Question) => {
          return !allUsedQuestions.find((questionTwo: Question) => {
            return questionOne.questionId === questionTwo.questionId;
          });
        }
      );
    } else allowedQuestions = [];
    return allowedQuestions;
  }

  mounted() {
    this.searchList();
  }
  keyMonitor(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.selectQuestion(this.filteredQuestions[this.highlightedIndex]);
    } else if (event.key === "Escape") {
      this.searchFilter = "";
      this.optionsShown = false;
    } else if (event.key === "ArrowUp") {
      this.highlightPrev();
    } else if (event.key === "ArrowDown") {
      this.highlightNext();
    }
  }
  highlightPrev() {
    if (this.highlightedIndex != 0) {
      this.highlightedIndex--;
    } else this.highlightedIndex = this.filteredQuestions.length - 1;
    this.scrollToView();
  }
  highlightNext() {
    if (this.highlightedIndex != this.filteredQuestions.length - 1) {
      this.highlightedIndex++;
    } else this.highlightedIndex = 0;
    this.scrollToView();
  }
  scrollToView() {
    if ("children" in this.$refs.questions) {
      this.$refs.questions.children[this.highlightedIndex].scrollIntoView({
        block: "nearest"
      });
    }
  }
  save() {
    this.$store.commit("SIDEMODALSAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$store.commit("ADD_QUESTION", {
        newQuestion: this.groupQuestion,
        index: this.groupIndex
      });
      this.$emit("close");
    }
  }
}
