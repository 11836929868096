


























































import { Component, Vue, Watch } from "vue-property-decorator";
import AccountInfo from "@/views/Navigation/AccountInfo.vue";
import AccountSearch from "@/views/Navigation/AccountSearch.vue";
import NotificationList from "@/views/notificationList/NotificationList.vue";
@Component({
  components: {
    AccountInfo,
    AccountSearch,
    NotificationList
  }
})
export default class HeaderComponent extends Vue {
  get alertCount() {
    const alertCount = this.$store.state.uploads.alertCount;
    if (alertCount > 0) return alertCount;
    else return;
  }
  isOpen = false;
  notificationOpen = false;
  isToggleId: number | null = null;
  listOpen = false;
  toggle() {
    this.$store.commit("TOGGLE_MENU");
  }
  get openNotificationClass() {
    if (this.notificationOpen) {
      return "text-white";
    } else return "";
  }

  toggleNotification() {
    this.$store.commit("RESET_ALERT");
    this.listOpen = true;
    this.notificationOpen = !this.notificationOpen;
    setTimeout(() => {
      this.listOpen = false;
    }, 0);
  }
}
