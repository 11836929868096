var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-container" }, [
    _c(
      "table",
      { staticClass: "w-full table" },
      [
        _c(
          "tr",
          [
            _vm._l(_vm.columns, function(column) {
              return _c(
                "th",
                { key: column.name, staticClass: "table-header-left" },
                [_vm._v(_vm._s(column.label))]
              )
            }),
            _vm.showDelete
              ? _c("th", { staticClass: "table-header-center" }, [
                  _vm._v("Delete")
                ])
              : _vm._e()
          ],
          2
        ),
        _vm.responseArray.length === 0
          ? _c("tr", [
              _c(
                "td",
                { staticClass: "text-center", attrs: { colspan: "100" } },
                [_vm._v("No items found")]
              )
            ])
          : _vm._l(_vm.responseArray, function(dataItem) {
              return _c(
                "tr",
                {
                  key: dataItem[_vm.primaryKey],
                  class: _vm.tableRowClass,
                  on: {
                    click: function($event) {
                      return _vm.$emit("edit", dataItem)
                    }
                  }
                },
                [
                  _vm._l(_vm.columns, function(column) {
                    return _c(
                      "td",
                      { key: column.name, class: _vm.tableDataClass },
                      [
                        dataItem[column.name] === true
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-green-300 text-sm block text-center"
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "check" }
                                })
                              ],
                              1
                            )
                          : dataItem[column.name] === false
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-red-300 text-sm block text-center"
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "times" }
                                })
                              ],
                              1
                            )
                          : _vm.checkifDate(dataItem[column.name])
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .moment(new Date(dataItem[column.name]))
                                    .format("DD MMM YYYY")
                                )
                              )
                            ])
                          : _c("span", [_vm._v(_vm._s(dataItem[column.name]))])
                      ]
                    )
                  }),
                  _vm.showDelete
                    ? _c(
                        "td",
                        {
                          staticClass: "border border-gray-25 w-24 text-center"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "px-8 py-4 cursor-pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("remove", dataItem)
                                }
                              }
                            },
                            [_vm._v("Delete")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }