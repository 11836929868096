export class SelfRegistration {
  code: string;
  domain: string;
  organisationId: string;
  startDate: string;
  endDate: string;

  constructor() {
    this.code = "";
    this.domain = "";
    this.organisationId = "";
    this.startDate = "";
    this.endDate = "";
  }
}
