var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "relative z-10" }, [
    _c(
      "li",
      {
        staticClass:
          "inline-block align-middle mr-6 h-full text-gray-50 hover:text-white transition",
        on: {
          mousedown: function($event) {
            return _vm.toggleNotification()
          }
        }
      },
      [
        _c(
          "portal",
          { attrs: { to: "app" } },
          [
            _vm.isOpen
              ? _c("notification-list", {
                  attrs: { listOpen: _vm.listOpen },
                  on: {
                    close: function($event) {
                      _vm.isOpen = $event
                    },
                    closeList: function($event) {
                      _vm.listOpen = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "h-8 w-8 relative block cursor-pointer bell-icon" },
          [
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "absolute top-0 right-0 text-xs bg-red-600 rounded px-1 z-10"
                },
                [_vm._v(_vm._s(_vm.alertCount))]
              ),
              _c(
                "div",
                { staticClass: "pb-10 px-1", class: _vm.openNotificationClass },
                [
                  _c("font-awesome-icon", {
                    staticClass: "text-xl absolute bottom-0",
                    attrs: { icon: "bell" }
                  })
                ],
                1
              )
            ]),
            _vm.$store.state.uploads.busy
              ? _c("div", { staticClass: "mt-1 lds-facebook" }, [_c("div")])
              : _vm._e()
          ]
        )
      ],
      1
    ),
    _c(
      "li",
      {
        staticClass:
          "inline-block align-middle mt-3 mr-6 h-full cursor-pointer text-gray-50 hover:text-white transition relative",
        on: { click: _vm.logout }
      },
      [
        _c("font-awesome-icon", {
          staticClass: "icon mr-2 text-xl font-awesome",
          attrs: { icon: "sign-out-alt" }
        }),
        _vm._v("Logout\n    ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }