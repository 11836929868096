





















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SubscriptionPackageItem } from "@/types/subscriptionPackage";
import { HttpService } from "@/services/api/httpService";
import { AccountOrganisation } from "@/types/accountOrganisation";

import { AxiosError } from "axios";

@Component
export default class AccountDashboardOrganisation extends Vue {
  accountId: string = "";

  accountOrganisations = new Array<AccountOrganisation>();
  organisationId: any = "";
  organisationName = "";
  showModal = false;

  async getData() {
    const httpService = new HttpService<Array<AccountOrganisation>>();
    const response = await httpService.getList(
      `accounts/${this.accountId}/organisations`
    );

    response.status === 404
      ? (this.accountOrganisations = new Array<AccountOrganisation>())
      : (this.accountOrganisations = response.data);
    this.$emit("finishedLoading", true);
  }

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.accountId = this.$route.params.id;

    this.getData();
  }

  edit(id: string) {
    this.$router.push({
      name: "accountOrganisationEdit",
      params: {
        id: this.accountId,
        accountOrganisationId: id
      }
    });
  }

  showModalFunction(id: string, name: string) {
    this.showModal = true;
    this.organisationId = id;
    this.organisationName = name;
  }
  async remove() {
    const httpService = new HttpService<AccountOrganisation>();
    const response = await httpService.deleteItem(
      `accounts/${this.accountId}/organisations`,
      this.organisationId
    );

    this.accountOrganisations = this.accountOrganisations.filter(
      (item: AccountOrganisation) =>
        item.organisation.organisationId != this.organisationId,
      this.$notification.success(
        `${this.organisationName} successfully removed!`
      )
    );

    this.showModal = false;
    this.getData();
  }
}
