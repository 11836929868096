




























import { Component, Vue, Watch } from "vue-property-decorator";
import { Country } from "@/types/country";
import { HttpService } from "@/services/api/httpService";
import { required, maxLength } from "vuelidate/lib/validators";
import { SaveMixin } from "@/services/mixins/saveMixin";
@Component({
  mixins: [SaveMixin],
  validations: {
    country: {
      countryName: { required, maxLength: maxLength(50) },
      countryCode: { required, maxLength: maxLength(3) }
    }
  }
})
export default class CountryEdit extends Vue {
  country: Country = new Country();
  httpService = new HttpService<Country>();
  id: number | string = 0;

  backRoute = { name: "countriesList" };

  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getData();
  }
  async getData() {
    await this.httpService.getItem(`countries/${this.id}`).then(response => {
      this.country = response.data || new Country();
    });
  }
  async save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      let httpService = new HttpService<Country>();
      await httpService.putItem(`countries/${this.id}`, this.country);

      this.$router.push(this.backRoute);
      this.$notification.success("Country successfully saved");
    }

    this.$store.dispatch("SET_DIRTY", false);
  }
}
