













import { Component, Prop, Vue } from "vue-property-decorator";
import { GroupQuestionType } from "../../../../../types/groupQuestion";
import { QuestionValue } from "../../../../../types/questionValue";
import { Answer } from "../../../../../types/answer";

@Component
export default class CheckboxQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;

  checkAnswer(value: QuestionValue, groupQuestion: GroupQuestionType) {
    let checked = false;
    if (groupQuestion.question.answers) {
      groupQuestion.question.answers.forEach(answer => {
        if (value.questionValueId === answer.questionAnswerValueId) {
          checked = true;
        }
      });
    }
    return checked;
  }
  check(
    value: QuestionValue,
    groupQuestion: GroupQuestionType,
    selected: boolean
  ) {
    const answer = new Answer();
    answer.questionId = groupQuestion.question.questionId;
    answer.questionAnswerValueId = value.questionValueId;
    if (groupQuestion.question.answers) {
      if (!selected) {
        groupQuestion.question.answers.push(answer);
      } else {
        const newArray = groupQuestion.question.answers.filter(removeAnswer => {
          if (removeAnswer.questionAnswerValueId === value.questionValueId) {
            this.$store.commit("DELETE_ANSWER_ARRAY", {
              id: removeAnswer.accountQuestionAnswerId
            });
          }
          return removeAnswer.questionAnswerValueId !== value.questionValueId;
        });

        groupQuestion.question.answers = newArray;
      }
    }
  }
}
