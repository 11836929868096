



















































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { OrganisationAccount } from "@/types/organisationAccount";
import { OrganisationAccountPost } from "@/types/organisationAccount";
import { Account } from "@/types/account";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { Organisation } from "../../types/organisation";

@Component({
  validations: {
    organisationAccount: {
      organisation: {
        organisationId: { required }
      },
      account: {
        email: { required }
      },
      owner: { required }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationIndividualAdd extends Vue {
  showData = false;
  total = 0;
  organisationAccount = new OrganisationAccount();
  organisationAccountPost = new OrganisationAccountPost();
  accounts = new Array<Account>();
  alreadyAdded = new Array<OrganisationAccount>();
  accountId: string = "";
  httpServiceAccounts: HttpService<Array<Account>> = new HttpService<
    Array<Account>
  >();
  pageNumber: number = 1;
  searching = false;
  timeout: any = null;
  message = "";
  currentPage = 1;
  pageSize = 25;
  searchCriteria = "";
  organisationName = "";
  httpServiceOrganisationAccount = new HttpService<OrganisationAccountPost>();

  backRoute: { name: string; id?: string } = {
    name: "organisationDashboard",
    id: ""
  };

  get title() {
    return `${this.organisationName} - Add Individual`;
  }

  async getList(resetPaging?: string) {
    await this.httpServiceAccounts
      .getListSearch(
        "accounts",
        this.pageSize,
        this.pageNumber,
        this.searchCriteria
      )
      .then(response => {
        if (response.status != 404) {
          this.accounts = response.data;
          this.searching = false;
          this.message = "";
          this.total = JSON.parse(response.headers["x-pagination"]).totalCount;
        } else this.message = "no account found";
      });
    this.showData = true;
    if (resetPaging) {
      this.pageNumber = 1;
    }
    let refs: any = this;
    if (refs.$refs.accounts)
      for (let account of refs.$refs.accounts) {
        if (account.id === this.organisationAccount.account.id) {
          account.classList.add("selected");
        } else account.classList.remove("selected");
      }
  }

  get filteredAccounts() {
    let filteredAccounts: Array<Account> = [];
    if (!this.alreadyAdded.length) {
      filteredAccounts = this.accounts;
    } else {
      filteredAccounts = this.accounts.filter(account => {
        return !this.alreadyAdded.find(organisationAccount => {
          return account.id === organisationAccount.account.id;
        });
      });
    }
    return filteredAccounts;
  }

  async select(account: Account, index: number) {
    let refs: any = this;
    this.organisationAccount.account = account;
    for (let account of refs.$refs.accounts) {
      if (account.id === this.organisationAccount.account.id) {
        account.classList.add("selected");
      } else account.classList.remove("selected");
    }
  }
  @Watch("$route", { immediate: true }) async getId() {
    const httpService = new HttpService<Organisation>();
    const response3 = await httpService.getItem(
      `organisations/${this.$route.params.id}`
    );

    this.organisationName = response3.data.organisationName;
    this.backRoute.id = this.$route.params.id;
    this.organisationAccount.organisation.organisationId = this.$route.params.id;

    this.getList();
    this.getAlreadyAdded();
  }

  async getAlreadyAdded() {
    const httpService = new HttpService<Array<OrganisationAccount>>();

    await httpService
      .getList(
        `organisations/${this.organisationAccount.organisation.organisationId}/accounts`,
        {
          errorInterceptor: false
        }
      )
      .then(response => {
        this.alreadyAdded = response.data || new Array<OrganisationAccount>();
      });
  }
  reset() {
    this.searchCriteria = "";
    this.getList();
  }
  keyMonitor(event: KeyboardEvent, pageReset: string) {
    this.searching = true;
    this.message = "searching...";
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.getList(pageReset);
    }, 500);
  }

  @Watch("pageSize", { immediate: true }) onPageSize() {
    this.getList();
  }
  @Watch("pageNumber") onPageChange() {
    this.getList();
  }

  save() {
    this.$store.commit("SAVE", false);
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.httpServiceOrganisationAccount
        .postItem(
          `organisations/${this.organisationAccount.organisation.organisationId}/accounts`,
          {
            accountId: this.organisationAccount.account.id,
            owner: this.organisationAccount.owner
          }
        )
        .then(res => this.$notification.success(`Account successfully added!`))
        .then(() => {
          this.$router.push({
            name: "organisationDashboard",
            params: {
              id: String(this.organisationAccount.organisation.organisationId)
            }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
