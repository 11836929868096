




















































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { SelfRegistration } from "@/types/selfRegistration";
import DatePicker from "@/components/DatePicker.vue";
import { SaveMixin } from "@/services/mixins/saveMixin";
import {
  endDateGreaterThanStartDate,
  dateGreaterThanToday,
  domain
} from "@/services/customValidator/customValidators";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { Organisation } from "../../types/organisation";

@Component({
  components: { DatePicker },
  validations: {
    selfRegistration: {
      code: { required, maxLength: maxLength(25) },
      domain: { required, domain, maxLength: maxLength(100) },
      startDate: { required },
      endDate: { required, endDateGreaterThanStartDate, dateGreaterThanToday }
    }
  },
  mixins: [SaveMixin]
})
export default class OrganisationSelfRegistrationAdd extends Vue {
  organisationName = "";
  showData = false;
  organisationId: string = "";
  selfRegistration: SelfRegistration = new SelfRegistration();
  httpServiceSelfRegistration: HttpService<SelfRegistration> = new HttpService<
    SelfRegistration
  >();

  backRoute = {
    name: "organisationDashboard",
    id: ""
  };
  get title() {
    return `${this.organisationName} - Add Domain`;
  }
  @Watch("$route", { immediate: true }) getId() {
    this.backRoute.id = this.$route.params.id;
    this.organisationId = this.$route.params.id;
  }
  async mounted() {
    const httpService = new HttpService<Organisation>();
    const response3 = await httpService.getItem(
      `organisations/${this.$route.params.id}`
    );
    this.organisationName = response3.data.organisationName;
    this.showData = true;
  }
  save() {
    this.$store.commit("SAVE", false);

    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.selfRegistration.organisationId = this.organisationId;
      this.httpServiceSelfRegistration
        .postItem(
          `organisations/${this.organisationId}/selfregistration`,
          this.selfRegistration
        )
        .then(res => this.$notification.success(`Domain successfully added!`))
        .then(() => {
          this.$router.push({
            name: "organisationDashboard",
            params: { id: this.organisationId }
          }),
            this.$store.dispatch("SET_DIRTY", false);
        });
    }
  }
}
