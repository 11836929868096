var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "save-bar",
    [
      !_vm.showData
        ? _c("div", { staticClass: "loader" })
        : [
            _c("h1", [_vm._v("Add an application")]),
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [
                _vm._v("\n        Application:\n        "),
                _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("dropdown-list", {
                    attrs: {
                      data: _vm.applications,
                      accessValueField: "applicationId",
                      allowNull: false
                    },
                    on: {
                      selected: function($event) {
                        _vm.$v.applicationId.$model = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(_vm._s(item.applicationName))]
                        }
                      }
                    ])
                  }),
                  _vm.$v.applicationId.$error
                    ? _c(
                        "div",
                        { staticClass: "text-red-400 text-xs italic" },
                        [
                          !_vm.$v.applicationId.required
                            ? _c("p", [_vm._v("Please select an application")])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }