export class CommunicationType {
  communicationTypeId?: any;
  communicationTypeName: string | null;
  links: [] | null;

  constructor() {
    this.communicationTypeName = null;
    this.links = null;
  }
}
