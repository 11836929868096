








































import OnClickOutside from "./OnClickOutside.vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "../services/api/httpService";
import { Account } from "@/types/account";

@Component({
  components: {
    OnClickOutside
  }
})
export default class SearchSelect extends Vue {
  isOpen = false;
  message = "";
  searching = false;
  searchCriteria = "";
  searchResults: Account[] = [];
  highlightedIndex = 0;
  @Prop() api!: string;
  @Prop() selectRoute!: string;

  timeout: number | undefined = 0;
  get filteredResults() {
    if (this.searchCriteria === "") return (this.isOpen = false);
    else return (this.isOpen = true), this.searchResults;
  }

  @Watch("searchCriteria", { immediate: true }) reset() {
    if (!this.searchCriteria) {
      this.close();
    }
  }

  async getList() {
    if (this.searchCriteria === "") return this.close();

    const htppService = new HttpService<Array<Account>>();

    const response = await htppService.getListSearch(
      this.api,
      50,
      1,
      this.searchCriteria
    );

    if (response.status != 404) {
      this.searchResults = response.data;
      this.isOpen = true;
      this.searching = false;
    } else {
      this.message = `no results found for ${this.searchCriteria}`;
    }
  }

  close() {
    this.searching = false;
    this.message = "";
    this.isOpen = false;
    this.searchCriteria = "";
    this.searchResults = [];
    this.highlightedIndex = 0;
  }

  keyMonitor(event: KeyboardEvent) {
    if (this.searchCriteria === "") {
      return this.close();
    }

    if (event.key === "Enter") {
      if (this.searchResults.length)
        this.select(this.searchResults[this.highlightedIndex].id);
      else if (this.searchCriteria) {
        this.searching = true;
        this.message = "searching...";
        this.getList();
      }
    } else if (event.key === "Escape") {
      this.close();
    } else if (event.key === "ArrowUp") {
      this.highlightPrev();
    } else if (event.key === "ArrowDown") {
      this.highlightNext();
    } else {
      this.searching = true;
      this.message = "searching...";
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getList();
      }, 500);
    }
  }

  select(id: string) {
    this.$router.push({ name: this.selectRoute, params: { id: id } });
    this.$store.commit("TOGGLE_MENU", false);
    this.close();
  }

  selectHighlighted() {
    this.select(this.searchResults[this.highlightedIndex].id);
  }
  highlightPrev() {
    if (this.highlightedIndex != 0) {
      this.highlightedIndex--;
    } else this.highlightedIndex = this.searchResults.length - 1;
    this.scrollToView();
  }
  highlightNext() {
    if (this.highlightedIndex != this.searchResults.length - 1) {
      this.highlightedIndex++;
    } else this.highlightedIndex = 0;
    this.scrollToView();
  }
  scrollToView() {
    if ("children" in this.$refs.searchResults) {
      this.$refs.searchResults.children[this.highlightedIndex].scrollIntoView({
        block: "nearest"
      });
    }
  }
  highlight(index: number) {
    this.highlightedIndex = index;

    if (this.highlightedIndex < 0) {
      this.highlightedIndex = this.searchResults.length - 1;
    }

    if (this.highlightedIndex > this.searchResults.length - 1) {
      this.highlightedIndex = 0;
    }

    this.scrollToView();
  }
}
