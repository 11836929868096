import { MutationTree } from "vuex";

import { Uploads } from "./types";

export const mutations: MutationTree<Uploads> = {
  UPLOAD_STARTED(state) {
    state.disableUploadButton = true;
    state.busy = true;
  },
  UPLOAD_FINISHED(state) {
    state.busy = false;
    state.disableUploadButton = false;
    state.alertCount += 1;
  },
  RESET_ALERT(state) {
    state.alertCount = 0;
  },

  ADD_NOTIFICATION(state, payLoad) {
    state.notificationsList.push(payLoad);
  },
  REMOVE_NOTIFICATION(state, payLoad: { index: number }) {
    state.notificationsList.splice(payLoad.index, 1);
    state.alertCount = 0;
  },
  UPDATE_NOTIFICATION(state, payLoad) {
    let index = state.notificationsList.length - 1;
    state.notificationsList[index].notification.message = payLoad.message;
    state.notificationsList[index].notification.endTime = payLoad.endTime;
    state.notificationsList[index].notification.status = payLoad.status;
  }
};
