













































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HttpService } from "@/services/api/httpService";
import { QuestionValue } from "@/types/questionValue";
import { Question } from "@/types/question";
@Component
export default class questionValueList extends Vue {
  @Prop() question!: Question;

  questionValues: Array<QuestionValue> = Array<QuestionValue>();
  questionValue: QuestionValue = new QuestionValue();
  httpServiceArray = new HttpService<Array<QuestionValue>>();
  httpService = new HttpService<QuestionValue>();
  id: string = "";

  showModal: boolean = false;

  async getList() {
    await this.httpServiceArray
      .getList(`questions/${this.id}/values`)
      .then(response => {
        if (response.status != 404) {
          this.questionValues = response.data || Array<QuestionValue>();
        } else {
          this.questionValues = Array<QuestionValue>();
        }
      });
  }
  @Watch("$route", { immediate: true }) onRouteChanged() {
    this.id = this.$route.params.id;
    this.getList();
  }
  cancel() {
    this.$router.push({ name: "questionList" });
  }
  showModalFunction(questionValue: QuestionValue) {
    this.questionValue = questionValue;
    this.showModal = true;
  }
  async remove(id: number) {
    await this.httpService.deleteItem(
      `questions/${this.question.questionId}/values`,
      id
    );
    this.showModal = false;
    this.getList();
  }

  edit(questionValue: QuestionValue) {
    this.$router.push({
      name: "questionValueEdit",
      params: {
        id: String(this.id),
        id2: String(questionValue.questionValueId)
      }
    });
  }
}
