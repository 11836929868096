





















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import OnClickOutside from "@/components/OnClickOutside.vue";
import { GroupQuestionType } from "../../../../types/groupQuestion";
import NewAnswers from "./newAnswers/NewAnswers.vue";
@Component({
  validations: {
    updatedGroupQuestion: {
      alternativeName: {},
      isRequired: {},
      question: { name: {} },
      columnPosition: {}
    }
  },
  components: { NewAnswers }
})
export default class ConfigureQuestion extends Vue {
  @Prop() groupQuestion!: GroupQuestionType;
  @Prop() groupIndex!: number;
  @Prop() questionIndex!: number;
  showConfigureQuestion = false;
  updatedGroupQuestion = new GroupQuestionType();
  showAddAnswer: boolean = false;

  @Watch("groupQuestion", { immediate: true, deep: true }) update() {
    this.updatedGroupQuestion = Object.assign({}, this.groupQuestion);
  }
  get triggerSave() {
    return this.$store.getters.getSideModalSave;
  }
  @Watch("triggerSave") onSave() {
    if (this.triggerSave) {
      this.$store.commit("SIDEMODALSAVE");
      this.$store.commit("UPDATE_GROUP_QUESTION", {
        groupQuestion: this.updatedGroupQuestion,
        groupIndex: this.groupIndex,
        questionIndex: this.questionIndex
      });

      this.$emit("close");
    }
  }

  removeQuestion() {
    this.$store.dispatch("REMOVE_QUESTION", {
      questionOrder: this.questionIndex,
      groupIndex: this.groupIndex
    });
  }
}
