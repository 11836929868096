var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("save-bar", [
    _c("h1", [_vm._v("Client Post Logout Redirect Uri")]),
    _vm.showData == false
      ? _c("div", { staticClass: "loader" })
      : _c(
          "form",
          {
            staticClass: "w-full",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.save($event)
              }
            }
          },
          [
            _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-label" }, [
                _vm._v("\n        Origin\n        "),
                _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
              ]),
              _c(
                "div",
                { staticClass: "main-input" },
                [
                  _c("input-component", {
                    attrs: {
                      type: "text",
                      validationObj:
                        _vm.$v.clientPostLogoutRedirectUri.postLogoutRedirectUri
                    },
                    model: {
                      value:
                        _vm.$v.clientPostLogoutRedirectUri.postLogoutRedirectUri
                          .$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.clientPostLogoutRedirectUri
                            .postLogoutRedirectUri,
                          "$model",
                          $$v
                        )
                      },
                      expression:
                        "\n            $v.clientPostLogoutRedirectUri.postLogoutRedirectUri.$model\n          "
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }