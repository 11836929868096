

























































import { Component, Prop, Vue } from "vue-property-decorator";

import { GroupQuestionType } from "../../../../types/groupQuestion";

import { Answer } from "../../../../types/answer";
import { isRequired } from "@/services/customValidator/customValidators";
import { required, email } from "vuelidate/lib/validators";
import { GroupType } from "../../../../types/group";
import TextBoxQuestion from "./typesOfQuestion/TextBoxQuestion.vue";
import TextAreaQuestion from "./typesOfQuestion/TextAreaQuestion.vue";
import RadioQuestion from "./typesOfQuestion/RadioQuestion.vue";
import CheckboxQuestion from "./typesOfQuestion/CheckboxQuestion.vue";
import DropdownQuestion from "./typesOfQuestion/DropdownQuestion.vue";
import DateQuestion from "./typesOfQuestion/DateQuestion.vue";
@Component({
  components: {
    TextBoxQuestion,
    TextAreaQuestion,
    RadioQuestion,
    CheckboxQuestion,
    DropdownQuestion,
    DateQuestion
  }
})
export default class FormGroup extends Vue {
  @Prop() group!: GroupType;
}
