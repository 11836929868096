import { User, UserManager, WebStorageStateStore } from "oidc-client";
import store from "@/store/store";
export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const STS_DOMAIN = process.env.VUE_APP_STS;

    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: STS_DOMAIN,
      client_id: process.env.VUE_APP_CLIENT,
      redirect_uri: process.env.VUE_APP_REDIRECT,
      automaticSilentRenew: true,
      silent_redirect_uri: process.env.VUE_APP_SILENT_REDIRECT,
      response_type: "code",
      scope: "openid email profile ingress.full",
      post_logout_redirect_uri: process.env.VUE_APP_LOGOUT_REDIRECT,
      filterProtocolClaims: true
    };

    this.userManager = new UserManager(settings);
  }

  getUser(): Promise<User | null> {
    store.commit("SET_ROLE", this.userManager.settings.client_id); //TODO- to be updated when roles are implemented on the API so we know how to get the correct role ;
    return this.userManager.getUser();
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  getAccessToken(): Promise<string> {
    return this.userManager.getUser().then((data: any) => {
      return data.access_token;
    });
  }
}
