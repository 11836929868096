var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-8 bg-white rounded shadow mb-8 relative" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.name) + " form answers")]),
      _vm.formData && _vm.formData.groups.length
        ? _c("div", { staticClass: "input-container" }, [
            _c("div", { staticClass: "input-label" }, [
              _vm._v("Form Complete")
            ]),
            _c(
              "div",
              { staticClass: "main-input" },
              [
                _c("checkbox-component", {
                  attrs: { checked: _vm.value },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", $event.target.checked)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.formData && _vm.formData.groups.length
        ? _c(
            "ul",
            _vm._l(_vm.formData.groups, function(group) {
              return _c("li", { key: group.formGroupId }, [
                _c("div", [_c("form-group", { attrs: { group: group } })], 1)
              ])
            }),
            0
          )
        : _c("div", [
            _vm._v("There is no Form associated with " + _vm._s(_vm.name))
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }